.c-resources-newsletter-archive {
  color: $color-secondary-2;
}

.c-resources-newsletter-archive__header {
  margin-bottom: 2rem;

  @include bp-xlarge {
    margin-bottom: 5rem;
  }
}

.c-resources-newsletter-archive__heading {
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.c-resources-newsletter-archive__subheading {
  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.2;

  @include bp-medium {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }

  @include bp-xlarge {
    font-size: 2.8rem;
  }
}

.c-resources-newsletter-archive__single {
  font-size: 1.6rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.4;

  @include bp-medium {
    font-size: 1.8rem;
    letter-spacing: 0.41px;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;

    @include bp-medium {
      margin-bottom: 3rem;
    }

    @include bp-xlarge {
      margin-bottom: 4rem;
    }
  }
}

.c-resources-newsletter-archive__single-heading {
  margin-bottom: 0.3em;

  @include bp-medium {
    margin-bottom: 0.6em;
  }
}

.c-resources-newsletter-archive__single-heading-date {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;

  @include bp-medium {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }

  @include bp-xlarge {
    font-size: 2.8rem;
  }
}

.c-resources-newsletter-archive__single-heading-title {
  font-weight: 400;
}

.c-resources-newsletter-archive__single-link {
  color: $color-primary;
  font-weight: 700;
  font-style: italic;
  text-decoration: none;
}
