.u-bg--grey {
  background-color: $color-grey;
}

.u-bg--grey-to-left {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-grey;

    @include bp-large {
      right: 4.6rem;
      border-radius: 0 2rem 2rem 0;
    }
  }
}

.u-bg--lightblue-to-right {
  position: relative;
  margin-bottom: 1rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-quaternary;

    @include bp-xlarge {
      left: 12.5rem;
      border-radius: 2rem 0 0 2rem;
    }
  }

  &::after {
    @include bp-medium {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      right: 0;
      bottom: -1rem;
      height: 1rem;
      background-color: rgba(#00638b, 0.3);
    }

    @include bp-xlarge {
      height: auto;
      top: 0;
      left: 13.5rem;
      border-radius: 2rem 0 0 2rem;
    }
  }
}

.u-bg--primary {
  background: $color-primary-bg;
}

.u-bg--secondary {
  background: $color-secondary-bg;

  h2,
  p,
  li {
    color: $color-bright;
  }
}

.u-bg--secondary-gradient {
  background: radial-gradient(circle at center top, #139fc1, #08668d);

  h2,
  p,
  li {
    color: $color-bright;
  }
}

.u-bg--skyblue {
  background: linear-gradient(to bottom, #bce8f2, #def8ff);
}

.u-bg--oceanblue {
  background: radial-gradient(circle at center top, #e6efe2, #c0f6fb);
}

.u-bg--v-part {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: -138px;
    top: -5px;
    width: 189px;
    height: 335px;
    background-repeat: no-repeat;
    background-image: url("../assets/images/bg-a.png");
    transition: all $transition;
    pointer-events: none;

    @include bp-large {
      left: -58px;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -135px;
    bottom: 195px;
    width: 189px;
    height: 335px;
    background-repeat: no-repeat;
    background-image: url("../assets/images/bg-b.png");
    transition: all $transition;
    pointer-events: none;

    @include bp-large {
      right: -58px;
      bottom: 50px;
    }
  }
}

.u-bg--v-full {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    right: -65px;
    top: 0;
    width: 219px;
    height: 234px;
    background-repeat: no-repeat;
    background-image: url("../assets/images/bg-v.png");
    background-size: cover;
    transition: all $transition;
    pointer-events: none;

    @include bp-large {
      right: auto;
      left: 57%;
      top: 50%;
      width: 398px;
      height: 425px;
      transform: translateY(-35%);
    }

    @include bp-xlarge {
      left: 59.5%;
      width: 498px;
      height: 531px;
    }
  }
}

.u-bg--circle {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 0;
    top: 50%;
    width: 100%;
    height: 100%;
    max-width: 40rem;
    max-height: 40rem;
    background: radial-gradient(circle at center center, #7de5ef 0%, white 66%);
    transform: translateX(-50%) translateY(-50%);
  }
}
