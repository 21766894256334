.c-resources-data-sheets__row {
  margin-bottom: -4rem;

  @include bp-xlarge {
    margin-bottom: -6.5rem;
  }
}

.c-resources-data-sheets__single {
  display: flex;
  margin-bottom: 4rem;

  @include bp-xlarge {
    margin-bottom: 6.5rem;
  }
}

.c-resources-data-sheets__single-link {
  width: 100%;
  display: flex;
  background-color: $color-secondary-bg;
  padding: 3rem 0 2rem;
  text-decoration: none;

  @include bp-medium {
    padding: 4rem 0 3rem;
  }

  @include bp-xlarge {
    padding: 5.5rem 0 4rem;
  }
}

.c-resources-data-sheets__single-side-spacer {
  width: 3rem;

  @include bp-large {
    width: $colInnerDataSheetSpacer;
  }
}

.c-resources-data-sheets__single-inner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.c-resources-data-sheets__single-image-wrapper {
  flex: 0 0 auto;
  margin-bottom: 2rem;

  @include bp-xlarge {
    margin-bottom: 4.5rem;
  }
}

.c-resources-data-sheets__single-image {
  padding-bottom: 47.62%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-resources-data-sheets__single-title {
  flex: 1 0 auto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  @include bp-medium {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }

  @include bp-xlarge {
    font-size: 2.8rem;
  }
}

.c-resources-data-sheets__single-download {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: $color-primary-light;
  letter-spacing: 1.5px;

  @include bp-xlarge {
    font-size: 2rem;
  }
}
