/* stylelint-disable */
.o-wysiwyg-content {
  p {
    font-size: inherit;
    line-height: inherit;
    max-width: none;
  }
  ul,
  ol {
    margin: 0;
    li {
      text-indent: 20px;
    }
  }
  ol {
    list-style-position: outside;
    margin-left: 20px;

    > li {
      list-style-type: decimal;

      > ul {
        text-indent: 37px;
        margin-left: 24px;
      }
    }
  }
  ul {
    margin-left: 22px;
    list-style-position: inside;
    > li {
      list-style-type: none;
      position: relative;
      text-indent: 15px;

      &:before {
        content: "";
        width: 4px;
        border-top: 2px solid $color-secondary-2;
        position: absolute;
        top: 11px;
        left: 0;
      }

      > ul {
        margin-left: 30px;
      }
      > ol {
        > li {
          text-indent: 0;
        }
      }
    }
  }

  @include bp-medium {
    ol,
    ul {
      margin: 0 0 0 40px;

      li {
        text-indent: 0;
      }
    }
    ol {
      > li {
        margin-bottom: 3rem;
        > ul {
          margin-top: 3rem;
          margin-left: 8rem;
        }
        > ol {
          margin-top: 3rem;
          > li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    ul {
      > li {
        &:before {
          top: 15px;
          left: -35px;
          width: 6px;
          border-top: 3px solid $color-secondary-2;
        }

        > ul {
          margin-top: 3rem;
          margin-left: 6rem;
        }
        > ol {
          margin-top: 3rem;
          > li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
/* stylelint-enable */
