.c-clients-b {
  display: none;

  @include bp-large {
    display: block;
  }
}

.c-clients-b__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;

  @include bp-large {
    margin-top: 8rem;
  }
}

.c-clients-b__item {
  width: 33%;
  margin: 0;
  text-align: center;

  &:nth-child(n + 4) {
    margin-top: 6rem;

    @include bp-large {
      margin-top: 14rem;
    }
  }

  &--retail:nth-child(4) {
    @include bp-large {
      margin-top: 6rem;
      margin-left: 10rem;
    }
  }

  &--retail:nth-child(5) {
    @include bp-large {
      margin-top: 6rem;
      margin-right: 10rem;
    }
  }
}

.c-clients-b__image,
.c-clients-b__picture {
  display: block;
  max-width: 13.5rem;
  max-height: 4.2rem;
  margin: auto;

  @include bp-xlarge {
    max-width: 20.7rem;
    max-height: 6.4rem;
  }
}

.c-clients-b__image {
  &--bw {
    filter: grayscale(1);
  }
}
