p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $color-secondary;
  font-size: 1.5rem;

  @include bp-large {
    font-size: 1.8rem;
    line-height: 2.8rem;
    max-width: 72rem;
  }

  @include bp-xlarge {
    font-size: 2rem;
    max-width: 96rem;
  }
}
