.c-vertical-tile {
  display: block;
  padding-bottom: 1.8rem;
  border-bottom: 2px solid $color-primary-bg;

  &.is-hidden {
    display: none !important;
  }

  @include bp-medium {
    display: grid;
    grid-template-columns: 294px auto;
    grid-template-rows: auto auto auto 1fr;
    column-gap: 3.2rem;
    padding-bottom: 3.2rem;
  }

  @include bp-large {
    column-gap: 4.4rem;
  }
}

.c-vertical-tile--featured {
  padding-bottom: 0;
  border: 0;

  @include bp-large {
    .c-vertical-tile__title {
      font-size: 2.8rem;
      line-height: 1.19;
    }

    .c-vertical-tile__text {
      margin-top: 1.6rem;
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  @include bp-xlarge {
    grid-template-columns: 445px auto;

    .c-vertical-tile__image {
      aspect-ratio: 445 / 324;
    }

    .c-vertical-tile__details {
      margin-bottom: 2.4rem;
    }

    .c-vertical-tile__title {
      font-size: 3.6rem;
    }

    .c-vertical-tile__text {
      margin-top: 3.2rem;
      font-size: 2.4rem;
    }

    .c-vertical-tile__cta {
      margin-top: 2.4rem;
    }
  }
}

.c-vertical-tile__picture {
  display: block;

  @include bp-medium {
    grid-row: 1/5;
  }
}

.c-vertical-tile__image {
  aspect-ratio: 294 / 180;
  width: 100%;
  object-fit: cover;
}

.c-vertical-tile__details {
  margin-block: 1.5rem;
  font-size: 1.4rem;
  color: $color-secondary-bg;

  @include bp-medium {
    margin-top: 0;
    grid-column-start: 2;
  }

  @include bp-large {
    font-size: 1.8rem;
  }
}

.c-vertical-tile__details-title {
  font-weight: 700;
  text-transform: uppercase;
  color: $color-blue-dark;
  letter-spacing: 0.37px;
}

.c-vertical-tile__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: currentColor;
}

.c-vertical-tile__details-data {
  font-weight: 500;
  opacity: 0.6;
  letter-spacing: 0.5px;
  color: $color-secondary-bg;
}

.c-vertical-tile__title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.42;
  letter-spacing: 0.4px;
  color: $color-secondary-2;

  a {
    color: inherit;
    text-decoration: none;
  }

  @include bp-medium {
    grid-column-start: 2;
  }

  @include bp-large {
    font-size: 2.4rem;
  }
}

.c-vertical-tile__text {
  display: none;
  margin-top: 1.1rem;
  font-size: 1.4rem;
  color: $color-secondary-2;

  @include bp-medium {
    display: block;
    grid-column-start: 2;
  }

  @include bp-large {
    font-size: 1.8rem;
  }
}

.c-vertical-tile__cta {
  display: inline-block;
  margin-top: 1.8rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 1.23px;
  color: $color-primary;
  transition: color $transition;

  &:hover {
    color: $color-secondary;
  }

  @include bp-medium {
    margin-top: 2.4rem;
    grid-column-start: 2;
  }

  @include bp-large {
    margin-top: 4.7rem;
  }
}
