.c-resources-case-studies__animate-height {
  transition: height 1s;
  height: auto;
  overflow: hidden;
}

.c-resources-case-studies__secondary {
  padding-top: 4rem;
}

.c-resources-case-studies__row {
  margin-bottom: -4rem;
}

/* stylelint-disable no-descending-specificity */
.c-resources-case-studies__single {
  display: flex;
  line-height: 1.3;
  margin-bottom: 4rem;

  .c-resources-case-studies__primary &:nth-of-type(3) {
    display: none;

    @include bp-large {
      display: flex;
    }
  }
}

.c-resources-case-studies__single,
.c-resources-case-studies__single-spacer {
  .c-resources-case-studies__secondary &:first-of-type {
    @include bp-large {
      display: none;
    }
  }
}
/* stylelint-enable no-descending-specificity */

.c-resources-case-studies__single-link {
  width: 100%;
  display: flex;
  background-color: $color-secondary-bg;
  padding: 3rem;
  text-decoration: none;
}

.c-resources-case-studies__single-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.c-resources-case-studies__single-image-wrapper {
  flex: 0 0 auto;
  margin: 0 -1rem 2rem;
}

.c-resources-case-studies__single-image {
  padding-bottom: 57.14%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-resources-case-studies__single-title {
  font-size: 1.6rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  letter-spacing: 0.19px;

  @include bp-large {
    font-size: 2.2rem;
  }
}

.c-resources-case-studies__single-subtitle {
  flex: 1 0 auto;
  min-height: 5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  letter-spacing: 0.16px;

  @include bp-large {
    font-size: 1.9rem;
  }
}

.c-resources-case-studies__single-download {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: $color-primary-light;
  letter-spacing: 0.5px;
}
