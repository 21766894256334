/* stylelint-disable */

.c-contact {
  padding: 2rem 0 0;
  position: relative;
  z-index: 10;
}

.c-contact__gloss {
  @include bp-medium {
    display: none;
  }
}

.c-contact__form {
  position: relative;
  padding: 3.5rem 0 5.5rem;
  margin-top: 3.5rem;

  @include bp-medium {
    max-width: 70rem;
    padding: 6rem 8rem;
    border-radius: 1.5rem;
    margin-top: -6rem;
    margin-left: auto;
    margin-right: auto;
    background: $color-quaternary;
    min-height: 750px;
  }

  @include bp-xlarge {
    margin-top: -50rem;
    margin-right: -12rem;
    margin-bottom: 5rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: -0.7rem;
    height: 1.5rem;
    left: -0.5rem;
    right: -$padding;
    border-radius: 1.5rem 0 0 0;
    background-color: #b2d0dc;

    @include bp-medium {
      display: none;
    }
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1.5rem;
    background-color: rgba(#013f58, 0.3);
    transform: translateX(1rem) translateY(1rem);

    @include bp-medium {
      display: block;
    }
  }
}

.c-contact__form--notification {
  @include bp-medium {
    display: grid;
    place-items: center;
    min-height: 440px;
  }

  @include bp-xlarge {
    margin-top: -50rem;
    margin-bottom: 65rem;
  }
}

.c-contact__bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $color-quaternary;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: -$padding * 0.5;
    right: 100%;
    top: 0;
    bottom: 0;
    border-radius: 1.5rem 0 0 1.5rem;
    background: inherit;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 100%;
    top: 0;
    bottom: 0;
    width: $padding;
    background: inherit;
  }

  @include bp-medium {
    display: none;
  }
}

.c-contact__label,
.hs-form-field label {
  display: block;
  margin-top: 2rem;
}

.c-contact__pseudolabel {
  display: block;
  color: $color-secondary;
  font-size: 1.6rem;
  font-weight: 700;
}

.c-contact__pseudolabel-info {
  font-weight: 400;
}

.c-contact__asterisk,
.hs-form-required {
  color: red;
}

.c-contact__checklist {
  margin: 3rem 0 4rem;
}

.c-contact__textinput,
input.hs-input {
  width: 100%;
  padding: 0.4rem;
  border: 0;
  border-bottom: 2px solid $color-primary;
  margin: 1rem 0 3rem;
  color: #091e3e;
  font-size: 2rem;
  background: transparent;
  transition: all $transition;

  &:focus {
    outline: none;
    color: #091e3e;
    border-bottom-color: #091e3e;
  }
}

.c-contact__textarea,
textarea.hs-fieldtype-textarea {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border: 0;
  min-height: 11rem;
  border-bottom: 2px solid $color-primary;
  line-height: 1.5;
  border-radius: 0.5rem 0.5rem 0 0;
  background: $color-bright;
  transition: border-color $transition;
  resize: none;

  &:focus {
    outline: none;
    border-color: #091e3e;
  }

  &::placeholder {
    color: $color-medium;
  }
}

.c-contact__disclaimer {
  font-style: italic;
}

.c-contact__link {
  color: inherit;
  text-decoration: underline;

  &--no-underline {
    text-decoration: none;
  }
}

.c-contact .actions {
  text-align: center;
}

.c-contact__button,
.hs_submit input {
  margin-bottom: 0;
}

.c-contact__error-message {
  color: red;
}

.c-contact__section-wrapper {
  @include bp-large {
    display: flex;
    align-items: flex-start;
    max-width: 70rem;
    margin: 10rem auto;
    transform: translateX(1rem);
  }

  @include bp-xlarge {
    flex-direction: column;
    position: absolute;
    max-width: none;
    width: 50rem;
    top: 0;
    margin: 0;
    transform: none;
  }
}

.c-contact__headquaters {
  padding: 5rem 0;
  color: $color-secondary;
  text-align: center;

  @include bp-large {
    width: 45%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 3rem;
    text-align: left;
  }

  @include bp-xlarge {
    width: 100%;
    padding: 6rem;
  }

  .p-locality::after {
    content: ",";
  }
}

.c-contact__headquaters-heading {
  @include bp-large {
    font-size: 2rem;
  }

  @include bp-xlarge {
    margin-bottom: 2rem;
  }
}

.c-contact__headquaters-line {
  text-align: center;

  @include bp-large {
    text-align: left;
    font-size: 2.1rem;
  }
}

.c-contact__address {
  display: flex;
  justify-content: center;
  text-align: center;

  @include bp-large {
    justify-content: flex-start;
  }
}

.c-contact__address-item {
  & + & {
    margin-left: 0.5rem;
  }
}

.c-contact__direct {
  position: relative;
  padding: 4rem 0 6rem;
  color: $color-secondary;
  font-size: 2.1rem;
  background-color: $color-primary-bg;

  @include bp-large {
    width: 55%;
    padding: $padding $padding $padding * 3;
  }

  @include bp-xlarge {
    width: 100%;
    padding: 6rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$padding;
    right: 100%;
    background: inherit;

    @include bp-large {
      display: none;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: -$padding;
    background: inherit;

    @include bp-large {
      display: none;
    }
  }
}

.c-contact__direct-heading {
  @include bp-large {
    font-size: 2rem;
  }

  @include bp-xlarge {
    margin-bottom: 1.5rem;
  }
}

.hs-error-msgs {
  margin-top: -40px;
  color: $color-red;

  .hs_error_rollup & {
    margin-top: 6px;
  }
}
/* stylelint-enable */
