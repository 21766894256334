.c-solutions-spacer {
  padding-top: 5rem;

  @include bp-medium {
    padding-top: 8rem;
  }

  @include bp-large {
    padding-top: 12rem;
  }

  @include bp-xlarge {
    padding-top: 17rem;
  }
}
