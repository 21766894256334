.c-resources-white-papers__single {
  color: $color-secondary-2;

  &:not(:last-child) {
    margin-bottom: 3rem;

    @include bp-medium {
      margin-bottom: 6rem;
    }
  }
}

.c-resources-white-papers__single-heading {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
  white-space: pre-line;

  @include bp-medium {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }

  @include bp-xlarge {
    font-size: 2.8rem;
  }
}

.c-resources-white-papers__single-subtitle {
  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.4;

  @include bp-medium {
    font-size: 1.8rem;
    letter-spacing: 0.41px;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
  }
}
