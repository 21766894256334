//   ========================================================================
//   #GLOBAL
//   ========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$radius: 10px !default;
$transition: 250ms;

$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-xlarge: 1440px;

$padding: 30px;
$padding_large: 60px;
$padding_xlarge: 60px;
$indentation: 0;
$indentation_large: 70px;
$indentation_xlarge: 120px;

$shift_large: $padding_large + $indentation_large;
$shift_xlarge: $padding_xlarge + $indentation_xlarge;
$shift_diff: $shift_xlarge - $shift_large;
$maxwidth_xlarge: $bp-xlarge - $shift_xlarge * 2;

/* Fonts
   ========================================================================== */

$font-sans-serif: "Lato", "Helvetica", "Arial", sans-serif;

/* Colors
   ========================================================================== */

$color-bright: #fff;
$color-medium: #9b9b9b;
$color-dark: #000;

$color-grey: #f0f0f0;

$color-primary: #2cb9dc;
$color-primary-light: #42d7fd;
$color-secondary: #045b7f;
$color-secondary-2: #386180;
$color-secondary-3: #00638b;
$color-tertiary: #031d44;
$color-quaternary: #f1fafd;

$color-primary-bg: #c5f1fd;
$color-secondary-bg: #08668d;

$color-blue-dark: #091e3e;
$color-red: #d0021b;
