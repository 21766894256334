.c-get-started {
  position: relative;
  padding-top: 12rem;
}

.c-get-started__bg {
  display: block;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.c-get-started__bg--circle {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .u-bg--circle;

  @include bp-large {
    display: none;
  }
}

.c-get-started__bg--v-full {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .u-bg--v-full;

  display: none;

  @include bp-large {
    display: block;
  }
}
