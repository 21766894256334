/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $bp-large - ($padding * 2 + $indentation_large * 2);
  padding-left: $padding;
  padding-right: $padding;

  @include bp-large {
    max-width: $bp-xlarge - $padding_large * 2;
    padding-left: $padding_large;
    padding-right: $padding_large;
  }

  @include bp($bp-xlarge - $padding_large * 2) {
    padding-left: $padding_large;
    padding-right: $padding_large;
    max-width: $bp-xlarge;
  }
}
