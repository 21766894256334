/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;
  z-index: 10;
  background: $color-bright;

  @include bp-large {
    box-shadow: 0 1px 10px rgba(black, 0.2);

    .o-wrapper {
      max-width: 1250px;
    }
  }
}

.c-header__toolbar {
  background: #eff1f3;
  box-shadow: 0 0.671894px 1.34379px rgba(0, 0, 0, 0.15);

  @include bpmax($bp-large - 1) {
    display: none;
  }

  @include bp-large {
    padding-left: 2px;
  }
}

.c-header__wrapper {
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 10px rgba(black, 0.2);

  @include bp-large {
    box-shadow: none;
  }

  @include bpmax($bp-large - 1) {
    max-width: none;
  }
}

.c-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 8.1rem;
  margin: auto;
  background: $color-bright;
  transition: height $transition;

  @include bpmax($bp-large - 1) {
    // based on .o-wrapper with adjustment
    max-width: $bp-large - ($padding * 4 + $indentation_large * 2);
  }

  @include bp-large {
    z-index: 2;
    height: 11.2rem;
    background: none;
    box-shadow: none;
  }
}

.c-header__logo {
  display: block;
  width: 14.7rem;
  height: 4.7rem;

  @include bp-large {
    width: 18.7rem;
    height: auto;
  }
}
