.c-product__logo-mobile {
  margin-bottom: 3rem;

  @include bp-medium {
    display: none;
  }
}

.c-product__logo-desktop {
  width: 100%;
}

.c-product__logo {
  &--mobile {
    width: 15rem;
  }
}

.c-product__heading {
  margin-bottom: 0;

  @include bp-xlarge {
    margin-bottom: 3rem;
  }
}

.c-product__row {
  display: flex;
  justify-content: space-between;
}

.c-product__col {
  flex: 0 0 auto;

  &--info {
    width: 100%;

    @include bp-medium {
      width: 65%;
    }
  }

  &--image {
    display: none;

    @include bp-medium {
      display: flex;
      align-items: center;
      width: 25%;
    }
  }
}

.c-product__link {
  color: $color-secondary;
  text-decoration: none;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.c-product__link-row {
  display: flex;
  align-items: center;
}

.c-product__link-v {
  display: block;
  width: 2rem;
  height: 2.4rem;
  margin-right: 1rem;
}
