.o-indent {
  padding-left: $padding_xlarge;
  padding-right: $padding_xlarge;
}

.o-indent--large-up {
  padding-left: 0;
  padding-right: 0;

  @include bp-large {
    padding-left: $padding_xlarge;
    padding-right: $padding_xlarge;
  }
}
