/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
}

h2 {
  margin-bottom: 2rem;
  color: $color-secondary;
  font-size: 1.9rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 1.47;
  transition: all $transition;

  @include bp-large {
    font-size: 2.8rem;
    letter-spacing: 0.37px;
    line-height: 1.32;
  }

  @include bp-xlarge {
    margin-bottom: 5rem;
    font-size: 3.8rem;
  }
}

p + h2 {
  margin-top: 4rem;

  @include bp-large {
    margin-top: 6rem;
  }
}
