.o-section--peak-performance {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include bp-large {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  @include bp-xlarge {
    padding-top: 14rem;
    padding-bottom: 12rem;
  }

  h2 {
    margin: 0;
  }

  p {
    max-width: 100%;
    margin-top: 1.4rem;
    margin-bottom: 0;

    @include bp-small {
      max-width: 40rem;
    }

    @include bp-large {
      max-width: 59rem;
      margin-top: 1.9rem;
    }

    @include bp-xlarge {
      max-width: 78rem;
      margin-top: 3.2rem;
    }
  }

  a {
    margin-bottom: 0;

    @include bp-large {
      margin-top: 7rem;
    }

    @include bp-xlarge {
      margin-top: 12rem;
    }
  }
}

.o-section--testimonial {
  padding-top: $padding;
  padding-bottom: $padding;

  @include bp-large {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  blockquote {
    @include bp-large {
      max-width: 74rem;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.o-section--cta {
  overflow: hidden;
  padding-top: 24rem;
  padding-bottom: 3rem;

  @include bp-large {
    padding-bottom: 9rem;
  }

  p {
    @include bp-large {
      max-width: 46rem;
    }

    @include bp-xlarge {
      max-width: 54rem;
    }
  }
}

.o-section--why-traverse {
  padding-top: 3rem;
  padding-bottom: 8rem;

  @include bp-large {
    padding-top: 8.4rem;
    padding-bottom: 13rem;
  }
}

.o-section--capabilities {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include bp-large {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include bp-xlarge {
    padding-top: 12rem;
    padding-bottom: 16rem;
  }

  h2 {
    margin: 0;
  }

  p {
    max-width: none;
  }

  .c-btn {
    margin: 3rem 0 0;

    @include bp-large {
      margin: 10rem 0 0;
    }
  }
}

.o-section--right-solution {
  padding-top: 3.8rem;
  padding-bottom: 4.2rem;

  @include bp-large {
    padding-top: 9.1rem;
    padding-bottom: 7.5rem;
  }

  h2 {
    margin: 0;

    @include bp-large {
      text-align: center;
      max-width: none;
    }
  }

  .c-btn {
    margin: 6rem 0 0;

    @include bp-large {
      margin-top: 7rem;
    }

    @include bp-xlarge {
      margin-top: 8rem;
    }
  }

  br {
    display: none;

    @include bp-large {
      display: block;
    }
  }
}

.o-section--plain-center {
  padding-top: $padding;
  padding-bottom: $padding;
  text-align: center;

  p {
    margin-inline: auto;
  }

  @include bp-large {
    padding-top: 10rem;
  }

  h2 {
    @include bp-large {
      margin-bottom: 4rem;
    }

    @include bp-xlarge {
      letter-spacing: 0;
    }
  }
}

.o-section--supply-chain {
  @include bp-large {
    padding-top: 0;
  }

  @include bp-xlarge {
    padding-top: 2rem;
  }

  h2 {
    margin-top: 0;

    @include bp-large {
      text-align: center;
      margin-bottom: 4.6rem;
    }
  }
}

.o-section--gleam {
  padding-top: 7.5rem;
  padding-bottom: 10rem;

  @include bp-large {
    padding-top: 14rem;
  }

  @include bp-xlarge {
    padding-top: 20rem;
    padding-bottom: 15rem;
  }

  p {
    max-width: 80%;

    @include bp-medium {
      max-width: 50%;
    }

    @include bp-xlarge {
      max-width: 70rem;
    }
  }

  a {
    margin-bottom: 0;
  }
}

.o-section--gleam-jobs {
  padding-top: 6rem;
  padding-bottom: 5rem;

  @include bp-large {
    padding-top: 14rem;
  }

  @include bp-xlarge {
    padding-top: 16.7rem;
    padding-bottom: 14.6rem;
  }
}

.o-section--upgrade {
  padding-top: 5.2rem;
  padding-bottom: 3rem;

  @include bp-large {
    padding-top: 11rem;
    padding-bottom: 6rem;
  }

  @include bp-xlarge {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }
}

.o-section--product {
  padding-top: 3.3rem;
  padding-bottom: 3.3rem;

  & + & {
    margin-top: 4.4rem;

    @include bp-large {
      margin-top: 7.8rem;
    }
  }

  @include bp-large {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include bp-xlarge {
    padding-left: 12rem;
    padding-right: 12rem;
    max-width: 108rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.o-section--about {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include bp-large {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  a {
    color: $color-secondary;
  }
}

.o-section--retail-supports {
  padding-top: 7rem;
  padding-bottom: 7rem;

  @include bp-large {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .c-btn {
    margin: 2rem 0 0;
  }
}

.o-section--built-for-you-header {
  padding-bottom: 3rem;
  text-align: center;

  p {
    margin-inline: auto;

    @include bp-xlarge {
      font-size: 2.4rem;
      max-width: none;
    }
  }
}
