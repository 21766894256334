.c-newsletter-footer {
  background: $color-secondary-bg;
  color: $color-bright;
  padding: 2rem 0;

  @include bp-large {
    padding: 5rem 0 3rem;
  }

  .o-newsletter-form__heading {
    color: inherit;
    margin-bottom: 1rem;

    @include bp-large {
      letter-spacing: 0.26px;
    }

    @include bp-xlarge {
      font-size: 3rem;
    }
  }

  .o-newsletter-form__intro {
    color: inherit;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.2px;

    @include bp-large {
      max-width: calc(100% - 22rem);
    }

    @include bp-xlarge {
      max-width: calc(100% - 24rem);
      font-size: 1.8rem;
    }
  }

  .o-newsletter-form__inner {
    margin-bottom: 2rem;
  }

  .o-newsletter-form__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem 2rem;

    @include bp-medium {
      margin-bottom: 0;
    }

    @include bp-large {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    @include bp-xlarge {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }

  .o-newsletter-form__field-group {
    flex: 0 0 auto;
    width: 100%;
    padding: 0 1rem;
    margin-top: 2rem;
    font-size: 1.6rem;

    @include bp-medium {
      width: 25%;
      margin-bottom: 0;
    }

    @include bp-large {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include bp-xlarge {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    label {
      display: block;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    input {
      display: block;
      border-radius: 0;
      border: 0;
      font: inherit;
      color: inherit;
      background: none;
      width: 100%;
      border-bottom: 3px solid currentColor;
    }

    /* stylelint-disable selector-class-pattern, selector-no-qualifying-type */
    div.mce_inline_error {
      margin-top: 0.5rem;
      font-size: 1.4rem;
    }
    /* stylelint-enable selector-class-pattern, selector-no-qualifying-type */
  }

  .o-newsletter-form__spam-note {
    display: none;
  }

  .o-newsletter-form__button-wrapper {
    flex: 0 0 auto;
    text-align: center;

    @include bp-medium {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 0;
      margin-left: -1rem;
      margin-right: -1rem;
    }

    @include bp-large {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    @include bp-xlarge {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }

  .c-btn--footer-sign-up {
    @include bp-medium {
      min-width: auto;
      width: calc(25% - 2rem);
      margin-right: 1rem;
    }

    @include bp-large {
      width: calc(25% - 4rem);
      margin-right: 2rem;
    }

    @include bp-xlarge {
      width: calc(25% - 6rem);
      margin-right: 3rem;
    }
  }

  .o-newsletter-form__responses {
    padding-bottom: 2rem;

    @include bp-medium {
      text-align: right;
    }
  }

  .o-newsletter-form__footer {
    color: $color-primary;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0.24px;

    a {
      color: inherit;
    }
  }
}
