/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/* stylelint-disable */
.c-btn,
.hs_submit input {
  display: inline-block;
  vertical-align: middle;
  border-radius: $radius * 0.6;
  padding: 1.2rem 2rem;
  border: 0;
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
  font: bold 1.3rem SharpSans, sans-serif;
  letter-spacing: 0.2px;
  text-align: center;
  text-transform: uppercase;
  transition: all $transition;
  cursor: pointer;
  box-shadow: none;
}

.c-btn[disabled] {
  background-color: #b8bfca;
}

.c-btn:not(.c-btn--footer):not(.c-btn--small):not(.c-btn--resources):not(.c-btn--footer-sign-up),
.hs_submit input {
  @include bp-large {
    padding: 1.8rem 2.4rem;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }

  @include bp-xlarge {
    padding: 2.4rem 3rem;
    font-size: 2rem;
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary,
.hs_submit input {
  color: $color-bright;
  background: $color-primary;
  text-decoration: none;

  &:hover,
  &:active {
    background-color: $color-primary-light;
    outline: none;
  }

  &:focus {
    background: $color-secondary-bg;
    outline: none;
  }
}

/* stylelint-enable */

.c-btn--footer {
  margin: 0;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: none;
  border-radius: $radius;
}

.c-btn--resources {
  margin: 0;
  min-width: 16rem;

  @include bp-large {
    padding: 1.8rem 2.4rem;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }
}

.c-btn--footer-sign-up {
  margin: 0;
  padding: 1.5rem;
  min-width: 18rem;
  font-size: 2.1rem;
  letter-spacing: 1.3px;

  @include bp-medium {
    min-width: 0;
  }

  @include bp-large {
    min-width: 18rem;
  }

  &:focus {
    background: rgba($color-primary-light, 0.6);
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.85rem 0.65rem;
  border: 0;
  border-radius: 0.33rem;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;

  @include bp-large {
    font-size: 1.6rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}
