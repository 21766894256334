/* stylelint-disable value-list-comma-space-after, indentation */

$hamburger-transition: 400ms;

.c-hamburger {
  padding: 2.5rem;
  margin-right: -$padding;

  @include bp-large {
    display: none;
  }
}

.c-hamburger__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.4rem;
  height: 2rem;
  // transform: translateZ(1px);
  cursor: pointer;
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  height: 4px;
  transform-origin: center center;
  transition: transform $hamburger-transition * 0.5 $hamburger-transition * 0.5,
    opacity 0s $hamburger-transition * 0.5;
}

.c-hamburger__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: $color-tertiary;
  transition: transform $hamburger-transition * 0.5;
}

.has-menu-opened {
  .c-hamburger__bar {
    transition: transform $hamburger-transition * 0.5,
      opacity 0s $hamburger-transition * 0.5;

    &:first-child {
      transform: translateY(0.8rem);
    }

    &:last-child {
      transform: translateY(-0.8rem);
    }

    &:not(:first-child):not(:last-child) {
      opacity: 0;
    }
  }

  .c-hamburger__inner {
    transition: transform $hamburger-transition * 0.5 $hamburger-transition *
      0.5;
  }

  :first-child > .c-hamburger__inner {
    transform: rotate(45deg);
  }

  :last-child > .c-hamburger__inner {
    transform: rotate(-45deg);
  }
}

/* stylelint-enable value-list-comma-space-after, indentation */
