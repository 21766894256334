.c-sunski {
  padding-top: 4rem;
  padding-bottom: 6rem;

  @include bp-large {
    padding-top: 8rem;
    padding-bottom: 10rem;
  }

  @include bp-large {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.c-sunski__logo {
  max-width: 14rem;

  @include bp-large {
    max-width: 18.8rem;
  }
}

.c-sunski__blockquote {
  margin: 4rem auto 0;

  @include bp-large {
    margin: 6rem auto 2rem;
  }
}

.c-sunski__paragraph {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.7rem;
  max-width: 40rem;
  font-weight: 400;

  @include bp-large {
    font-size: 2rem;
    max-width: 56rem;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
    max-width: 61rem;
  }
}

.c-sunski__footer {
  @include bp-large {
    margin-top: 4rem;
  }

  @include bp-xlarge {
    font-size: 1.6rem;
  }
}
