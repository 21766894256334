.c-toolbar {
  display: flex;
  justify-content: space-between;
  height: 52px;
  list-style: none;
  margin: 0;

  li {
    margin: 0;
  }

  @include bp-small {
    justify-content: flex-start;
    gap: 51px;
  }

  @include bpmax($bp-large - 1) {
    &.c-toolbar--top {
      display: none;
    }
  }
}

.c-toolbar--top {
  li {
    display: grid;
    place-items: center;
  }

  .is-active {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      height: 3px;
      bottom: 0;
      left: 0;
      right: 0;
      background: #d4d4d5;
    }
  }
}

.c-toolbar--mobile {
  margin-top: 32px;
  gap: 20px;
  height: auto;
  align-items: center;
}

.c-toolbar__link {
  display: block;
  flex: 1;
  transition: all 300ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.c-toolbar__logo--vision {
  position: relative;
  top: -1px;
  width: 66px;
}

.c-toolbar__logo--platform {
  position: relative;
  top: -1px;
  width: 98px;
}
