.c-results {
  padding-top: 3.7rem;
  padding-bottom: 1rem;

  @include bp-large {
    padding-top: 8rem;
    padding-bottom: 5rem;
  }

  @include bp-xlarge {
    padding-top: 7rem;
    padding-bottom: 6rem;
  }
}

.c-results__heading {
  @include bp-xlarge {
    margin-bottom: 2rem;
  }
}

.c-results__paragraph {
  @include bp-large {
    font-size: 2.4rem;
    max-width: none;
  }
}

.c-results__item {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 2rem 0 0;
  letter-spacing: 1.15px;

  @include bp-large {
    font-size: 2rem;
    letter-spacing: 0.34px;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
    letter-spacing: 0.4px;
  }

  strong {
    color: $color-primary-light;
  }
}

.c-results__props {
  margin-top: 4rem;
}
