.c-start {
  padding: 10rem 1.1rem 5.8rem;

  @include bp-large {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.3rem 0 17.2rem;
  }

  @include bp-xlarge {
    padding: 19.1rem 0 24.8rem;
  }
}

.c-start__inner {
  position: relative;
  padding: 6.2rem 2rem 4rem;
  background: radial-gradient(circle at center -30%, #e6efe2, #c0f6fb);
  transition: all $transition;

  @include bp-small {
    text-align: center;
  }

  @include bp-large {
    text-align: left;
    width: 76.8rem;
    padding: $padding_large;
  }

  @include bp-xlarge {
    width: 96rem;
  }
}

.c-start__question-mark {
  position: absolute;
  max-width: 5.3rem;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: all $transition;

  @include bp-large {
    top: 50%;
    left: auto;
    right: 9rem;
    max-width: 10.6rem;
    transform: translateY(-50%);
  }

  @include bp-xlarge {
    max-width: 13.3rem;
    right: 11.1rem;
  }
}

.c-start__heading {
  transition: all $transition;

  @include bp-medium {
    margin-left: auto;
    margin-right: auto;
  }

  @include bp-large {
    margin: 0;
  }
}

.c-start__paragraph {
  max-width: 23rem;
  transition: all $transition;

  @include bp-small {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include bp-large {
    max-width: 80%;
    margin: 2.5rem 0 0;
  }

  @include bp-xlarge {
    max-width: 65%;
  }
}

.c-start__button {
  transition: all $transition;

  @include bp-large {
    margin: 3rem 0 0;
  }
}
