.c-related-posts {
  padding-bottom: 6.7rem;

  &::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    max-width: 746px;
    margin-bottom: 3.6rem;
    background-color: $color-primary-bg;
  }

  @include bp-large {
    padding-bottom: 13.4rem;

    &::before {
      margin-bottom: 7.2rem;
    }
  }
}

.c-related-posts__title {
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;

  @include bp-large {
    margin-bottom: 3.2rem;
  }
}

.c-related-posts__container {
  max-width: 1022px;
  display: grid;
  row-gap: 32px;

  @include bp-medium {
    grid-template-columns: 1fr 1fr;
    column-gap: 2.4rem;
  }

  @include bp-large {
    row-gap: 2.4rem;
  }

  @include bp-xlarge {
    column-gap: 1rem;
  }
}

.c-related-posts__item {
  display: block;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #e6e6e6;

  @include bp-medium {
    &:last-child,
    &:nth-last-child(2) {
      border: 0;
      padding-bottom: 0;
    }
  }

  @include bp-xlarge {
    display: grid;
    grid-template-columns: 170px auto;
    grid-template-rows: auto 1fr;
    column-gap: 2rem;
  }
}

.c-related-posts__item-picture {
  margin-bottom: 1.6rem;
  display: block;

  @include bp-xlarge {
    margin-bottom: 0;
    grid-row: 1 / 3;
  }
}

.c-related-posts__item-image {
  aspect-ratio: 290 / 100;
  width: 100%;
  object-fit: cover;

  @include bp-xlarge {
    aspect-ratio: 170 / 100;
  }
}

.c-related-posts__item-details-title {
  margin-bottom: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #b3bfca;
  letter-spacing: 0.37px;

  @include bp-xlarge {
    grid-column-start: 2;
  }
}

.c-related-posts__item-title {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.27;
  color: $color-secondary-bg;

  @include bp-xlarge {
    grid-column-start: 2;
  }
}
