/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  display: block;
  quotes: "“" "”" "‘" "’";
  font-style: italic;
  margin-top: 4rem;
  margin-bottom: 4rem;

  p {
    font-size: 1.7rem;
    font-weight: 700;
    font-family: Lato, sans-serif;
    letter-spacing: 0.19px;
    line-height: 2.8rem;
    max-width: 100%;

    @include bp-large {
      font-size: 2.6rem;
      line-height: 3.6rem;
      letter-spacing: 0.3px;
    }
  }

  footer {
    margin-top: 2rem;
    color: $color-secondary;
    font-size: 1.4rem;

    @include bp-large {
      font-size: 1.6rem;
    }
  }

  cite {
    font-style: normal;
  }

  span {
    display: block;
  }
}
