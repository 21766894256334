@charset "UTF-8";
/*
  Project: Traverse
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin: 0;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin: 0;
}

/* stylelint-disable value-list-comma-space-after */
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Book.woff2") format("woff2"), url("../assets/fonts/SharpSans-Book.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Medium.woff2") format("woff2"), url("../assets/fonts/SharpSans-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Semibold.woff2") format("woff2"), url("../assets/fonts/SharpSans-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Bold.woff2") format("woff2"), url("../assets/fonts/SharpSans-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Extrabold.woff2") format("woff2"), url("../assets/fonts/SharpSans-Extrabold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}
abbr {
  text-decoration: none;
  border: 0;
}

abbr[title] {
  text-decoration: none;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  display: block;
  quotes: "“" "”" "‘" "’";
  font-style: italic;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
blockquote p {
  font-size: 1.7rem;
  font-weight: 700;
  font-family: Lato, sans-serif;
  letter-spacing: 0.19px;
  line-height: 2.8rem;
  max-width: 100%;
}
@media (min-width: 1024px) {
  blockquote p {
    font-size: 2.6rem;
    line-height: 3.6rem;
    letter-spacing: 0.3px;
  }
}
blockquote footer {
  margin-top: 2rem;
  color: #045b7f;
  font-size: 1.4rem;
}
@media (min-width: 1024px) {
  blockquote footer {
    font-size: 1.6rem;
  }
}
blockquote cite {
  font-style: normal;
}
blockquote span {
  display: block;
}

body {
  font-size: 1.5rem;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
}

h2 {
  margin-bottom: 2rem;
  color: #045b7f;
  font-size: 1.9rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 1.47;
  transition: all 250ms;
}
@media (min-width: 1024px) {
  h2 {
    font-size: 2.8rem;
    letter-spacing: 0.37px;
    line-height: 1.32;
  }
}
@media (min-width: 1440px) {
  h2 {
    margin-bottom: 5rem;
    font-size: 3.8rem;
  }
}

p + h2 {
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  p + h2 {
    margin-top: 6rem;
  }
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

input {
  font-family: Lato, sans-serif;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #fff;
  text-decoration: underline;
}

p a {
  color: inherit;
  text-decoration: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
  list-style-type: none;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #045b7f;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    max-width: 72rem;
  }
}
@media (min-width: 1440px) {
  p {
    font-size: 2rem;
    max-width: 96rem;
  }
}

section:not([class*=o-section]):not([class*=c-]) {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 1024px) {
  section:not([class*=o-section]):not([class*=c-]) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

@media (min-width: 1024px) {
  .o-two-cols {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .o-two-cols__col {
    width: 45%;
  }
}
@media (min-width: 1440px) {
  .o-two-cols__col {
    width: 42.5%;
  }
}

.o-content {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 824px;
  padding-left: 30px;
  padding-right: 30px;
}
@media (min-width: 1024px) {
  .o-content {
    max-width: none;
    padding-left: 130px;
    padding-right: 130px;
  }
}
@media (min-width: 1340px) {
  .o-content {
    padding: 0;
    max-width: 1080px;
  }
}

@media (min-width: 1024px) {
  .o-content--narrow {
    max-width: 76.8rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1440px) {
  .o-content--narrow {
    max-width: 960px;
  }
}

@media (min-width: 1024px) {
  .o-content--jobs {
    max-width: 90rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1440px) {
  .o-content--jobs {
    max-width: 1135px;
  }
}

.o-indent {
  padding-left: 60px;
  padding-right: 60px;
}

.o-indent--large-up {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1024px) {
  .o-indent--large-up {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.o-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable no-descending-specificity */
.o-pagination__item {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.6rem;
  color: #08668d;
  text-align: center;
}
.o-pagination__item button + button {
  margin-left: 2px;
}
.o-pagination__item button {
  min-height: 3.2rem;
  min-width: 3.2rem;
  border: 0;
  color: inherit;
  background: transparent;
  cursor: pointer;
}
.o-pagination__item button:hover {
  text-decoration: underline;
}
.o-pagination__item .is-active {
  color: #2cb9dc;
  background: rgba(44, 185, 220, 0.1);
}

.o-pagination__item--nr {
  display: flex;
}
.o-pagination__item--nr button {
  padding: 0 0.3rem;
  border-radius: 50%;
}

.o-pagination__item--prev button,
.o-pagination__item--next button {
  padding: 0;
}

.o-pagination__item--prev {
  margin-right: 1.4rem;
}
.o-pagination__item--prev svg {
  margin-right: 1rem;
}

.o-pagination__item--next {
  margin-left: 1.4rem;
}
.o-pagination__item--next svg {
  margin-left: 1rem;
}

/* stylelint-enable no-descending-specificity */
.o-resources-grid-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
}
@media (min-width: 1024px) {
  .o-resources-grid-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.o-resources-grid-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}
@media (min-width: 1024px) {
  .o-resources-grid-row--two-columns {
    margin-right: 14.2857142857%;
  }
}

.o-resources-grid-col {
  flex: 0 0 auto;
  padding: 0 0.5rem;
}
.o-resources-grid-col--outer-spacer {
  display: none;
}
@media (min-width: 1024px) {
  .o-resources-grid-col--outer-spacer {
    display: block;
    width: 4.1666666667%;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-col--outer-spacer {
    display: block;
    width: 12.5%;
  }
}
.o-resources-grid-col--outer-main {
  width: 100%;
}
@media (min-width: 1024px) {
  .o-resources-grid-col--outer-main {
    width: 95.8333333333%;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-col--outer-main {
    width: 87.5%;
  }
}
.o-resources-grid-col--case-study {
  width: 100%;
}
@media (min-width: 768px) {
  .o-resources-grid-col--case-study {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .o-resources-grid-col--case-study {
    width: 33.3333333333%;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-col--case-study {
    width: 28.5714285714%;
  }
}
.o-resources-grid-col--case-study-spacer {
  display: none;
}
@media (min-width: 1440px) {
  .o-resources-grid-col--case-study-spacer {
    display: block;
    width: 4.7619047619%;
  }
}
.o-resources-grid-col--case-study-button {
  width: 100%;
}
@media (min-width: 1024px) {
  .o-resources-grid-col--case-study-button {
    padding-top: 2rem;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-col--case-study-button {
    padding-top: 5rem;
    width: 95.2380952381%;
  }
}
.o-resources-grid-col--two-columns-main {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .o-resources-grid-col--two-columns-main {
    width: 71.4285714286%;
    margin-bottom: 0;
    padding-right: 1rem;
  }
}
@media (min-width: 1024px) {
  .o-resources-grid-col--two-columns-main {
    width: 72.2222222222%;
  }
}
@media (min-width: 1440px) {
  .o-resources-grid-col--two-columns-main {
    width: 77.7777777778%;
  }
}
.o-resources-grid-col--data-sheet {
  width: 100%;
}
@media (min-width: 768px) {
  .o-resources-grid-col--data-sheet {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .o-resources-grid-col--data-sheet {
    width: 42.8571428571%;
  }
}
.o-resources-grid-col--data-sheet-spacer {
  display: none;
}
@media (min-width: 1024px) {
  .o-resources-grid-col--data-sheet-spacer {
    display: block;
    width: 4.7619047619%;
  }
}

.o-section--peak-performance {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .o-section--peak-performance {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
@media (min-width: 1440px) {
  .o-section--peak-performance {
    padding-top: 14rem;
    padding-bottom: 12rem;
  }
}
.o-section--peak-performance h2 {
  margin: 0;
}
.o-section--peak-performance p {
  max-width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 0;
}
@media (min-width: 480px) {
  .o-section--peak-performance p {
    max-width: 40rem;
  }
}
@media (min-width: 1024px) {
  .o-section--peak-performance p {
    max-width: 59rem;
    margin-top: 1.9rem;
  }
}
@media (min-width: 1440px) {
  .o-section--peak-performance p {
    max-width: 78rem;
    margin-top: 3.2rem;
  }
}
.o-section--peak-performance a {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .o-section--peak-performance a {
    margin-top: 7rem;
  }
}
@media (min-width: 1440px) {
  .o-section--peak-performance a {
    margin-top: 12rem;
  }
}

.o-section--testimonial {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 1024px) {
  .o-section--testimonial {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .o-section--testimonial blockquote {
    max-width: 74rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.o-section--cta {
  overflow: hidden;
  padding-top: 24rem;
  padding-bottom: 3rem;
}
@media (min-width: 1024px) {
  .o-section--cta {
    padding-bottom: 9rem;
  }
}
@media (min-width: 1024px) {
  .o-section--cta p {
    max-width: 46rem;
  }
}
@media (min-width: 1440px) {
  .o-section--cta p {
    max-width: 54rem;
  }
}

.o-section--why-traverse {
  padding-top: 3rem;
  padding-bottom: 8rem;
}
@media (min-width: 1024px) {
  .o-section--why-traverse {
    padding-top: 8.4rem;
    padding-bottom: 13rem;
  }
}

.o-section--capabilities {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 1024px) {
  .o-section--capabilities {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media (min-width: 1440px) {
  .o-section--capabilities {
    padding-top: 12rem;
    padding-bottom: 16rem;
  }
}
.o-section--capabilities h2 {
  margin: 0;
}
.o-section--capabilities p {
  max-width: none;
}
.o-section--capabilities .c-btn {
  margin: 3rem 0 0;
}
@media (min-width: 1024px) {
  .o-section--capabilities .c-btn {
    margin: 10rem 0 0;
  }
}

.o-section--right-solution {
  padding-top: 3.8rem;
  padding-bottom: 4.2rem;
}
@media (min-width: 1024px) {
  .o-section--right-solution {
    padding-top: 9.1rem;
    padding-bottom: 7.5rem;
  }
}
.o-section--right-solution h2 {
  margin: 0;
}
@media (min-width: 1024px) {
  .o-section--right-solution h2 {
    text-align: center;
    max-width: none;
  }
}
.o-section--right-solution .c-btn {
  margin: 6rem 0 0;
}
@media (min-width: 1024px) {
  .o-section--right-solution .c-btn {
    margin-top: 7rem;
  }
}
@media (min-width: 1440px) {
  .o-section--right-solution .c-btn {
    margin-top: 8rem;
  }
}
.o-section--right-solution br {
  display: none;
}
@media (min-width: 1024px) {
  .o-section--right-solution br {
    display: block;
  }
}

.o-section--plain-center {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
.o-section--plain-center p {
  margin-inline: auto;
}
@media (min-width: 1024px) {
  .o-section--plain-center {
    padding-top: 10rem;
  }
}
@media (min-width: 1024px) {
  .o-section--plain-center h2 {
    margin-bottom: 4rem;
  }
}
@media (min-width: 1440px) {
  .o-section--plain-center h2 {
    letter-spacing: 0;
  }
}

@media (min-width: 1024px) {
  .o-section--supply-chain {
    padding-top: 0;
  }
}
@media (min-width: 1440px) {
  .o-section--supply-chain {
    padding-top: 2rem;
  }
}
.o-section--supply-chain h2 {
  margin-top: 0;
}
@media (min-width: 1024px) {
  .o-section--supply-chain h2 {
    text-align: center;
    margin-bottom: 4.6rem;
  }
}

.o-section--gleam {
  padding-top: 7.5rem;
  padding-bottom: 10rem;
}
@media (min-width: 1024px) {
  .o-section--gleam {
    padding-top: 14rem;
  }
}
@media (min-width: 1440px) {
  .o-section--gleam {
    padding-top: 20rem;
    padding-bottom: 15rem;
  }
}
.o-section--gleam p {
  max-width: 80%;
}
@media (min-width: 768px) {
  .o-section--gleam p {
    max-width: 50%;
  }
}
@media (min-width: 1440px) {
  .o-section--gleam p {
    max-width: 70rem;
  }
}
.o-section--gleam a {
  margin-bottom: 0;
}

.o-section--gleam-jobs {
  padding-top: 6rem;
  padding-bottom: 5rem;
}
@media (min-width: 1024px) {
  .o-section--gleam-jobs {
    padding-top: 14rem;
  }
}
@media (min-width: 1440px) {
  .o-section--gleam-jobs {
    padding-top: 16.7rem;
    padding-bottom: 14.6rem;
  }
}

.o-section--upgrade {
  padding-top: 5.2rem;
  padding-bottom: 3rem;
}
@media (min-width: 1024px) {
  .o-section--upgrade {
    padding-top: 11rem;
    padding-bottom: 6rem;
  }
}
@media (min-width: 1440px) {
  .o-section--upgrade {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }
}

.o-section--product {
  padding-top: 3.3rem;
  padding-bottom: 3.3rem;
}
.o-section--product + .o-section--product {
  margin-top: 4.4rem;
}
@media (min-width: 1024px) {
  .o-section--product + .o-section--product {
    margin-top: 7.8rem;
  }
}
@media (min-width: 1024px) {
  .o-section--product {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media (min-width: 1440px) {
  .o-section--product {
    padding-left: 12rem;
    padding-right: 12rem;
    max-width: 108rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.o-section--about {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 1024px) {
  .o-section--about {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
.o-section--about a {
  color: #045b7f;
}

.o-section--retail-supports {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
@media (min-width: 1024px) {
  .o-section--retail-supports {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
}
.o-section--retail-supports .c-btn {
  margin: 2rem 0 0;
}

.o-section--built-for-you-header {
  padding-bottom: 3rem;
  text-align: center;
}
.o-section--built-for-you-header p {
  margin-inline: auto;
}
@media (min-width: 1440px) {
  .o-section--built-for-you-header p {
    font-size: 2.4rem;
    max-width: none;
  }
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 824px;
  padding-left: 30px;
  padding-right: 30px;
}
@media (min-width: 1024px) {
  .o-wrapper {
    max-width: 1320px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 1320px) {
  .o-wrapper {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1440px;
  }
}

/* stylelint-disable */
.o-wysiwyg-content p {
  font-size: inherit;
  line-height: inherit;
  max-width: none;
}
.o-wysiwyg-content ul,
.o-wysiwyg-content ol {
  margin: 0;
}
.o-wysiwyg-content ul li,
.o-wysiwyg-content ol li {
  text-indent: 20px;
}
.o-wysiwyg-content ol {
  list-style-position: outside;
  margin-left: 20px;
}
.o-wysiwyg-content ol > li {
  list-style-type: decimal;
}
.o-wysiwyg-content ol > li > ul {
  text-indent: 37px;
  margin-left: 24px;
}
.o-wysiwyg-content ul {
  margin-left: 22px;
  list-style-position: inside;
}
.o-wysiwyg-content ul > li {
  list-style-type: none;
  position: relative;
  text-indent: 15px;
}
.o-wysiwyg-content ul > li:before {
  content: "";
  width: 4px;
  border-top: 2px solid #386180;
  position: absolute;
  top: 11px;
  left: 0;
}
.o-wysiwyg-content ul > li > ul {
  margin-left: 30px;
}
.o-wysiwyg-content ul > li > ol > li {
  text-indent: 0;
}
@media (min-width: 768px) {
  .o-wysiwyg-content ol,
.o-wysiwyg-content ul {
    margin: 0 0 0 40px;
  }
  .o-wysiwyg-content ol li,
.o-wysiwyg-content ul li {
    text-indent: 0;
  }
  .o-wysiwyg-content ol > li {
    margin-bottom: 3rem;
  }
  .o-wysiwyg-content ol > li > ul {
    margin-top: 3rem;
    margin-left: 8rem;
  }
  .o-wysiwyg-content ol > li > ol {
    margin-top: 3rem;
  }
  .o-wysiwyg-content ol > li > ol > li {
    margin-bottom: 0.5rem;
  }
  .o-wysiwyg-content ul > li:before {
    top: 15px;
    left: -35px;
    width: 6px;
    border-top: 3px solid #386180;
  }
  .o-wysiwyg-content ul > li > ul {
    margin-top: 3rem;
    margin-left: 6rem;
  }
  .o-wysiwyg-content ul > li > ol {
    margin-top: 3rem;
  }
  .o-wysiwyg-content ul > li > ol > li {
    margin-bottom: 0.5rem;
  }
}

/* stylelint-enable */
.c-blog {
  padding-block: 3.5rem 7.5rem;
}
@media (min-width: 1024px) {
  .c-blog {
    padding-block: 7rem 15rem;
  }
}

.c-blog__filters {
  display: flex;
  gap: 2.4rem;
  margin-bottom: 3.5rem;
  padding-bottom: 2rem;
  color: #08668d;
  border-bottom: 1px solid #e6e6e6;
  overflow-y: auto;
  align-items: flex-start;
}
@media (min-width: 1024px) {
  .c-blog__filters {
    margin-bottom: 7rem;
  }
}

.c-blog__filters-title {
  font-size: 2rem;
}

.c-blog__filters-list {
  display: flex;
  gap: 2.4rem;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .c-blog__filters-list {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}

.c-blog__filters-button {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  border: 0;
  background: none;
  transition: color 250ms;
  cursor: pointer;
}
.c-blog__filters-button.is-active {
  color: #2cb9dc;
}
.c-blog__filters-button:hover {
  color: #42d7fd;
}

.c-blog__filters-button--FIRST {
  color: #2cb9dc;
}

@media (min-width: 1440px) {
  .c-blog__title-top {
    margin-bottom: 7rem;
    font-size: 3.2rem;
  }
}

.c-blog__title-bottom {
  margin-block: 4.2rem 3.5rem;
  padding-top: 3.5rem;
  border-top: 10px solid #08668d;
  letter-spacing: 0.5px;
}
@media (min-width: 1440px) {
  .c-blog__title-bottom {
    margin-block: 8.4rem 7rem;
    padding-top: 7rem;
  }
}

.c-blog__posts {
  display: grid;
  gap: 4.4rem;
}

.c-blog__posts-empty {
  display: none;
  font-size: 2.4rem;
  color: #08668d;
  font-weight: 700;
  text-align: center;
}
.c-blog__posts-empty.is-visible {
  display: block;
}

.c-blog__pagination {
  margin-top: 3.2rem;
}
.c-blog__pagination.is-hidden {
  display: none;
}
@media (min-width: 1024px) {
  .c-blog__pagination {
    margin-top: 4rem;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/* stylelint-disable */
.c-btn,
.hs_submit input {
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
  padding: 1.2rem 2rem;
  border: 0;
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
  font: bold 1.3rem SharpSans, sans-serif;
  letter-spacing: 0.2px;
  text-align: center;
  text-transform: uppercase;
  transition: all 250ms;
  cursor: pointer;
  box-shadow: none;
}

.c-btn[disabled] {
  background-color: #b8bfca;
}

@media (min-width: 1024px) {
  .c-btn:not(.c-btn--footer):not(.c-btn--small):not(.c-btn--resources):not(.c-btn--footer-sign-up),
.hs_submit input {
    padding: 1.8rem 2.4rem;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }
}
@media (min-width: 1440px) {
  .c-btn:not(.c-btn--footer):not(.c-btn--small):not(.c-btn--resources):not(.c-btn--footer-sign-up),
.hs_submit input {
    padding: 2.4rem 3rem;
    font-size: 2rem;
  }
}

/* Style variants
   ========================================================================== */
.c-btn--primary,
.hs_submit input {
  color: #fff;
  background: #2cb9dc;
  text-decoration: none;
}
.c-btn--primary:hover, .c-btn--primary:active,
.hs_submit input:hover,
.hs_submit input:active {
  background-color: #42d7fd;
  outline: none;
}
.c-btn--primary:focus,
.hs_submit input:focus {
  background: #08668d;
  outline: none;
}

/* stylelint-enable */
.c-btn--footer {
  margin: 0;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: none;
  border-radius: 10px;
}

.c-btn--resources {
  margin: 0;
  min-width: 16rem;
}
@media (min-width: 1024px) {
  .c-btn--resources {
    padding: 1.8rem 2.4rem;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }
}

.c-btn--footer-sign-up {
  margin: 0;
  padding: 1.5rem;
  min-width: 18rem;
  font-size: 2.1rem;
  letter-spacing: 1.3px;
}
@media (min-width: 768px) {
  .c-btn--footer-sign-up {
    min-width: 0;
  }
}
@media (min-width: 1024px) {
  .c-btn--footer-sign-up {
    min-width: 18rem;
  }
}
.c-btn--footer-sign-up:focus {
  background: rgba(66, 215, 253, 0.6);
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.85rem 0.65rem;
  border: 0;
  border-radius: 0.33rem;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .c-btn--small {
    font-size: 1.6rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-capabilities__list {
  display: block;
}
@media (min-width: 1024px) {
  .c-capabilities__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .c-capabilities__item {
    width: calc(50% - 2.5rem);
  }
}
@media (min-width: 1440px) {
  .c-capabilities__item {
    width: calc(50% - 3.5rem);
  }
}

.c-capabilities__heading {
  margin-top: 4rem;
  color: #045b7f;
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 900;
  letter-spacing: 0.25px;
}
@media (min-width: 1024px) {
  .c-capabilities__heading {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}
@media (min-width: 1440px) {
  .c-capabilities__heading {
    margin-top: 7rem;
    font-size: 2.2rem;
  }
}

.c-capabilities__paragraph {
  margin: 1.5rem 0;
}
@media (min-width: 1024px) {
  .c-capabilities__paragraph:first-of-type {
    margin: 2rem 0;
  }
}
.c-capabilities__paragraph:last-child {
  margin-bottom: 0;
}

.c-checklist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.c-checklist__label {
  display: flex;
  width: 50%;
  margin: 1rem 0;
  align-items: flex-start;
  cursor: pointer;
}
@media (min-width: 768px) {
  .c-checklist__label {
    width: 33.3333333333%;
  }
}

.c-checklist__input {
  position: absolute;
  visibility: hidden;
}

.c-checklist__pseudoinput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1.7rem;
  height: 1.7rem;
  border: 0.2rem solid #2cb9dc;
  border-radius: 0.3rem;
  margin: 0.1rem 1rem 0 0;
  background: white;
  transition: background-color 250ms;
}
:checked + .c-checklist__pseudoinput {
  background: #2cb9dc;
}

/* stylelint-disable */
_:-ms-fullscreen,
:root .c-checklist__pseudoinput {
  margin-top: -0.2rem;
}

/* stylelint-enable */
.c-checklist__checked-icon {
  width: 1.3rem;
}

.c-checklist__pseudolabel {
  color: #045b7f;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.c-checklist__pseudolabel-short {
  /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
}
@media (min-width: 480px) {
  .c-checklist__pseudolabel-short {
    display: none;
  }
}
.c-checklist__pseudolabel-short + .c-checklist__pseudolabel-default {
  display: none;
}
@media (min-width: 480px) {
  .c-checklist__pseudolabel-short + .c-checklist__pseudolabel-default {
    display: block;
  }
}

.c-clients-a {
  padding: 30px 0;
  background-color: #e6ebf5;
}
@media (min-width: 768px) {
  .c-clients-a {
    padding-block: 17px;
  }
}

.c-clients-a__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
  gap: 15px;
}
@media (min-width: 768px) {
  .c-clients-a__list {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.c-clients-a__item {
  width: 26%;
  margin: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .c-clients-a__item {
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.c-clients-a__image,
.c-clients-a__picture {
  display: block;
  margin: auto;
}
.c-clients-a__image--cvs,
.c-clients-a__picture--cvs {
  max-width: 6rem;
}
@media (min-width: 768px) {
  .c-clients-a__image--cvs,
.c-clients-a__picture--cvs {
    max-width: none;
    max-height: 4.8rem;
  }
}
.c-clients-a__image--kohls,
.c-clients-a__picture--kohls {
  max-height: 1.5rem;
}
@media (min-width: 768px) {
  .c-clients-a__image--kohls,
.c-clients-a__picture--kohls {
    max-height: 2rem;
  }
}
.c-clients-a__image--burlington,
.c-clients-a__picture--burlington {
  max-height: 4rem;
}
.c-clients-a__image--giant-eagle,
.c-clients-a__picture--giant-eagle {
  max-width: 5.5rem;
}
@media (min-width: 768px) {
  .c-clients-a__image--giant-eagle,
.c-clients-a__picture--giant-eagle {
    max-width: 8rem;
  }
}
.c-clients-a__image--michaels,
.c-clients-a__picture--michaels {
  max-height: 4.5rem;
}
@media (min-width: 768px) {
  .c-clients-a__image--michaels,
.c-clients-a__picture--michaels {
    max-height: 5rem;
  }
}
.c-clients-a__image--tractor,
.c-clients-a__picture--tractor {
  max-height: 3rem;
}
@media (min-width: 768px) {
  .c-clients-a__image--tractor,
.c-clients-a__picture--tractor {
    max-height: 3.9rem;
  }
}

@media (min-width: 768px) {
  .c-clients-a__image {
    transform: scale(0.8);
  }
}
@media (min-width: 1024px) {
  .c-clients-a__image {
    transform: scale(0.8);
  }
}
.c-clients-a__image--bw {
  filter: grayscale(1);
}

.c-clients-b {
  display: none;
}
@media (min-width: 1024px) {
  .c-clients-b {
    display: block;
  }
}

.c-clients-b__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
}
@media (min-width: 1024px) {
  .c-clients-b__list {
    margin-top: 8rem;
  }
}

.c-clients-b__item {
  width: 33%;
  margin: 0;
  text-align: center;
}
.c-clients-b__item:nth-child(n+4) {
  margin-top: 6rem;
}
@media (min-width: 1024px) {
  .c-clients-b__item:nth-child(n+4) {
    margin-top: 14rem;
  }
}
@media (min-width: 1024px) {
  .c-clients-b__item--retail:nth-child(4) {
    margin-top: 6rem;
    margin-left: 10rem;
  }
}
@media (min-width: 1024px) {
  .c-clients-b__item--retail:nth-child(5) {
    margin-top: 6rem;
    margin-right: 10rem;
  }
}

.c-clients-b__image,
.c-clients-b__picture {
  display: block;
  max-width: 13.5rem;
  max-height: 4.2rem;
  margin: auto;
}
@media (min-width: 1440px) {
  .c-clients-b__image,
.c-clients-b__picture {
    max-width: 20.7rem;
    max-height: 6.4rem;
  }
}

.c-clients-b__image--bw {
  filter: grayscale(1);
}

/* stylelint-disable */
.c-contact {
  padding: 2rem 0 0;
  position: relative;
  z-index: 10;
}

@media (min-width: 768px) {
  .c-contact__gloss {
    display: none;
  }
}

.c-contact__form {
  position: relative;
  padding: 3.5rem 0 5.5rem;
  margin-top: 3.5rem;
}
@media (min-width: 768px) {
  .c-contact__form {
    max-width: 70rem;
    padding: 6rem 8rem;
    border-radius: 1.5rem;
    margin-top: -6rem;
    margin-left: auto;
    margin-right: auto;
    background: #f1fafd;
    min-height: 750px;
  }
}
@media (min-width: 1440px) {
  .c-contact__form {
    margin-top: -50rem;
    margin-right: -12rem;
    margin-bottom: 5rem;
  }
}
.c-contact__form::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: -0.7rem;
  height: 1.5rem;
  left: -0.5rem;
  right: -30px;
  border-radius: 1.5rem 0 0 0;
  background-color: #b2d0dc;
}
@media (min-width: 768px) {
  .c-contact__form::before {
    display: none;
  }
}
.c-contact__form::after {
  display: none;
  content: "";
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1.5rem;
  background-color: rgba(1, 63, 88, 0.3);
  transform: translateX(1rem) translateY(1rem);
}
@media (min-width: 768px) {
  .c-contact__form::after {
    display: block;
  }
}

@media (min-width: 768px) {
  .c-contact__form--notification {
    display: grid;
    place-items: center;
    min-height: 440px;
  }
}
@media (min-width: 1440px) {
  .c-contact__form--notification {
    margin-top: -50rem;
    margin-bottom: 65rem;
  }
}

.c-contact__bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #f1fafd;
}
.c-contact__bg::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: -15px;
  right: 100%;
  top: 0;
  bottom: 0;
  border-radius: 1.5rem 0 0 1.5rem;
  background: inherit;
}
.c-contact__bg::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 30px;
  background: inherit;
}
@media (min-width: 768px) {
  .c-contact__bg {
    display: none;
  }
}

.c-contact__label,
.hs-form-field label {
  display: block;
  margin-top: 2rem;
}

.c-contact__pseudolabel {
  display: block;
  color: #045b7f;
  font-size: 1.6rem;
  font-weight: 700;
}

.c-contact__pseudolabel-info {
  font-weight: 400;
}

.c-contact__asterisk,
.hs-form-required {
  color: red;
}

.c-contact__checklist {
  margin: 3rem 0 4rem;
}

.c-contact__textinput,
input.hs-input {
  width: 100%;
  padding: 0.4rem;
  border: 0;
  border-bottom: 2px solid #2cb9dc;
  margin: 1rem 0 3rem;
  color: #091e3e;
  font-size: 2rem;
  background: transparent;
  transition: all 250ms;
}
.c-contact__textinput:focus,
input.hs-input:focus {
  outline: none;
  color: #091e3e;
  border-bottom-color: #091e3e;
}

.c-contact__textarea,
textarea.hs-fieldtype-textarea {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border: 0;
  min-height: 11rem;
  border-bottom: 2px solid #2cb9dc;
  line-height: 1.5;
  border-radius: 0.5rem 0.5rem 0 0;
  background: #fff;
  transition: border-color 250ms;
  resize: none;
}
.c-contact__textarea:focus,
textarea.hs-fieldtype-textarea:focus {
  outline: none;
  border-color: #091e3e;
}
.c-contact__textarea::placeholder,
textarea.hs-fieldtype-textarea::placeholder {
  color: #9b9b9b;
}

.c-contact__disclaimer {
  font-style: italic;
}

.c-contact__link {
  color: inherit;
  text-decoration: underline;
}
.c-contact__link--no-underline {
  text-decoration: none;
}

.c-contact .actions {
  text-align: center;
}

.c-contact__button,
.hs_submit input {
  margin-bottom: 0;
}

.c-contact__error-message {
  color: red;
}

@media (min-width: 1024px) {
  .c-contact__section-wrapper {
    display: flex;
    align-items: flex-start;
    max-width: 70rem;
    margin: 10rem auto;
    transform: translateX(1rem);
  }
}
@media (min-width: 1440px) {
  .c-contact__section-wrapper {
    flex-direction: column;
    position: absolute;
    max-width: none;
    width: 50rem;
    top: 0;
    margin: 0;
    transform: none;
  }
}

.c-contact__headquaters {
  padding: 5rem 0;
  color: #045b7f;
  text-align: center;
}
@media (min-width: 1024px) {
  .c-contact__headquaters {
    width: 45%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 3rem;
    text-align: left;
  }
}
@media (min-width: 1440px) {
  .c-contact__headquaters {
    width: 100%;
    padding: 6rem;
  }
}
.c-contact__headquaters .p-locality::after {
  content: ",";
}

@media (min-width: 1024px) {
  .c-contact__headquaters-heading {
    font-size: 2rem;
  }
}
@media (min-width: 1440px) {
  .c-contact__headquaters-heading {
    margin-bottom: 2rem;
  }
}

.c-contact__headquaters-line {
  text-align: center;
}
@media (min-width: 1024px) {
  .c-contact__headquaters-line {
    text-align: left;
    font-size: 2.1rem;
  }
}

.c-contact__address {
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (min-width: 1024px) {
  .c-contact__address {
    justify-content: flex-start;
  }
}

.c-contact__address-item + .c-contact__address-item {
  margin-left: 0.5rem;
}

.c-contact__direct {
  position: relative;
  padding: 4rem 0 6rem;
  color: #045b7f;
  font-size: 2.1rem;
  background-color: #c5f1fd;
}
@media (min-width: 1024px) {
  .c-contact__direct {
    width: 55%;
    padding: 30px 30px 90px;
  }
}
@media (min-width: 1440px) {
  .c-contact__direct {
    width: 100%;
    padding: 6rem;
  }
}
.c-contact__direct::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  right: 100%;
  background: inherit;
}
@media (min-width: 1024px) {
  .c-contact__direct::before {
    display: none;
  }
}
.c-contact__direct::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  right: -30px;
  background: inherit;
}
@media (min-width: 1024px) {
  .c-contact__direct::after {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-contact__direct-heading {
    font-size: 2rem;
  }
}
@media (min-width: 1440px) {
  .c-contact__direct-heading {
    margin-bottom: 1.5rem;
  }
}

.hs-error-msgs {
  margin-top: -40px;
  color: #d0021b;
}
.hs_error_rollup .hs-error-msgs {
  margin-top: 6px;
}

/* stylelint-enable */
.c-ecosystem__image-row {
  margin: 0 -2.5rem 4.5rem;
}
@media (min-width: 768px) {
  .c-ecosystem__image-row {
    margin: 0 0 8.5rem;
  }
}
@media (min-width: 1440px) {
  .c-ecosystem__image-row {
    margin: 10rem 0 12rem;
  }
}

.c-ecosystem__image-wrapper {
  max-width: 42rem;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 768px) {
  .c-ecosystem__image-wrapper {
    max-width: none;
    width: 62.5%;
  }
}

.c-ecosystem__image {
  padding-top: 105%;
  background-image: url("../assets/images/ecosystem/ecosystem-mobile.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.c-ecosystem__blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5rem;
}
.c-ecosystem__blocks::after {
  content: "";
  flex: auto;
  order: 99;
}
@media (min-width: 1440px) {
  .c-ecosystem__blocks {
    margin-right: -5.5555555556%;
  }
}

.c-ecosystem__section {
  flex: 0 0 auto;
  width: 100%;
  padding: 0.5rem;
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
}
@media (min-width: 768px) {
  .c-ecosystem__section {
    width: 46.6666666667%;
  }
}
@media (min-width: 1440px) {
  .c-ecosystem__section {
    width: 42.1052631579%;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(1) {
    order: 1;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(2) {
    order: 52;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(3) {
    order: 3;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(4) {
    order: 54;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(5) {
    order: 5;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(6) {
    order: 56;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(7) {
    order: 7;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(8) {
    order: 58;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(9) {
    order: 9;
  }
}
@media (max-width: 767px) {
  .c-ecosystem__section:nth-child(10) {
    order: 60;
  }
}
.c-ecosystem__section:not(:last-child) {
  margin-bottom: 4rem;
}

.c-ecosystem__section-title {
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin-left: -3rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 1.5rem;
  display: inline-block;
  min-width: 19rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .c-ecosystem__section-title {
    margin-left: 0;
    display: block;
  }
}
@media (min-width: 1024px) {
  .c-ecosystem__section-title {
    font-size: 2.2rem;
    letter-spacing: 0.36px;
    padding-left: 1.5rem;
  }
}

.c-ecosystem__section-list {
  color: #386180;
  font-size: 1.5rem;
}
@media (min-width: 1024px) {
  .c-ecosystem__section-list {
    font-size: 2rem;
  }
}

.c-ecosystem__section-list-element {
  list-style: disc;
  margin-left: 2rem;
  padding-left: 0.5rem;
}
@media (min-width: 768px) {
  .c-ecosystem__section-list-element {
    margin-left: 5rem;
  }
}
@media (min-width: 1024px) {
  .c-ecosystem__section-list-element {
    margin-left: 3.5rem;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer__main {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  background: linear-gradient(to bottom, #154072, #091e3e);
}
@media (min-width: 1024px) {
  .c-footer__main {
    font-size: 1.8rem;
    padding-top: 4.4rem;
    padding-bottom: 3.5rem;
  }
}

.c-footer__contact-wrapper {
  text-align: center;
}

.c-footer__contact-button {
  font-size: 1.28rem;
}
@media (min-width: 1024px) {
  .c-footer__contact-button {
    margin-top: 19px;
    font-size: 1.6rem;
    min-width: 147px;
    height: 48px;
    border-radius: 7px;
    letter-spacing: 0.25px;
    padding-block: 1.3rem;
  }
}

.c-footer__menu {
  margin-top: 2rem;
}

.c-footer__heading {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3.5rem;
  margin-bottom: 0.7rem;
  letter-spacing: 0.17px;
}
@media (min-width: 1024px) {
  .c-footer__heading {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-footer__heading--first {
    margin-bottom: 0.5rem;
  }
}

.c-footer__link,
.c-footer__linkish {
  display: block;
  text-decoration: none;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .c-footer__link,
.c-footer__linkish {
    font-size: 2rem;
    letter-spacing: 0.17px;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }
}
.c-footer__link br,
.c-footer__linkish br {
  display: none;
}
@media (min-width: 1024px) {
  .c-footer__link br,
.c-footer__linkish br {
    display: block;
  }
}

.c-footer__link--primary {
  text-decoration: none;
  color: #2cb9dc;
}
@media (min-width: 1024px) {
  .c-footer__link--primary {
    font-weight: 400;
    margin: 0;
  }
}

.c-footer__link--icon {
  display: flex;
  align-items: center;
}

.c-footer__icon {
  margin-left: 8px;
}

.c-footer__form {
  margin-top: 1rem;
  display: flex;
  max-width: 35rem;
}
@media (min-width: 1024px) {
  .c-footer__form {
    max-width: 39rem;
  }
}

.c-footer__form-input {
  display: block;
  flex-grow: 1;
  padding: 0.3rem 0.6rem;
  border: 0;
  border-radius: 0.33rem;
  color: #000;
  font-size: 1.2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 600;
  line-height: 1.5;
}
.c-footer__form-input::placeholder {
  color: #9b9b9b;
}
@media (min-width: 1024px) {
  .c-footer__form-input {
    font-size: inherit;
    font-weight: 300;
    padding: 0.45rem 1rem;
  }
}

.c-footer__form-button {
  margin-left: 0.6rem;
}
@media (min-width: 1024px) {
  .c-footer__form-button {
    margin-left: 1.6rem;
  }
}

.c-footer__logo {
  display: block;
  width: 100%;
  max-width: 14.6rem;
  margin: 5rem auto;
}
@media (min-width: 1024px) {
  .c-footer__logo {
    margin-right: 0;
    max-width: 18.3rem;
    margin-top: 18px;
  }
}

.c-footer__group {
  margin: 5rem auto;
  text-align: center;
  display: grid;
  gap: 10px;
}
.c-footer__group .c-footer__link {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  letter-spacing: 0.170455px;
}
@media (min-width: 1024px) {
  .c-footer__group {
    margin: 6.3rem 0 0;
    text-align: right;
  }
}

.c-footer__social {
  display: flex;
  margin: 1rem 0 2rem -1rem;
}

.c-footer__social-link {
  display: block;
  width: 5rem;
  height: 4.5rem;
  padding: 0.75rem 1rem;
}

.c-footer__toolbar {
  grid-area: toolbar;
  display: grid;
  gap: 24px;
  place-items: center;
  margin-left: 0;
  margin-bottom: 0;
}
.c-footer__toolbar li {
  margin: 0;
}
@media (min-width: 1024px) {
  .c-footer__toolbar {
    display: flex;
    justify-content: end;
    grid-column: 1/-1;
    grid-row: 3/4;
    gap: 66px;
    justify-self: stretch;
    margin-top: 30px;
    padding-top: 27px;
    border-top: 1px solid #08668d;
  }
}

.c-footer__toolbar-link {
  display: grid;
  transition: all 250ms;
}
.c-footer__toolbar-link:hover {
  opacity: 0.7;
}

.c-footer__toolbar-logo--vision {
  position: relative;
  width: 104px;
}

.c-footer__toolbar-logo--platform {
  position: relative;
  width: 146px;
}

@media (min-width: 1024px) {
  .c-footer__grid-container {
    display: grid;
    grid-template-columns: 20fr 20fr 20fr auto;
  }

  .c-footer__grid-item--1 {
    grid-column: 4;
    margin-bottom: 4.8rem;
    justify-self: end;
  }

  .c-footer__grid-item--2 {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 4.8rem;
  }

  .c-footer__grid-item--4 {
    grid-column: 1;
    grid-row: 2;
  }

  .c-footer__grid-item--5 {
    grid-column: 2;
    grid-row: 2/span 2;
  }

  .c-footer__grid-item--6 {
    grid-column: 3;
    grid-row: 2;
  }

  .c-footer__grid-item--7 {
    justify-self: end;
    grid-column: 4;
    grid-row: 2;
  }

  .c-footer__grid-item--8 {
    grid-column: 1/-1;
  }
}
@media (min-width: 1440px) {
  .c-footer__grid-container {
    grid-template-columns: 14fr 12fr 15fr auto 25fr;
  }

  .c-footer__grid-item--1 {
    grid-column: 5;
  }

  .c-footer__grid-item--3 {
    grid-row: 2/span 2;
  }

  .c-footer__grid-item--6 {
    grid-column: 3;
    grid-row: 2/span 2;
  }

  .c-footer__grid-item--7 {
    grid-column: 5;
    grid-row: 2;
  }

  .c-footer__grid-item--8 {
    grid-row: 4;
  }
}
.c-get-started {
  position: relative;
  padding-top: 12rem;
}

.c-get-started__bg {
  display: block;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.c-get-started__bg--circle {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
}
@media (min-width: 1024px) {
  .c-get-started__bg--circle {
    display: none;
  }
}

.c-get-started__bg--v-full {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  display: none;
}
@media (min-width: 1024px) {
  .c-get-started__bg--v-full {
    display: block;
  }
}

/* stylelint-disable value-list-comma-space-after, indentation */
.c-hamburger {
  padding: 2.5rem;
  margin-right: -30px;
}
@media (min-width: 1024px) {
  .c-hamburger {
    display: none;
  }
}

.c-hamburger__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.4rem;
  height: 2rem;
  cursor: pointer;
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  height: 4px;
  transform-origin: center center;
  transition: transform 200ms 200ms, opacity 0s 200ms;
}

.c-hamburger__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: #031d44;
  transition: transform 200ms;
}

.has-menu-opened .c-hamburger__bar {
  transition: transform 200ms, opacity 0s 200ms;
}
.has-menu-opened .c-hamburger__bar:first-child {
  transform: translateY(0.8rem);
}
.has-menu-opened .c-hamburger__bar:last-child {
  transform: translateY(-0.8rem);
}
.has-menu-opened .c-hamburger__bar:not(:first-child):not(:last-child) {
  opacity: 0;
}
.has-menu-opened .c-hamburger__inner {
  transition: transform 200ms 200ms;
}
.has-menu-opened :first-child > .c-hamburger__inner {
  transform: rotate(45deg);
}
.has-menu-opened :last-child > .c-hamburger__inner {
  transform: rotate(-45deg);
}

/* stylelint-enable value-list-comma-space-after, indentation */
/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: relative;
  z-index: 10;
  background: #fff;
}
@media (min-width: 1024px) {
  .c-header {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }
  .c-header .o-wrapper {
    max-width: 1250px;
  }
}

.c-header__toolbar {
  background: #eff1f3;
  box-shadow: 0 0.671894px 1.34379px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1023px) {
  .c-header__toolbar {
    display: none;
  }
}
@media (min-width: 1024px) {
  .c-header__toolbar {
    padding-left: 2px;
  }
}

.c-header__wrapper {
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1024px) {
  .c-header__wrapper {
    box-shadow: none;
  }
}
@media (max-width: 1023px) {
  .c-header__wrapper {
    max-width: none;
  }
}

.c-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 8.1rem;
  margin: auto;
  background: #fff;
  transition: height 250ms;
}
@media (max-width: 1023px) {
  .c-header__container {
    max-width: 764px;
  }
}
@media (min-width: 1024px) {
  .c-header__container {
    z-index: 2;
    height: 11.2rem;
    background: none;
    box-shadow: none;
  }
}

.c-header__logo {
  display: block;
  width: 14.7rem;
  height: 4.7rem;
}
@media (min-width: 1024px) {
  .c-header__logo {
    width: 18.7rem;
    height: auto;
  }
}

.c-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 37rem;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: all 250ms;
  overflow: hidden;
}
.c-hero--retail, .c-hero--grocery, .c-hero--wholesale-distribution, .c-hero--digitally-native, .c-hero--suppliers {
  min-height: 46rem;
}
@media (min-width: 768px) {
  .c-hero {
    height: 50rem;
  }
}
@media (min-width: 1024px) {
  .c-hero {
    height: 56rem;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1440px) {
  .c-hero {
    height: 70rem;
  }
}
.c-hero--portal {
  height: 37rem !important;
}
.c-hero--blog {
  min-height: 37rem;
  height: auto !important;
  background: radial-gradient(88.83% 88.83% at 46.53% -2.59%, rgba(255, 255, 255, 0.6) 0%, rgba(216, 219, 226, 0.6) 100%);
}

.c-hero--contact {
  background: radial-gradient(circle at center top, #139fc1, #08668d);
  background-position: right center;
}

/* Background *****************************************************************/
.c-hero__bg,
.c-hero__icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  pointer-events: none;
}

.c-hero__icon::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(19, 159, 193, 0.57), transparent);
}

.c-hero__bg--home {
  background-color: #07729c;
  background-position: right 85%;
}
@media (min-width: 768px) {
  .c-hero__bg--home {
    background-position: 70% 85%;
  }
}
@media (min-width: 1440px) {
  .c-hero__bg--home {
    background-position: right 85%;
  }
}

.c-hero__bg--home::after,
.c-hero__bg--404::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #045b7f;
  opacity: 0.85;
  transition: opacity 250ms;
}
@media (min-width: 768px) {
  .c-hero__bg--home::after,
.c-hero__bg--404::after {
    opacity: 0;
  }
}

.c-hero__bg--platform {
  background-color: #0f668b;
  background-position: left 85%;
  background-size: cover;
}
@media (min-width: 1024px) {
  .c-hero__bg--platform {
    background-position: right 85%;
    background-size: auto 100%;
  }
}

.c-hero__bg--solutions {
  background-color: #1d9ebf;
  background-position: 75% center;
  background-size: cover;
}
@media (min-width: 1440px) {
  .c-hero__bg--solutions {
    background-position: right center;
    background-size: auto 100%;
  }
}

.c-hero__bg--about {
  background-color: #126d94;
  background-position: 83% center;
  background-size: auto 100%;
}
@media (min-width: 1440px) {
  .c-hero__bg--about {
    background-position: right center;
  }
}

.c-hero__bg--contact {
  background-position: right top;
  background-size: auto 57vw;
  right: -14rem;
}
@media (min-width: 480px) {
  .c-hero__bg--contact {
    background-size: auto 75%;
  }
}
@media (min-width: 768px) {
  .c-hero__bg--contact {
    background-size: auto 60%;
    right: calc(100vw - 76.8rem - 14rem);
  }
}
@media (min-width: 908px) {
  .c-hero__bg--contact {
    right: 0;
  }
}
@media (min-width: 1024px) {
  .c-hero__bg--contact {
    background-size: auto;
  }
}

.c-hero__bg--blog {
  background-size: auto 100%;
  background-position: calc(100% + 250px) 0;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .c-hero__bg--blog {
    background-position: calc(100% + 125px);
  }
}
@media (min-width: 1024px) {
  .c-hero__bg--blog {
    background-position: right top 0;
  }
}
@media (min-width: 1440px) {
  .c-hero__bg--blog {
    background-position: right 17% top 0;
  }
}

.c-hero__bg--404 {
  background-position: 50% center;
}
@media (min-width: 768px) {
  .c-hero__bg--404 {
    background-position: 30% 85%;
  }
}
@media (min-width: 1440px) {
  .c-hero__bg--404 {
    background-position: 100px 85%;
  }
}
@media (min-width: 1600px) {
  .c-hero__bg--404 {
    background-position: right 85%;
  }
}
.c-hero__bg--404::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #096c93, #08678e 40%, #08668d);
}

.c-hero__bg--misc {
  background-position: right center;
}
@media (min-width: 1024px) {
  .c-hero__bg--misc {
    bottom: -13rem;
    background-position: 60rem center;
  }
}
@media (min-width: 1300px) {
  .c-hero__bg--misc {
    background-position: right center;
  }
}
.c-hero__bg--misc::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: radial-gradient(circle at 40% 0%, #2cb9dc, #045b7f 40%);
  transition: opacity 250ms;
}
@media (min-width: 1024px) {
  .c-hero__bg--misc::before {
    bottom: 13rem;
  }
}
.c-hero__bg--misc::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #045b7f;
  opacity: 0.85;
  transition: opacity 250ms;
}
@media (min-width: 1024px) {
  .c-hero__bg--misc::after {
    opacity: 0;
    visibility: hidden;
  }
}

.c-hero__bg--circuit,
.c-hero__icon {
  background-position: 50% 100%;
  background-size: cover;
}
@media (min-width: 1440px) {
  .c-hero__bg--circuit,
.c-hero__icon {
    background-position: center center;
    background-size: auto 100%;
  }
}

.c-hero__bg--resources {
  background-position: 30% top;
  background-size: cover;
}
@media (min-width: 768px) {
  .c-hero__bg--resources {
    background-position: center top;
  }
}

/* Content ********************************************************************/
.c-hero__content {
  transition: margin-top 250ms;
}

.c-hero__content--platform {
  margin-top: -5.5rem;
}
@media (min-width: 768px) {
  .c-hero__content--platform {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .c-hero__content--solutions {
    margin-top: -14.5rem;
  }
}

.c-hero__content--about {
  margin-top: -14rem;
}
@media (min-width: 480px) {
  .c-hero__content--about {
    margin-top: -3.5rem;
  }
}
@media (min-width: 768px) {
  .c-hero__content--about {
    margin-top: -6rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__content--about {
    margin-top: -9rem;
  }
}

.c-hero__content--contact {
  margin-top: -16rem;
}
@media (min-width: 768px) {
  .c-hero__content--contact {
    margin-top: 0;
  }
}

.c-hero__content--circuit {
  margin-top: -5rem;
}
@media (min-width: 768px) {
  .c-hero__content--circuit {
    margin-top: 0;
  }
}

/* Heading ********************************************************************/
.c-hero__heading {
  position: relative;
  z-index: 1;
  color: #fff;
  font: bold 3rem SharpSans, sans-serif;
  letter-spacing: 0.3px;
  transition: all 250ms;
}
@media (min-width: 768px) {
  .c-hero__heading {
    font-size: 4rem;
    letter-spacing: 0.38px;
  }
}
@media (min-width: 1440px) {
  .c-hero__heading {
    font-size: 5.2rem;
    letter-spacing: 0.5px;
  }
}

.c-hero__heading--home,
.c-hero__heading--404 {
  max-width: 35rem;
}
@media (min-width: 768px) {
  .c-hero__heading--home,
.c-hero__heading--404 {
    max-width: 45rem;
  }
}
@media (min-width: 1024px) {
  .c-hero__heading--home,
.c-hero__heading--404 {
    max-width: 50.5rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__heading--home,
.c-hero__heading--404 {
    max-width: 63.5rem;
  }
}

.c-hero__heading--platform {
  max-width: 27rem;
}
@media (min-width: 768px) {
  .c-hero__heading--platform {
    max-width: none;
  }
}

.c-hero__heading--solutions {
  max-width: 30rem;
}
@media (min-width: 768px) {
  .c-hero__heading--solutions {
    max-width: 50rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__heading--solutions {
    max-width: 70rem;
  }
}

.c-hero__heading--about {
  max-width: 15rem;
}
@media (min-width: 480px) {
  .c-hero__heading--about {
    max-width: none;
  }
}

.c-hero__heading--contact {
  max-width: 15rem;
}
@media (min-width: 768px) {
  .c-hero__heading--contact {
    max-width: none;
  }
}

/* Gloss **********************************************************************/
.c-hero__gloss {
  position: relative;
  z-index: 1;
  margin-top: 2.7rem;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: 0.15px;
  transition: all 250ms;
}
@media (min-width: 768px) {
  .c-hero__gloss {
    font-size: 1.8rem;
    line-height: 1.6;
    letter-spacing: 0.17px;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss {
    font-size: 2.8rem;
    line-height: 1.36;
    letter-spacing: 0.27px;
  }
}

.c-hero__gloss--home,
.c-hero__gloss--404 {
  max-width: 28rem;
}
@media (min-width: 1024px) {
  .c-hero__gloss--home,
.c-hero__gloss--404 {
    max-width: 40rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss--home,
.c-hero__gloss--404 {
    max-width: 59rem;
  }
}

.c-hero__gloss--platform {
  max-width: 25rem;
}
@media (min-width: 768px) {
  .c-hero__gloss--platform {
    max-width: 43.5rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss--platform {
    max-width: 67rem;
  }
}

.c-hero__gloss--contact {
  display: none;
}
@media (min-width: 768px) {
  .c-hero__gloss--contact {
    display: block;
    max-width: 43.5rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss--contact {
    max-width: 41.5rem;
    font-size: 2.2rem;
  }
}

.c-hero__gloss--blog {
  color: #091e3e;
  width: 60%;
}
@media (min-width: 768px) {
  .c-hero__gloss--blog {
    max-width: 43.5rem;
    width: auto;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss--blog {
    max-width: 77rem;
    font-size: 2.2rem;
    letter-spacing: 0.25px;
  }
}

@media (min-width: 768px) {
  .c-hero__gloss--solutions,
.c-hero__gloss--retail,
.c-hero__gloss--grocery,
.c-hero__gloss--wholesale-distribution,
.c-hero__gloss--digitally-native,
.c-hero__gloss--suppliers {
    max-width: 45rem;
  }
}
@media (min-width: 1440px) {
  .c-hero__gloss--solutions,
.c-hero__gloss--retail,
.c-hero__gloss--grocery,
.c-hero__gloss--wholesale-distribution,
.c-hero__gloss--digitally-native,
.c-hero__gloss--suppliers {
    font-size: 2.6rem;
    max-width: 60rem;
  }
}

.c-hero__logo {
  position: relative;
  z-index: 1;
  width: 162px;
  margin-bottom: 1.5rem;
}
.c-hero--blog .c-hero__logo {
  width: 75%;
  max-width: 327px;
}
@media (min-width: 1024px) {
  .c-hero__logo {
    margin-bottom: 3rem;
  }
}

.c-horizontal-tile {
  display: block;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #e6e6e6;
}

.c-horizontal-tile__picture {
  margin-bottom: 2rem;
  display: block;
}

.c-horizontal-tile__image {
  aspect-ratio: 290/100;
  width: 100%;
  object-fit: cover;
}

.c-horizontal-tile__title {
  margin-bottom: 3rem;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.27;
  color: #00638b;
}

.c-horizontal-tile__details {
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #08668d;
}

.c-horizontal-tile__details-title {
  font-weight: 700;
  text-transform: uppercase;
  color: #b3bfca;
  letter-spacing: 0.37px;
}

.c-horizontal-tile__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: #08668d;
  opacity: 0.4;
}

.c-horizontal-tile__details-data {
  font-weight: 500;
  opacity: 0.6;
  letter-spacing: 0.5px;
}

.c-icons-block {
  margin-top: 6rem;
}
@media (min-width: 1024px) {
  .c-icons-block {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 0;
  }
}

.c-icons-block__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 0;
  margin: 3.5rem 0 0;
  text-align: center;
  background: rgba(230, 235, 245, 0.5);
}
.c-icons-block__item:first-child {
  margin-top: 0;
}
@media (min-width: 1024px) {
  .c-icons-block__item:first-child {
    margin-top: 4.8rem;
  }
}
@media (min-width: 1024px) {
  .c-icons-block__item {
    margin-top: 4.8rem;
    width: calc(33.3333333333% - 3.2rem);
  }
}

.c-icons-block__image-wrapper {
  width: 9.3rem;
  height: 6.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable */
.c-icons-block__image--document {
  max-width: 4.6rem;
}
.c-icons-block__image--hand {
  max-width: 6.2rem;
}
.c-icons-block__image--scale {
  max-width: 3.5rem;
}
.c-icons-block__image--spiral {
  max-width: 7rem;
}
.c-icons-block__image--box {
  max-width: 6.6rem;
}
.c-icons-block__image--boxes {
  max-width: 6.2rem;
}
.c-icons-block__image--short {
  max-width: 5.6rem;
}
.c-icons-block__image--auto {
  max-width: 6.8rem;
}
.c-icons-block__image--abstract {
  max-width: 9.3rem;
}

/* stylelint-enable */
.c-icons-block__textual {
  margin-top: 2rem;
  width: 100%;
}

.c-icons-block__label {
  display: block;
  max-width: 80%;
  margin: auto;
  color: #031d44;
  font-weight: 700;
}

.c-icons-rich {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .c-icons-rich {
    margin: 5rem 0;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich {
    margin: 7.5rem 0;
  }
}

.c-icons-rich__item {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .c-icons-rich__item {
    width: 45%;
  }
  .c-icons-rich__item:nth-child(1) {
    order: 1;
    margin-right: 10%;
  }
  .c-icons-rich__item:nth-child(2) {
    order: 3;
    margin-right: 10%;
  }
  .c-icons-rich__item:nth-child(3) {
    order: 5;
    margin-right: 10%;
  }
  .c-icons-rich__item:nth-child(4) {
    order: 2;
  }
  .c-icons-rich__item:nth-child(5) {
    order: 4;
  }
  .c-icons-rich__item:nth-child(6) {
    order: 6;
  }
}

.c-icons-rich__image-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 5.8rem;
  height: 6.4rem;
  margin-right: 3rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image-wrapper {
    width: 7rem;
    height: 7rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image-wrapper {
    width: 8.8rem;
    height: 8.8rem;
  }
}

.c-icons-rich__image {
  max-width: 100%;
  max-height: 100%;
  margin-top: 0.5rem;
}

.c-icons-rich__image--hand {
  width: 4.7rem;
  height: 4.1rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--hand {
    width: 6.4rem;
    height: 5.7rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--hand {
    width: 8rem;
    height: 7.1rem;
  }
}

.c-icons-rich__image--scale {
  width: 3.2rem;
  height: 5.2rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--scale {
    width: 3.9rem;
    height: 6.4rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--scale {
    width: 4.9rem;
    height: 8rem;
  }
}

.c-icons-rich__image--boxes {
  width: 5.1rem;
  height: 3.6rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--boxes {
    width: 6.9rem;
    height: 4.8rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--boxes {
    width: 8.6rem;
    height: 6rem;
  }
}

.c-icons-rich__image--transparency {
  width: 4.2rem;
  height: 6.4rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--transparency {
    width: 4.6rem;
    height: 7rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--transparency {
    width: 5.8rem;
    height: 8.8rem;
  }
}

.c-icons-rich__image--handshake {
  width: 5.8rem;
  height: 3.9rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--handshake {
    width: 7rem;
    height: 4.7rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--handshake {
    width: 8.8rem;
    height: 5.9rem;
  }
}

.c-icons-rich__image--pyramid {
  width: 5.6rem;
  height: 5.6rem;
}
@media (min-width: 768px) {
  .c-icons-rich__image--pyramid {
    width: 6.8rem;
    height: 6.8rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__image--pyramid {
    width: 8.5rem;
    height: 8.5rem;
  }
}

.c-icons-rich__label {
  display: block;
  color: #045b7f;
  font-size: 1.5rem;
  font-weight: 900;
}
@media (min-width: 1024px) {
  .c-icons-rich__label {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__label {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.c-icons-rich__paragraph {
  font-size: 1.5rem;
  margin: 0;
}
@media (min-width: 1024px) {
  .c-icons-rich__paragraph {
    font-size: 1.8rem;
  }
}
@media (min-width: 1440px) {
  .c-icons-rich__paragraph {
    font-size: 2rem;
  }
}

.c-jobs {
  margin-bottom: 20rem;
  margin-left: -30px;
  margin-right: -30px;
}
@media (min-width: 768px) {
  .c-jobs {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-job-top {
  box-shadow: 0 2px 4px 0 #b8bfca;
  font-family: Helvetica, sans-serif;
  font-size: 2.6rem;
  color: #386180;
  letter-spacing: 0.39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 2.8rem;
  transition: background 250ms;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.c-job-top:hover {
  background: #f1fafd;
}
.c-job-top.is-opened {
  background: #c5f1fd;
}
@media (min-width: 480px) {
  .c-job-top {
    padding: 4.2rem 4rem;
  }
}
@media (min-width: 1024px) {
  .c-job-top {
    padding: 5.3rem 10.8rem 5.3rem 7.6rem;
  }
}

.c-job-top__title {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  line-height: 2.1rem;
  letter-spacing: 0.4px;
  font-size: 1.56rem;
  padding-right: 10px;
  position: relative;
  top: 2px;
}
@media (min-width: 480px) {
  .c-job-top__title {
    font-size: 20px;
    line-height: 34px;
    position: static;
  }
}
@media (min-width: 1024px) {
  .c-job-top__title {
    font-size: 26px;
  }
}

.c-job-top__arrow {
  transition: transform 250ms;
  flex: 0 0 2.4rem;
}
.is-opened .c-job-top__arrow {
  transform: rotate(180deg);
}
@media (min-width: 1024px) {
  .c-job-top__arrow {
    max-width: none;
    flex: 0 0 auto;
  }
}

.c-job-bottom {
  transition: height 0.7s, opacity 0.7s, margin-bottom 0.7s;
  overflow: hidden;
  opacity: 0;
  margin-bottom: 1rem;
  background: transparent;
}
.c-job-bottom.is-opened {
  opacity: 1;
  margin-bottom: 0;
}
@media (min-width: 480px) {
  .c-job-bottom {
    margin-bottom: 2.5rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 1024px) {
  .c-job-bottom {
    margin-left: 33px;
    margin-right: 33px;
  }
}

.c-job:last-child .c-job-bottom {
  margin-bottom: 0;
}

.c-job-bottom__inner {
  background: #f8fdfe;
  border: 1px solid #d7eaee;
  padding: 3.2rem 2.8rem 5rem;
}
@media (min-width: 768px) {
  .c-job-bottom__inner {
    padding: 10.9rem 5.7rem 10rem;
  }
}

.c-job-bottom__block-label {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.6rem;
  letter-spacing: 0.3px;
  color: #386180;
  display: block;
  margin-bottom: 2.8rem;
}
@media (min-width: 1024px) {
  .c-job-bottom__block-label {
    font-size: 2rem;
  }
}

.c-job-bottom__block-content {
  margin-bottom: 5rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  color: #386180;
  letter-spacing: 0.3px;
  line-height: 2.2rem;
}
@media (min-width: 1024px) {
  .c-job-bottom__block-content {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 8.6rem;
    padding-left: 4.3rem;
  }
}

.c-job-bottom__button-wrapper {
  text-align: center;
}

.c-job-bottom__button {
  margin: 0;
}

.c-legal {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #386180;
}
@media (min-width: 1024px) {
  .c-legal {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.c-legal h3 {
  font-size: 1.6rem;
  font-weight: black;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .c-legal h3 {
    font-size: 2rem;
  }
}
.c-legal p {
  font-size: 1.6rem;
}
@media (min-width: 1024px) {
  .c-legal p {
    font-size: 2rem;
  }
}
.c-legal dl {
  margin-top: 2rem;
  font-size: 1.6rem;
}
@media (min-width: 1024px) {
  .c-legal dl {
    font-size: 2rem;
  }
}
.c-legal dt {
  display: inline;
  color: inherit;
  float: left;
  margin-right: 0.5rem;
}
.c-legal dt:not(.no-quotes)::before {
  content: "“";
}
.c-legal dt:not(.no-quotes)::after {
  content: "”";
}
.c-legal .no-quotes span::before {
  content: "“";
}
.c-legal .no-quotes span::after {
  content: "”";
}
.c-legal dd {
  display: block;
  margin: 0 0 3rem 0;
}
.c-legal ol {
  counter-reset: list;
  list-style-type: none;
  list-style-position: inside;
}
.c-legal li {
  display: flex;
  list-style-type: inherit;
  font-size: 1.6rem;
  margin-left: 3rem;
}
@media (min-width: 1024px) {
  .c-legal li {
    font-size: 2rem;
  }
}
.c-legal li::before {
  content: "(" counter(list, lower-roman) ")";
  counter-increment: list;
  display: block;
  width: 5rem;
  flex-shrink: 0;
}
.c-legal a {
  border-bottom: 2px solid #386180;
}

.c-links {
  margin: 3rem 0;
}
@media (min-width: 1024px) {
  .c-links {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
  }
}
@media (min-width: 1440px) {
  .c-links {
    max-width: 86rem;
  }
}

.c-links__item {
  display: block;
  margin: 1.4rem 0;
  color: #045b7f;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

.c-links__item-span:hover {
  border-bottom: 2px solid #045b7f;
}

.c-links br {
  display: none;
}
@media (min-width: 1024px) {
  .c-links br {
    display: block;
  }
}

.c-logo {
  max-width: 24rem;
  margin-bottom: 4rem;
}

.c-main {
  min-height: 30rem;
}

.c-menu {
  display: none;
}
@media (min-width: 1024px) {
  .c-menu {
    display: flex;
    width: auto;
    left: auto;
    top: 0;
    right: 60px;
    bottom: 0;
    padding: 0;
    background: none;
    transform: none;
    transition: none;
    align-items: center;
  }
}

.c-menu--mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  width: 100%;
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
  margin: 0;
  background-color: #f1fafd;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0);
  transform: translateY(-100%);
  transition: transform 250ms;
}
.has-menu-opened .c-menu--mobile {
  transform: translateY(0);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1024px) {
  .has-menu-opened .c-menu--mobile {
    box-shadow: none;
  }
}
@media (min-width: 1024px) {
  .c-menu--mobile {
    display: none;
  }
}

.c-menu__item {
  display: block;
  margin: 0;
}
@media (min-width: 1024px) {
  .c-menu__item {
    margin-left: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .c-menu__item--button {
    margin-left: 2.9rem;
  }
}
@media (min-width: 1024px) and (max-width: 1149.98px) {
  .c-menu__item--button {
    margin-left: 1.4rem;
  }
}

.c-menu__link {
  display: block;
  padding: 1.4rem;
  color: #045b7f;
  font-family: SharpSans, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .c-menu__link {
    letter-spacing: 0.75px;
  }
}
@media (min-width: 1024px) and (max-width: 1149.98px) {
  .c-menu__link {
    font-size: 1.5rem;
    padding-inline: 0.8rem;
  }
}

.c-menu__link--icon {
  display: flex;
  align-items: center;
}

.c-menu__icon {
  margin-left: 12px;
}

.c-menu__link--button {
  font-weight: 700;
  padding: 1.1rem 2.1rem;
  border-radius: 10px;
  margin-top: 1rem;
  color: #fff;
  line-height: 1.45;
  background: #2cb9dc;
  min-width: 14.4rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .c-menu__link--button {
    margin-top: 0;
    padding: 1.1rem 1.8rem;
  }
}
@media (min-width: 1024px) and (max-width: 1149.98px) {
  .c-menu__link--button {
    min-width: 0;
  }
}

.c-menu__link--button-darker {
  background: #00638b;
}

.c-news {
  color: #fff;
  font-size: 1.4rem;
  background-color: #031d44;
}
@media (min-width: 1024px) {
  .c-news {
    color: #a6bac7;
  }
}

.c-news__inner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.c-news__content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .c-news__content-wrapper {
    padding-right: 1.5rem;
  }
}
@media (min-width: 1340px) {
  .c-news__content-wrapper {
    max-width: none;
    padding-left: calc((100% - 1020px) / 2);
  }
}
@media (min-width: 1440px) {
  .c-news__content-wrapper {
    padding-right: 0;
    padding-left: 12rem;
    max-width: 1320px;
  }
}

@media (min-width: 1024px) {
  .c-news__content {
    padding-right: 1rem;
  }
}
.c-news__content a {
  color: inherit;
  text-decoration: underline;
}

.c-news__close-wrapper {
  display: none;
}
@media (min-width: 1024px) {
  .c-news__close-wrapper {
    display: block;
  }
}

.c-news__close-button {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  display: block;
  color: inherit;
  cursor: pointer;
}

.c-news__close-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  fill: currentColor;
}

.c-newsletter-footer {
  background: #08668d;
  color: #fff;
  padding: 2rem 0;
}
@media (min-width: 1024px) {
  .c-newsletter-footer {
    padding: 5rem 0 3rem;
  }
}
.c-newsletter-footer .o-newsletter-form__heading {
  color: inherit;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .c-newsletter-footer .o-newsletter-form__heading {
    letter-spacing: 0.26px;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .o-newsletter-form__heading {
    font-size: 3rem;
  }
}
.c-newsletter-footer .o-newsletter-form__intro {
  color: inherit;
  margin: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.2px;
}
@media (min-width: 1024px) {
  .c-newsletter-footer .o-newsletter-form__intro {
    max-width: calc(100% - 22rem);
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .o-newsletter-form__intro {
    max-width: calc(100% - 24rem);
    font-size: 1.8rem;
  }
}
.c-newsletter-footer .o-newsletter-form__inner {
  margin-bottom: 2rem;
}
.c-newsletter-footer .o-newsletter-form__fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem 2rem;
}
@media (min-width: 768px) {
  .c-newsletter-footer .o-newsletter-form__fields {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-footer .o-newsletter-form__fields {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .o-newsletter-form__fields {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
.c-newsletter-footer .o-newsletter-form__field-group {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 1rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  /* stylelint-disable selector-class-pattern, selector-no-qualifying-type */
  /* stylelint-enable selector-class-pattern, selector-no-qualifying-type */
}
@media (min-width: 768px) {
  .c-newsletter-footer .o-newsletter-form__field-group {
    width: 25%;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-footer .o-newsletter-form__field-group {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .o-newsletter-form__field-group {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.c-newsletter-footer .o-newsletter-form__field-group label {
  display: block;
  margin-bottom: 1rem;
  font-weight: 700;
}
.c-newsletter-footer .o-newsletter-form__field-group input {
  display: block;
  border-radius: 0;
  border: 0;
  font: inherit;
  color: inherit;
  background: none;
  width: 100%;
  border-bottom: 3px solid currentColor;
}
.c-newsletter-footer .o-newsletter-form__field-group div.mce_inline_error {
  margin-top: 0.5rem;
  font-size: 1.4rem;
}
.c-newsletter-footer .o-newsletter-form__spam-note {
  display: none;
}
.c-newsletter-footer .o-newsletter-form__button-wrapper {
  flex: 0 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .c-newsletter-footer .o-newsletter-form__button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 0;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-footer .o-newsletter-form__button-wrapper {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .o-newsletter-form__button-wrapper {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
@media (min-width: 768px) {
  .c-newsletter-footer .c-btn--footer-sign-up {
    min-width: auto;
    width: calc(25% - 2rem);
    margin-right: 1rem;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-footer .c-btn--footer-sign-up {
    width: calc(25% - 4rem);
    margin-right: 2rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-footer .c-btn--footer-sign-up {
    width: calc(25% - 6rem);
    margin-right: 3rem;
  }
}
.c-newsletter-footer .o-newsletter-form__responses {
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .c-newsletter-footer .o-newsletter-form__responses {
    text-align: right;
  }
}
.c-newsletter-footer .o-newsletter-form__footer {
  color: #2cb9dc;
  font-size: 1.3rem;
  font-style: italic;
  letter-spacing: 0.24px;
}
.c-newsletter-footer .o-newsletter-form__footer a {
  color: inherit;
}

.c-newsletter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 30, 62, 0.4);
  z-index: 100;
  align-items: center;
  justify-content: center;
  color: #08668d;
  display: none;
}
.c-newsletter-modal.is-open {
  display: flex;
}

.c-newsletter-modal__main {
  flex: 0 0 auto;
  position: relative;
  width: calc(100% - 6rem);
  max-width: 97rem;
  max-height: 90%;
  display: flex;
}
.c-newsletter-modal__main::before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 1.7rem;
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  background-color: rgba(4, 91, 127, 0.6);
}

.c-newsletter-modal__close {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  display: block;
  color: inherit;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 768px) {
  .c-newsletter-modal__close {
    top: 2rem;
    right: 2rem;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-modal__close {
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
  }
}

.c-newsletter-modal__close-icon {
  width: 1em;
  height: 1em;
  display: block;
  fill: currentColor;
}

.c-newsletter-modal__main-inner {
  width: 100%;
  position: relative;
  background: radial-gradient(circle at center -10%, #e6efe2, #c0f6fb) #fff;
  border-radius: 1.5rem;
  overflow: hidden;
  display: flex;
}

.c-newsletter-modal__main-inner-inner {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  padding: 2rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}
@media (min-width: 768px) {
  .c-newsletter-modal__main-inner-inner {
    padding: 4rem 5rem 2rem 5rem;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-modal__main-inner-inner {
    padding: 7rem 5rem 3.5rem 5rem;
  }
}

.c-newsletter-modal__form .o-newsletter-form__heading {
  color: inherit;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .c-newsletter-modal__form .o-newsletter-form__heading {
    letter-spacing: 0.29px;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-modal__form .o-newsletter-form__heading {
    font-size: 3.4rem;
  }
}
.c-newsletter-modal__form .o-newsletter-form__intro {
  color: inherit;
  margin: 0;
  margin-bottom: 3rem;
  letter-spacing: 0.2px;
}
@media (min-width: 1024px) {
  .c-newsletter-modal__form .o-newsletter-form__intro {
    max-width: 67rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-modal__form .o-newsletter-form__intro {
    font-size: 2rem;
    margin-bottom: 5rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-modal__form .o-newsletter-form__heading,
.c-newsletter-modal__form .o-newsletter-form__intro {
    margin-left: 5rem;
  }
}
.c-newsletter-modal__form .o-newsletter-form__inner {
  margin-bottom: 2rem;
}
.c-newsletter-modal__form .o-newsletter-form__fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  max-width: 73rem;
}
@media (min-width: 1024px) {
  .c-newsletter-modal__form .o-newsletter-form__fields {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-modal__form .o-newsletter-form__fields {
    margin-left: 3rem;
  }
}
.c-newsletter-modal__form .o-newsletter-form__field-group {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  /* stylelint-disable selector-class-pattern, selector-no-qualifying-type */
  /* stylelint-enable selector-class-pattern, selector-no-qualifying-type */
}
@media (min-width: 768px) {
  .c-newsletter-modal__form .o-newsletter-form__field-group {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .c-newsletter-modal__form .o-newsletter-form__field-group {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 2.2rem;
    margin-bottom: 4.5rem;
  }
}
.c-newsletter-modal__form .o-newsletter-form__field-group label {
  display: block;
  margin-bottom: 1rem;
  font-weight: 700;
}
.c-newsletter-modal__form .o-newsletter-form__field-group .asterisk {
  color: #d0021b;
}
.c-newsletter-modal__form .o-newsletter-form__field-group input {
  display: block;
  border-radius: 0;
  border: 0;
  font: inherit;
  color: inherit;
  background: none;
  width: 100%;
  border-bottom: 2px solid #2cb9dc;
}
.c-newsletter-modal__form .o-newsletter-form__field-group div.mce_inline_error {
  margin-top: 0.5rem;
  font-size: 1.4rem;
}
.c-newsletter-modal__form .o-newsletter-form__spam-note {
  max-width: 59rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .c-newsletter-modal__form .o-newsletter-form__spam-note {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
}
.c-newsletter-modal__form .o-newsletter-form__button-wrapper {
  text-align: center;
}
.c-newsletter-modal__form .o-newsletter-form__responses {
  text-align: center;
}
.c-newsletter-modal__form .o-newsletter-form__responses a {
  color: inherit;
}
.c-newsletter-modal__form .o-newsletter-form__footer {
  font-size: 1.3rem;
  font-style: italic;
  letter-spacing: 0.24px;
  text-align: center;
  margin-top: 2rem;
}
.c-newsletter-modal__form .o-newsletter-form__footer a {
  color: inherit;
}
@media (min-width: 768px) {
  .c-newsletter-modal__form .o-newsletter-form__footer {
    margin-top: 3rem;
  }
}
@media (min-width: 1440px) {
  .c-newsletter-modal__form .o-newsletter-form__footer {
    margin-top: 5rem;
  }
}
@media (min-width: 768px) {
  .c-newsletter-modal__form .o-newsletter-form__footer {
    font-size: 1.5rem;
    letter-spacing: 0.28px;
  }
}

.c-notification {
  text-align: center;
}

.c-notification__heading {
  font-family: SharpSans, sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin: 0;
  letter-spacing: 0.5px;
  color: #091e3e;
}
@media (min-width: 768px) {
  .c-notification__heading {
    font-size: 4rem;
  }
}

.c-notification__subheading {
  margin-block: 8px 0;
  font-family: SharpSans, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.3px;
  color: #091e3e;
}
@media (min-width: 768px) {
  .c-notification__subheading {
    font-size: 2.8rem;
  }
}

.c-btn--notification {
  margin-top: 24px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .c-btn--notification {
    margin-top: 48px;
  }
}

.c-partners {
  padding: 30px 0;
  background-color: #e6ebf5;
}
@media (min-width: 768px) {
  .c-partners {
    margin-top: 120px;
    padding: 75px 0;
  }
}

.c-partners__title {
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .c-partners__title {
    margin-bottom: 35px;
  }
}

.c-partners__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
}
@media (min-width: 768px) {
  .c-partners__list {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.c-partners__item {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: calc(50% - 30px);
  flex: 2 1 auto;
}
@media (min-width: 768px) {
  .c-partners__item {
    margin: 0 15px;
    height: 52px;
  }
}
@media (min-width: 1024px) {
  .c-partners__item {
    margin: 0 39.999px;
  }
}
.c-partners__item--portrait {
  max-width: calc(33.33% - 30px);
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .c-partners__item--portrait {
    height: 104px;
  }
}
@media (min-width: 768px) {
  .c-partners__item:first-child {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .c-partners__item:last-child {
    margin-right: 0;
  }
}

.c-partners__link {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.c-partners__image {
  margin: auto;
}
@media (min-width: 768px) {
  .c-partners__image {
    max-height: 100%;
    width: auto;
  }
}

.c-post {
  padding-bottom: 5rem;
}
@media (min-width: 1024px) {
  .c-post {
    padding-bottom: 10rem;
  }
}

.c-post__toolbar {
  position: sticky;
  top: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  padding: 25px 30px;
  gap: 40px;
  background: #fff;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.04);
}
.c-post__toolbar .c-social-list {
  margin-left: auto;
  font-size: 1.6rem;
}
@media (min-width: 1024px) {
  .c-post__toolbar {
    padding-inline: 36px;
  }
}

.c-post__toolbar-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  background: rgba(9, 30, 62, 0.04);
}

.c-post__toolbar-progress-bar {
  --scroll-amount: 0%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--scroll-amount);
  background: #2cb9dc;
}

.c-post__toolbar-back {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.705849px;
  color: #091e3e;
  text-transform: uppercase;
  text-decoration: none;
}

.c-post__toolbar-back-arrow {
  margin-right: 12px;
}

.c-post__toolbar-title {
  font-size: 2rem;
  font-weight: 700;
  color: #00638b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  display: none;
}
@media (min-width: 1024px) {
  .c-post__toolbar-title {
    display: block;
  }
}

.c-post__picture {
  display: block;
  text-align: center;
}

.c-post__image {
  aspect-ratio: 1070/435;
  width: 100%;
  object-fit: contain;
}

.c-post__header {
  margin-block: 4.8rem 3.6rem;
}
@media (min-width: 1024px) {
  .c-post__header {
    margin-block: 10.4rem 5.1rem;
  }
}

@media (min-width: 768px) {
  .c-post__title-content {
    display: flex;
    justify-content: space-between;
  }
}

.c-post__title {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  letter-spacing: 0.501466px;
  line-height: 1.37;
  color: #00638b;
}
@media (min-width: 768px) {
  .c-post__title {
    flex: 1;
    margin: 0;
    padding-right: 5.5rem;
    letter-spacing: 0.5px;
    font-size: 2.8rem;
  }
}
@media (min-width: 1440px) {
  .c-post__title {
    font-size: 3.8rem;
  }
}

.c-post__details {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: #08668d;
}
@media (min-width: 1024px) {
  .c-post__details {
    margin-top: 4rem;
    font-size: 1.8rem;
  }
}

.c-post__details-title {
  float: left;
  font-weight: 700;
  opacity: 0.4;
  letter-spacing: 0.37px;
  text-transform: uppercase;
}

.c-post__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: #386180;
}

.c-post__details-data {
  font-weight: 500;
  opacity: 0.4;
  letter-spacing: 0.7px;
}

@media (min-width: 1440px) {
  .c-post__container {
    display: grid;
    gap: 5rem;
    grid-template-columns: auto 290px;
  }
}

/* stylelint-disable */
.c-post__content {
  max-width: 746px;
  margin-bottom: 2rem;
  word-break: break-word;
}
.c-post__content > *:first-child {
  margin-top: 0 !important;
}
.c-post__content *:last-child {
  margin-bottom: 0 !important;
}
.c-post__content .image,
.c-post__content .bg-box {
  margin-block: 4.2rem;
}
.c-post__content h1,
.c-post__content h2,
.c-post__content h3,
.c-post__content h4,
.c-post__content h5,
.c-post__content h6 {
  margin-block: 4.2rem 2rem;
  color: #045b7f;
  font-weight: 700;
}
.c-post__content h1 b,
.c-post__content h1 strong,
.c-post__content h2 b,
.c-post__content h2 strong,
.c-post__content h3 b,
.c-post__content h3 strong,
.c-post__content h4 b,
.c-post__content h4 strong,
.c-post__content h5 b,
.c-post__content h5 strong,
.c-post__content h6 b,
.c-post__content h6 strong {
  font-weight: 700;
}
.c-post__content h1:first-child,
.c-post__content h2:first-child,
.c-post__content h3:first-child,
.c-post__content h4:first-child,
.c-post__content h5:first-child,
.c-post__content h6:first-child {
  margin-top: 0;
}
.c-post__content h1,
.c-post__content h2 {
  font-family: SharpSans, sans-serif;
}
.c-post__content h1 {
  font-size: 3.2rem;
}
.c-post__content h2 {
  font-size: 2.2rem;
}
.c-post__content h3 {
  font-size: 2rem;
}
.c-post__content h4 {
  font-size: 1.8rem;
}
.c-post__content h5 {
  font-size: 1.6rem;
}
.c-post__content h6 {
  font-size: 1.4rem;
}
.c-post__content a {
  color: inherit;
  text-decoration: underline;
  transition: color 250ms;
}
.c-post__content a:hover {
  color: #031d44;
}
.c-post__content p + h3 {
  margin-top: 4rem;
}
.c-post__content,
.c-post__content p {
  font-size: 2rem;
  line-height: 1.4;
  color: #386180;
}
.c-post__content p:first-child,
.c-post__content ul:first-child,
.c-post__content ol:first-child,
.c-post__content blockquote:first-child {
  margin-top: 0;
}
.c-post__content p:last-child,
.c-post__content ul:last-child,
.c-post__content ol:last-child,
.c-post__content blockquote:last-child {
  margin-bottom: 0;
}
.c-post__content blockquote {
  quotes: none;
}
.c-post__content blockquote p {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.45;
  font-style: normal;
  color: #08668d;
}
.c-post__content blockquote::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 3px;
  margin-right: 1.5rem;
  vertical-align: middle;
  background: #2cb9dc;
}
.c-post__content ul,
.c-post__content ol {
  margin: 2rem 0;
}
.c-post__content ul ul,
.c-post__content ul ol,
.c-post__content ol ul,
.c-post__content ol ol {
  margin-left: 1rem;
  margin-block: 1rem 2rem !important;
}
.c-post__content ul li,
.c-post__content ol li {
  position: relative;
}
.c-post__content ul {
  margin-left: 0;
  list-style-position: inside;
  list-style-type: none;
}
.c-post__content ul > li {
  padding-left: 1.5rem;
}
.c-post__content ul > li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
}
.c-post__content ol {
  counter-reset: list;
  list-style-type: none;
  list-style-position: inside;
}
.c-post__content ol > li {
  padding-left: 2rem;
}
.c-post__content ol > li::before {
  content: counter(list) ".";
  counter-increment: list;
  position: absolute;
  left: 0;
}
.c-post__content ol ol > li::before {
  content: counter(list, lower-alpha) ".";
}
.c-post__content .image[data-align=left] {
  text-align: left;
}
.c-post__content .image[data-align=center] {
  text-align: center;
}
.c-post__content .image[data-align=right] {
  text-align: right;
}
.c-post__content .bg-box {
  padding: 24px 16px;
}
@media (min-width: 768px) {
  .c-post__content blockquote {
    position: relative;
    font-size: 1.8rem;
    padding-left: 6.3rem;
  }
  .c-post__content blockquote::before {
    position: absolute;
    top: 15px;
    left: 0;
  }
  .c-post__content ul,
.c-post__content ol {
    margin-left: 6.3rem;
  }
  .c-post__content ul ul,
.c-post__content ul ol,
.c-post__content ol ul,
.c-post__content ol ol {
    margin-left: 1rem;
  }
}
@media (min-width: 1024px) {
  .c-post__content {
    margin-bottom: 4rem;
  }
  .c-post__content .bg-box {
    padding: 48px 40px;
  }
}

/* stylelint-enable */
.c-post__sidebar {
  display: none;
}
@media (min-width: 1440px) {
  .c-post__sidebar {
    display: block;
  }
}

.c-post__sidebar-title {
  margin-bottom: 2.4rem;
  font-size: 2.2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
}

/* stylelint-disable */
.c-post__content .custom-text * {
  font-size: inherit;
}

.c-post__content ul.checkmark-list {
  display: block;
  margin-left: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list {
    margin-left: 7.3rem;
  }
}
.c-post__content ul.checkmark-list li {
  color: inherit;
  margin: 1rem 0;
  padding-left: 2rem;
  line-height: 1.4;
  display: list-item;
  list-style-type: none;
}
.c-post__content ul.checkmark-list li::before {
  content: "";
  position: absolute;
  display: block;
  top: 12px;
  left: 0;
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
}
.c-post__content ul.checkmark-list[data-bullet-type=decimal] {
  margin-left: 1.8rem;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list[data-bullet-type=decimal] {
    margin-left: 9.3rem;
  }
}
.c-post__content ul.checkmark-list[data-bullet-type=decimal] > li {
  list-style-type: decimal;
  padding-left: 0.8rem;
}
.c-post__content ul.checkmark-list[data-bullet-type=decimal] > li::before {
  display: none;
}
.c-post__content ul.checkmark-list[data-bullet-type=alpha] {
  margin-left: 1.8rem;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list[data-bullet-type=alpha] {
    margin-left: 9.3rem;
  }
}
.c-post__content ul.checkmark-list[data-bullet-type=alpha] > li {
  list-style-type: lower-alpha;
  padding-left: 0.8rem;
}
.c-post__content ul.checkmark-list[data-bullet-type=alpha] > li::before {
  display: none;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list[data-bullet-type=traverse-bullet] {
    margin-left: 7rem;
  }
}
.c-post__content ul.checkmark-list[data-bullet-type=traverse-bullet] > li {
  margin: 1.6rem 0;
  list-style-type: none;
  line-height: 1.4;
  padding-left: 3.5rem;
}
.c-post__content ul.checkmark-list[data-bullet-type=traverse-bullet] > li::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 2rem;
  height: 2rem;
  background: transparent url("../assets/images/traverse-bullet.png") center center/contain no-repeat;
  border-radius: 0;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list[data-bullet-type=rivet-bullet] {
    margin-left: 7rem;
  }
}
.c-post__content ul.checkmark-list[data-bullet-type=rivet-bullet] > li {
  margin: 1.6rem 0;
  list-style-type: none;
  line-height: 1.4;
  padding-left: 3.5rem;
}
.c-post__content ul.checkmark-list[data-bullet-type=rivet-bullet] > li::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 2rem;
  height: 2rem;
  background: transparent url("../assets/images/rivet-bullet.png") center center/contain no-repeat;
  border-radius: 0;
}
@media (min-width: 768px) {
  .c-post__content ul.checkmark-list[data-bullet-type=vision-bullet] {
    margin-left: 7rem;
  }
}
.c-post__content ul.checkmark-list[data-bullet-type=vision-bullet] > li {
  margin: 1.6rem 0;
  list-style-type: none;
  line-height: 1.4;
  padding-left: 3.5rem;
}
.c-post__content ul.checkmark-list[data-bullet-type=vision-bullet] > li::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 2rem;
  height: 2rem;
  background: transparent url("../assets/images/vision-bullet.png") center center/contain no-repeat;
  border-radius: 0;
}

/* stylelint-enable */
.c-product__logo-mobile {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .c-product__logo-mobile {
    display: none;
  }
}

.c-product__logo-desktop {
  width: 100%;
}

.c-product__logo--mobile {
  width: 15rem;
}

.c-product__heading {
  margin-bottom: 0;
}
@media (min-width: 1440px) {
  .c-product__heading {
    margin-bottom: 3rem;
  }
}

.c-product__row {
  display: flex;
  justify-content: space-between;
}

.c-product__col {
  flex: 0 0 auto;
}
.c-product__col--info {
  width: 100%;
}
@media (min-width: 768px) {
  .c-product__col--info {
    width: 65%;
  }
}
.c-product__col--image {
  display: none;
}
@media (min-width: 768px) {
  .c-product__col--image {
    display: flex;
    align-items: center;
    width: 25%;
  }
}

.c-product__link {
  color: #045b7f;
  text-decoration: none;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.c-product__link-row {
  display: flex;
  align-items: center;
}

.c-product__link-v {
  display: block;
  width: 2rem;
  height: 2.4rem;
  margin-right: 1rem;
}

.c-related-posts {
  padding-bottom: 6.7rem;
}
.c-related-posts::before {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  max-width: 746px;
  margin-bottom: 3.6rem;
  background-color: #c5f1fd;
}
@media (min-width: 1024px) {
  .c-related-posts {
    padding-bottom: 13.4rem;
  }
  .c-related-posts::before {
    margin-bottom: 7.2rem;
  }
}

.c-related-posts__title {
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .c-related-posts__title {
    margin-bottom: 3.2rem;
  }
}

.c-related-posts__container {
  max-width: 1022px;
  display: grid;
  row-gap: 32px;
}
@media (min-width: 768px) {
  .c-related-posts__container {
    grid-template-columns: 1fr 1fr;
    column-gap: 2.4rem;
  }
}
@media (min-width: 1024px) {
  .c-related-posts__container {
    row-gap: 2.4rem;
  }
}
@media (min-width: 1440px) {
  .c-related-posts__container {
    column-gap: 1rem;
  }
}

.c-related-posts__item {
  display: block;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #e6e6e6;
}
@media (min-width: 768px) {
  .c-related-posts__item:last-child, .c-related-posts__item:nth-last-child(2) {
    border: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 1440px) {
  .c-related-posts__item {
    display: grid;
    grid-template-columns: 170px auto;
    grid-template-rows: auto 1fr;
    column-gap: 2rem;
  }
}

.c-related-posts__item-picture {
  margin-bottom: 1.6rem;
  display: block;
}
@media (min-width: 1440px) {
  .c-related-posts__item-picture {
    margin-bottom: 0;
    grid-row: 1/3;
  }
}

.c-related-posts__item-image {
  aspect-ratio: 290/100;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 1440px) {
  .c-related-posts__item-image {
    aspect-ratio: 170/100;
  }
}

.c-related-posts__item-details-title {
  margin-bottom: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #b3bfca;
  letter-spacing: 0.37px;
}
@media (min-width: 1440px) {
  .c-related-posts__item-details-title {
    grid-column-start: 2;
  }
}

.c-related-posts__item-title {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.27;
  color: #08668d;
}
@media (min-width: 1440px) {
  .c-related-posts__item-title {
    grid-column-start: 2;
  }
}

.c-results {
  padding-top: 3.7rem;
  padding-bottom: 1rem;
}
@media (min-width: 1024px) {
  .c-results {
    padding-top: 8rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1440px) {
  .c-results {
    padding-top: 7rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .c-results__heading {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-results__paragraph {
    font-size: 2.4rem;
    max-width: none;
  }
}

.c-results__item {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 2rem 0 0;
  letter-spacing: 1.15px;
}
@media (min-width: 1024px) {
  .c-results__item {
    font-size: 2rem;
    letter-spacing: 0.34px;
  }
}
@media (min-width: 1440px) {
  .c-results__item {
    font-size: 2.4rem;
    letter-spacing: 0.4px;
  }
}
.c-results__item strong {
  color: #42d7fd;
}

.c-results__props {
  margin-top: 4rem;
}

.c-social-list {
  position: relative;
  font-size: 1.4rem;
  display: inline-block;
}

.c-social-list__button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-weight: 500;
  color: #08668d;
  letter-spacing: 0.705849px;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
  transition: 250ms all;
}
.c-social-list__button:hover, .c-social-list__button.is-opened {
  color: #2cb9dc;
}

.c-social-list__list {
  position: absolute;
  top: 120%;
  right: -2rem;
  align-items: center;
  gap: 12px;
  display: none;
  background: #fff;
  padding: 2.5rem 2rem 2rem;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.04);
  grid-auto-flow: row;
}
.is-opened + .c-social-list__list {
  display: grid;
}
.c-social-list__list--horizontal {
  box-shadow: none;
  background: none;
  right: auto;
  left: 100%;
  top: 2px;
  padding: 0 1.5rem;
  grid-auto-flow: column;
}

.c-social-list__item {
  margin: 0;
}

.c-social-list__link {
  display: block;
}

.c-social-list__img {
  max-width: none;
}

.c-solution-blocks__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 5rem 0;
  margin: 0 0 2.2rem 0;
  background: #f0f0f0;
  transition: all 250ms;
}
@media (min-width: 1024px) {
  .c-solution-blocks__item {
    align-items: flex-start;
    padding: 7rem 8.5rem 4rem;
    margin-bottom: 3.6rem;
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__item {
    padding: 7rem 0 5rem;
    margin-bottom: 4rem;
  }
}
.c-solution-blocks__item:last-child {
  margin-bottom: 0;
}
.c-solution-blocks__item::before, .c-solution-blocks__item::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  background: inherit;
  transition: all 250ms;
}
@media (min-width: 1024px) {
  .c-solution-blocks__item::before, .c-solution-blocks__item::after {
    width: 0;
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__item::before, .c-solution-blocks__item::after {
    width: 60px;
  }
}
.c-solution-blocks__item::before {
  right: 100%;
}
.c-solution-blocks__item::after {
  left: 100%;
}

.c-solution-blocks__icon-wrapper {
  width: 10.7rem;
  height: 9.1rem;
}
@media (min-width: 1024px) {
  .c-solution-blocks__icon-wrapper {
    position: absolute;
    top: 50%;
    right: 5rem;
    width: 15.4rem;
    height: 13.1rem;
    transform: translateY(-50%);
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__icon-wrapper {
    right: 3rem;
    width: 21.4rem;
    height: 18.2rem;
    transform: translateY(-60%);
  }
}

.c-solution-blocks__name {
  margin-top: 3.4rem;
  color: #045b7f;
  font-size: 1.9rem;
  font-weight: 900;
}
@media (min-width: 1024px) {
  .c-solution-blocks__name {
    font-size: 2.8rem;
    margin-top: 0;
    font-weight: 700;
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__name {
    font-size: 3.8rem;
  }
}

.c-solution-blocks__link {
  color: inherit;
  text-decoration: none;
}

.c-solution-blocks__description {
  text-align: center;
  max-width: 80%;
  line-height: 1.46;
}
@media (min-width: 1024px) {
  .c-solution-blocks__description {
    text-align: left;
    max-width: 60%;
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__description {
    font-size: 2.4rem;
    max-width: 60%;
  }
}
@media (min-width: 1024px) {
  .c-solution-blocks__description:last-of-type {
    margin-bottom: 5rem;
  }
}

.c-solution-blocks__button {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .c-solution-blocks__button {
    align-self: center;
  }
}
@media (min-width: 1440px) {
  .c-solution-blocks__button {
    margin-right: 60px;
  }
}

.c-solution-blocks br {
  display: none;
}

.c-solution-icons {
  margin-top: 3.8rem;
}
@media (min-width: 1024px) {
  .c-solution-icons {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8rem;
  }
}
@media (min-width: 1440px) {
  .c-solution-icons {
    margin-top: 12rem;
  }
}

.c-solution-icons__item {
  margin: 2.2rem 0 0 2.5rem;
}
@media (min-width: 1024px) {
  .c-solution-icons__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    margin: 0;
  }
}

.c-solution-icons__icon-wrapper {
  display: none;
}
@media (min-width: 1024px) {
  .c-solution-icons__icon-wrapper {
    display: block;
    width: 8rem;
    height: 6.6rem;
  }
}
@media (min-width: 1440px) {
  .c-solution-icons__icon-wrapper {
    width: 12rem;
    height: 9.9rem;
  }
}

.c-solution-icons__icon {
  max-width: 100%;
  max-height: 100%;
}

.c-solution-icons__name {
  color: #045b7f;
  font-weight: 900;
}
@media (min-width: 1024px) {
  .c-solution-icons__name {
    margin-top: 2.4rem;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
  }
}
@media (min-width: 1440px) {
  .c-solution-icons__name {
    font-size: 2.4rem;
  }
}

.c-solution-icons__link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.c-solution-icons__description,
.c-solution-icons__button {
  display: none;
}

.c-solutions-spacer {
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .c-solutions-spacer {
    padding-top: 8rem;
  }
}
@media (min-width: 1024px) {
  .c-solutions-spacer {
    padding-top: 12rem;
  }
}
@media (min-width: 1440px) {
  .c-solutions-spacer {
    padding-top: 17rem;
  }
}

.c-start {
  padding: 10rem 1.1rem 5.8rem;
}
@media (min-width: 1024px) {
  .c-start {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.3rem 0 17.2rem;
  }
}
@media (min-width: 1440px) {
  .c-start {
    padding: 19.1rem 0 24.8rem;
  }
}

.c-start__inner {
  position: relative;
  padding: 6.2rem 2rem 4rem;
  background: radial-gradient(circle at center -30%, #e6efe2, #c0f6fb);
  transition: all 250ms;
}
@media (min-width: 480px) {
  .c-start__inner {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .c-start__inner {
    text-align: left;
    width: 76.8rem;
    padding: 60px;
  }
}
@media (min-width: 1440px) {
  .c-start__inner {
    width: 96rem;
  }
}

.c-start__question-mark {
  position: absolute;
  max-width: 5.3rem;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: all 250ms;
}
@media (min-width: 1024px) {
  .c-start__question-mark {
    top: 50%;
    left: auto;
    right: 9rem;
    max-width: 10.6rem;
    transform: translateY(-50%);
  }
}
@media (min-width: 1440px) {
  .c-start__question-mark {
    max-width: 13.3rem;
    right: 11.1rem;
  }
}

.c-start__heading {
  transition: all 250ms;
}
@media (min-width: 768px) {
  .c-start__heading {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  .c-start__heading {
    margin: 0;
  }
}

.c-start__paragraph {
  max-width: 23rem;
  transition: all 250ms;
}
@media (min-width: 480px) {
  .c-start__paragraph {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  .c-start__paragraph {
    max-width: 80%;
    margin: 2.5rem 0 0;
  }
}
@media (min-width: 1440px) {
  .c-start__paragraph {
    max-width: 65%;
  }
}

.c-start__button {
  transition: all 250ms;
}
@media (min-width: 1024px) {
  .c-start__button {
    margin: 3rem 0 0;
  }
}

/* ==========================================================================
   #STYLEGUIDE
   ========================================================================== */
.c-style-guide__intro {
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  border-right-width: 2px;
  margin: 2em 0 1em 0;
  padding: 2em;
}

.c-style-guide__title {
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0 0 0.25em 0;
  text-transform: uppercase;
}

.c-style-guide__desc {
  color: #666;
  margin: 0;
}

.c-style-guide__section {
  padding: 1rem 0;
}
.c-style-guide__section::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.c-style-guide__section-title {
  border-bottom: 1px solid #ccc;
  clear: both;
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  width: 50%;
}

.c-style-guide__section-subtitle {
  border-left: 6px solid #ccc;
  clear: both;
  color: #999;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 2em 0;
  padding-left: 1em;
  text-transform: uppercase;
}

.c-sunski {
  padding-top: 4rem;
  padding-bottom: 6rem;
}
@media (min-width: 1024px) {
  .c-sunski {
    padding-top: 8rem;
    padding-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .c-sunski {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.c-sunski__logo {
  max-width: 14rem;
}
@media (min-width: 1024px) {
  .c-sunski__logo {
    max-width: 18.8rem;
  }
}

.c-sunski__blockquote {
  margin: 4rem auto 0;
}
@media (min-width: 1024px) {
  .c-sunski__blockquote {
    margin: 6rem auto 2rem;
  }
}

.c-sunski__paragraph {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.7rem;
  max-width: 40rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .c-sunski__paragraph {
    font-size: 2rem;
    max-width: 56rem;
  }
}
@media (min-width: 1440px) {
  .c-sunski__paragraph {
    font-size: 2.4rem;
    max-width: 61rem;
  }
}

@media (min-width: 1024px) {
  .c-sunski__footer {
    margin-top: 4rem;
  }
}
@media (min-width: 1440px) {
  .c-sunski__footer {
    font-size: 1.6rem;
  }
}

.c-team__member + .c-team__member {
  margin-top: 8rem;
}
@media (min-width: 768px) {
  .c-team__member + .c-team__member {
    margin-top: 5rem;
  }
}
@media (min-width: 768px) {
  .c-team__member {
    display: flex;
    align-items: flex-start;
    margin-top: 5rem;
  }
}

.c-team__wrapper {
  max-width: 22rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .c-team__wrapper {
    flex-shrink: 0;
    width: 23rem;
    max-width: none;
    margin-right: 6rem;
    margin-top: 0.7rem;
  }
}
@media (min-width: 1440px) {
  .c-team__wrapper {
    width: 28rem;
  }
}

.c-team__linkedin-link {
  display: block;
  padding: 1.2rem;
  background-color: #2cb9dc;
  text-align: right;
  transition: all 250ms;
}
.c-team__linkedin-link:hover {
  background-color: #42d7fd;
}
.c-team__linkedin-link:focus {
  background-color: #08668d;
}

.c-team__linkedin-icon {
  max-width: 3rem;
}

.c-team__name {
  margin-top: 3rem;
  color: #045b7f;
  font-size: 1.7rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  .c-team__name {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .c-team__name {
    font-size: 1.8rem;
  }
}
@media (min-width: 1440px) {
  .c-team__name {
    font-size: 2.2rem;
  }
}

.c-team__role {
  color: #045b7f;
  font-size: 1.7rem;
  font-weight: 400;
  font-style: italic;
}
@media (min-width: 1024px) {
  .c-team__role {
    font-size: 1.8rem;
  }
}
@media (min-width: 1440px) {
  .c-team__role {
    font-size: 2.2rem;
  }
}

.c-team__description p {
  line-height: 1.46;
}

.c-toolbar {
  display: flex;
  justify-content: space-between;
  height: 52px;
  list-style: none;
  margin: 0;
}
.c-toolbar li {
  margin: 0;
}
@media (min-width: 480px) {
  .c-toolbar {
    justify-content: flex-start;
    gap: 51px;
  }
}
@media (max-width: 1023px) {
  .c-toolbar.c-toolbar--top {
    display: none;
  }
}

.c-toolbar--top li {
  display: grid;
  place-items: center;
}
.c-toolbar--top .is-active {
  position: relative;
}
.c-toolbar--top .is-active::after {
  position: absolute;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #d4d4d5;
}

.c-toolbar--mobile {
  margin-top: 32px;
  gap: 20px;
  height: auto;
  align-items: center;
}

.c-toolbar__link {
  display: block;
  flex: 1;
  transition: all 300ms ease-in-out;
}
.c-toolbar__link:hover {
  opacity: 0.7;
}

.c-toolbar__logo--vision {
  position: relative;
  top: -1px;
  width: 66px;
}

.c-toolbar__logo--platform {
  position: relative;
  top: -1px;
  width: 98px;
}

@media (min-width: 768px) {
  .c-upgrade__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
  }
}
@media (min-width: 1440px) {
  .c-upgrade__wrapper {
    margin-top: 6rem;
  }
}

@media (min-width: 1024px) {
  .c-upgrade__paragraph {
    max-width: 65rem;
  }
}
@media (min-width: 1440px) {
  .c-upgrade__paragraph {
    max-width: 80rem;
  }
}

.c-upgrade__block {
  position: relative;
  margin-top: 3.5rem;
  background-color: #045b7f;
  padding-top: 30px;
  padding-bottom: 30px;
}
.c-upgrade__block::before, .c-upgrade__block::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  background: inherit;
  transition: all 250ms;
}
@media (min-width: 768px) {
  .c-upgrade__block::before, .c-upgrade__block::after {
    width: 0;
  }
}
.c-upgrade__block::before {
  left: -30px;
}
.c-upgrade__block::after {
  right: -30px;
}
@media (min-width: 768px) {
  .c-upgrade__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 2.5rem);
    padding: 2.3rem;
    margin-top: 0;
    margin-bottom: 4rem;
  }
}
@media (min-width: 1024px) {
  .c-upgrade__block {
    padding: 5rem 5rem 4.3rem;
  }
}

.c-upgrade__logo {
  max-height: 4rem;
}

.c-upgrade__image {
  display: block;
  height: 2.5rem;
  width: auto;
}
@media (min-width: 768px) {
  .c-upgrade__image {
    height: 4rem;
  }
}
@media (min-width: 1440px) {
  .c-upgrade__image {
    height: 5rem;
  }
}

@media (min-width: 768px) {
  .c-upgrade__block-paragraph {
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 2.3rem 0 0;
  }
}
@media (min-width: 1440px) {
  .c-upgrade__block-paragraph {
    margin: 5rem 0 0;
    font-size: 2rem;
  }
}

.c-upgrade__block-link-wrapper {
  margin-top: 1.5em;
}
@media (min-width: 1024px) {
  .c-upgrade__block-link-wrapper {
    margin-top: 2.5em;
  }
}

.c-upgrade__block-link {
  text-decoration: none;
  color: #42d7fd;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1.2px;
}

.c-vertical-tile {
  display: block;
  padding-bottom: 1.8rem;
  border-bottom: 2px solid #c5f1fd;
}
.c-vertical-tile.is-hidden {
  display: none !important;
}
@media (min-width: 768px) {
  .c-vertical-tile {
    display: grid;
    grid-template-columns: 294px auto;
    grid-template-rows: auto auto auto 1fr;
    column-gap: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (min-width: 1024px) {
  .c-vertical-tile {
    column-gap: 4.4rem;
  }
}

.c-vertical-tile--featured {
  padding-bottom: 0;
  border: 0;
}
@media (min-width: 1024px) {
  .c-vertical-tile--featured .c-vertical-tile__title {
    font-size: 2.8rem;
    line-height: 1.19;
  }
  .c-vertical-tile--featured .c-vertical-tile__text {
    margin-top: 1.6rem;
    font-size: 2rem;
    line-height: 1.5;
  }
}
@media (min-width: 1440px) {
  .c-vertical-tile--featured {
    grid-template-columns: 445px auto;
  }
  .c-vertical-tile--featured .c-vertical-tile__image {
    aspect-ratio: 445/324;
  }
  .c-vertical-tile--featured .c-vertical-tile__details {
    margin-bottom: 2.4rem;
  }
  .c-vertical-tile--featured .c-vertical-tile__title {
    font-size: 3.6rem;
  }
  .c-vertical-tile--featured .c-vertical-tile__text {
    margin-top: 3.2rem;
    font-size: 2.4rem;
  }
  .c-vertical-tile--featured .c-vertical-tile__cta {
    margin-top: 2.4rem;
  }
}

.c-vertical-tile__picture {
  display: block;
}
@media (min-width: 768px) {
  .c-vertical-tile__picture {
    grid-row: 1/5;
  }
}

.c-vertical-tile__image {
  aspect-ratio: 294/180;
  width: 100%;
  object-fit: cover;
}

.c-vertical-tile__details {
  margin-block: 1.5rem;
  font-size: 1.4rem;
  color: #08668d;
}
@media (min-width: 768px) {
  .c-vertical-tile__details {
    margin-top: 0;
    grid-column-start: 2;
  }
}
@media (min-width: 1024px) {
  .c-vertical-tile__details {
    font-size: 1.8rem;
  }
}

.c-vertical-tile__details-title {
  font-weight: 700;
  text-transform: uppercase;
  color: #091e3e;
  letter-spacing: 0.37px;
}

.c-vertical-tile__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: currentColor;
}

.c-vertical-tile__details-data {
  font-weight: 500;
  opacity: 0.6;
  letter-spacing: 0.5px;
  color: #08668d;
}

.c-vertical-tile__title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.42;
  letter-spacing: 0.4px;
  color: #386180;
}
.c-vertical-tile__title a {
  color: inherit;
  text-decoration: none;
}
@media (min-width: 768px) {
  .c-vertical-tile__title {
    grid-column-start: 2;
  }
}
@media (min-width: 1024px) {
  .c-vertical-tile__title {
    font-size: 2.4rem;
  }
}

.c-vertical-tile__text {
  display: none;
  margin-top: 1.1rem;
  font-size: 1.4rem;
  color: #386180;
}
@media (min-width: 768px) {
  .c-vertical-tile__text {
    display: block;
    grid-column-start: 2;
  }
}
@media (min-width: 1024px) {
  .c-vertical-tile__text {
    font-size: 1.8rem;
  }
}

.c-vertical-tile__cta {
  display: inline-block;
  margin-top: 1.8rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 1.23px;
  color: #2cb9dc;
  transition: color 250ms;
}
.c-vertical-tile__cta:hover {
  color: #045b7f;
}
@media (min-width: 768px) {
  .c-vertical-tile__cta {
    margin-top: 2.4rem;
    grid-column-start: 2;
  }
}
@media (min-width: 1024px) {
  .c-vertical-tile__cta {
    margin-top: 4.7rem;
  }
}

.c-vlist {
  margin-left: 3.2rem;
}
@media (min-width: 1024px) {
  .c-vlist {
    display: flex;
    flex-wrap: wrap;
    margin: 11rem 0 5rem;
  }
}
@media (min-width: 1440px) {
  .c-vlist {
    margin: 11rem 0 9rem;
  }
}

.c-vlist__item {
  display: flex;
  margin: 2rem 0;
  align-items: center;
}
@media (min-width: 1024px) {
  .c-vlist__item {
    width: 50%;
    margin: 1.5rem 0;
  }
}

.c-vlist__punctuation-wrapper {
  width: 1.8rem;
  height: 2.1rem;
  margin-right: 1.4rem;
}
@media (min-width: 1024px) {
  .c-vlist__punctuation-wrapper {
    width: 2.7rem;
    height: 3.2rem;
    margin-right: 3rem;
  }
}

.c-vlist__punctuation-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.c-vlist__text {
  color: #045b7f;
}
@media (min-width: 1024px) {
  .c-vlist__text {
    font-size: 2rem;
  }
}
@media (min-width: 1440px) {
  .c-vlist__text {
    font-weight: 900;
  }
}

.c-resources-case-studies__animate-height {
  transition: height 1s;
  height: auto;
  overflow: hidden;
}

.c-resources-case-studies__secondary {
  padding-top: 4rem;
}

.c-resources-case-studies__row {
  margin-bottom: -4rem;
}

/* stylelint-disable no-descending-specificity */
.c-resources-case-studies__single {
  display: flex;
  line-height: 1.3;
  margin-bottom: 4rem;
}
.c-resources-case-studies__primary .c-resources-case-studies__single:nth-of-type(3) {
  display: none;
}
@media (min-width: 1024px) {
  .c-resources-case-studies__primary .c-resources-case-studies__single:nth-of-type(3) {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .c-resources-case-studies__secondary .c-resources-case-studies__single:first-of-type,
.c-resources-case-studies__secondary .c-resources-case-studies__single-spacer:first-of-type {
    display: none;
  }
}

/* stylelint-enable no-descending-specificity */
.c-resources-case-studies__single-link {
  width: 100%;
  display: flex;
  background-color: #08668d;
  padding: 3rem;
  text-decoration: none;
}

.c-resources-case-studies__single-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.c-resources-case-studies__single-image-wrapper {
  flex: 0 0 auto;
  margin: 0 -1rem 2rem;
}

.c-resources-case-studies__single-image {
  padding-bottom: 57.14%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-resources-case-studies__single-title {
  font-size: 1.6rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  letter-spacing: 0.19px;
}
@media (min-width: 1024px) {
  .c-resources-case-studies__single-title {
    font-size: 2.2rem;
  }
}

.c-resources-case-studies__single-subtitle {
  flex: 1 0 auto;
  min-height: 5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  letter-spacing: 0.16px;
}
@media (min-width: 1024px) {
  .c-resources-case-studies__single-subtitle {
    font-size: 1.9rem;
  }
}

.c-resources-case-studies__single-download {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: #42d7fd;
  letter-spacing: 0.5px;
}

.c-resources-data-sheets__row {
  margin-bottom: -4rem;
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__row {
    margin-bottom: -6.5rem;
  }
}

.c-resources-data-sheets__single {
  display: flex;
  margin-bottom: 4rem;
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__single {
    margin-bottom: 6.5rem;
  }
}

.c-resources-data-sheets__single-link {
  width: 100%;
  display: flex;
  background-color: #08668d;
  padding: 3rem 0 2rem;
  text-decoration: none;
}
@media (min-width: 768px) {
  .c-resources-data-sheets__single-link {
    padding: 4rem 0 3rem;
  }
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__single-link {
    padding: 5.5rem 0 4rem;
  }
}

.c-resources-data-sheets__single-side-spacer {
  width: 3rem;
}
@media (min-width: 1024px) {
  .c-resources-data-sheets__single-side-spacer {
    width: 11.1111111111%;
  }
}

.c-resources-data-sheets__single-inner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.c-resources-data-sheets__single-image-wrapper {
  flex: 0 0 auto;
  margin-bottom: 2rem;
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__single-image-wrapper {
    margin-bottom: 4.5rem;
  }
}

.c-resources-data-sheets__single-image {
  padding-bottom: 47.62%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-resources-data-sheets__single-title {
  flex: 1 0 auto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .c-resources-data-sheets__single-title {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__single-title {
    font-size: 2.8rem;
  }
}

.c-resources-data-sheets__single-download {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: #42d7fd;
  letter-spacing: 1.5px;
}
@media (min-width: 1440px) {
  .c-resources-data-sheets__single-download {
    font-size: 2rem;
  }
}

.c-resources-newsletter-archive {
  color: #386180;
}

.c-resources-newsletter-archive__header {
  margin-bottom: 2rem;
}
@media (min-width: 1440px) {
  .c-resources-newsletter-archive__header {
    margin-bottom: 5rem;
  }
}

.c-resources-newsletter-archive__heading {
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.c-resources-newsletter-archive__subheading {
  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .c-resources-newsletter-archive__subheading {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }
}
@media (min-width: 1440px) {
  .c-resources-newsletter-archive__subheading {
    font-size: 2.8rem;
  }
}

.c-resources-newsletter-archive__single {
  font-size: 1.6rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .c-resources-newsletter-archive__single {
    font-size: 1.8rem;
    letter-spacing: 0.41px;
  }
}
@media (min-width: 1440px) {
  .c-resources-newsletter-archive__single {
    font-size: 2.4rem;
  }
}
.c-resources-newsletter-archive__single:not(:last-child) {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .c-resources-newsletter-archive__single:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1440px) {
  .c-resources-newsletter-archive__single:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.c-resources-newsletter-archive__single-heading {
  margin-bottom: 0.3em;
}
@media (min-width: 768px) {
  .c-resources-newsletter-archive__single-heading {
    margin-bottom: 0.6em;
  }
}

.c-resources-newsletter-archive__single-heading-date {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .c-resources-newsletter-archive__single-heading-date {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }
}
@media (min-width: 1440px) {
  .c-resources-newsletter-archive__single-heading-date {
    font-size: 2.8rem;
  }
}

.c-resources-newsletter-archive__single-heading-title {
  font-weight: 400;
}

.c-resources-newsletter-archive__single-link {
  color: #2cb9dc;
  font-weight: 700;
  font-style: italic;
  text-decoration: none;
}

.c-resources-recent-articles__row {
  position: relative;
  padding-bottom: 4rem;
}
@media (min-width: 1440px) {
  .c-resources-recent-articles__row {
    padding-bottom: 7rem;
  }
}
.c-resources-recent-articles__single:not(:last-child) .c-resources-recent-articles__row {
  margin-bottom: 4rem;
}
@media (min-width: 1440px) {
  .c-resources-recent-articles__single:not(:last-child) .c-resources-recent-articles__row {
    margin-bottom: 7rem;
  }
}
.c-resources-recent-articles__row::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  width: calc(100% - 1rem);
  border-bottom: 2px solid #c5f1fd;
}

.c-resources-recent-articles__single-main {
  display: flex;
}

.c-resources-recent-articles__single-image-wrapper {
  flex: 0 0 auto;
  width: 6rem;
  padding-right: 1rem;
  display: flex;
}
@media (min-width: 768px) {
  .c-resources-recent-articles__single-image-wrapper {
    width: 8rem;
  }
}
@media (min-width: 1024px) {
  .c-resources-recent-articles__single-image-wrapper {
    min-height: 12rem;
    padding-right: 2rem;
    width: 12rem;
  }
}

.c-resources-recent-articles__single-image {
  width: 100%;
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
}

.c-resources-recent-articles__single-info {
  width: 100%;
  color: #386180;
  line-height: 1.1;
}

.c-resources-recent-articles__single-heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .c-resources-recent-articles__single-heading {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .c-resources-recent-articles__single-heading {
    font-size: 2.4rem;
    margin-bottom: 1.8rem;
  }
}

.c-resources-recent-articles__single-publication,
.c-resources-recent-articles__single-author {
  font-size: 1.6rem;
}
@media (min-width: 1024px) {
  .c-resources-recent-articles__single-publication,
.c-resources-recent-articles__single-author {
    font-size: 1.8rem;
  }
}

.c-resources-recent-articles__single-publication {
  margin-bottom: 1rem;
}

.c-resources-recent-articles__single-author {
  font-style: italic;
}

.c-resources-white-papers__single {
  color: #386180;
}
.c-resources-white-papers__single:not(:last-child) {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .c-resources-white-papers__single:not(:last-child) {
    margin-bottom: 6rem;
  }
}

.c-resources-white-papers__single-heading {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
  white-space: pre-line;
}
@media (min-width: 768px) {
  .c-resources-white-papers__single-heading {
    font-size: 2rem;
    letter-spacing: 0.48px;
  }
}
@media (min-width: 1440px) {
  .c-resources-white-papers__single-heading {
    font-size: 2.8rem;
  }
}

.c-resources-white-papers__single-subtitle {
  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .c-resources-white-papers__single-subtitle {
    font-size: 1.8rem;
    letter-spacing: 0.41px;
  }
}
@media (min-width: 1440px) {
  .c-resources-white-papers__single-subtitle {
    font-size: 2.4rem;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1023px) {
  .u-center--up-to-large {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-margin {
  margin: 1em;
}

.u-margin-top {
  margin-top: 1em;
}

.u-margin-top--2 {
  margin-top: 2em;
}

.u-margin-right {
  margin-right: 1em;
}

.u-margin-bottom {
  margin-bottom: 1em;
}

.u-margin-left {
  margin-left: 1em;
}

.u-padding {
  padding: 1em;
}

.u-padding-top {
  padding-top: 1em;
}

.u-padding-right {
  padding-right: 1em;
}

.u-padding-bottom {
  padding-bottom: 1em;
}

.u-padding-left {
  padding-left: 1em;
}

.u-spaced {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.u-bg--grey {
  background-color: #f0f0f0;
}

.u-bg--grey-to-left {
  position: relative;
}
.u-bg--grey-to-left::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f0f0f0;
}
@media (min-width: 1024px) {
  .u-bg--grey-to-left::before {
    right: 4.6rem;
    border-radius: 0 2rem 2rem 0;
  }
}

.u-bg--lightblue-to-right {
  position: relative;
  margin-bottom: 1rem;
}
.u-bg--lightblue-to-right::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f1fafd;
}
@media (min-width: 1440px) {
  .u-bg--lightblue-to-right::before {
    left: 12.5rem;
    border-radius: 2rem 0 0 2rem;
  }
}
@media (min-width: 768px) {
  .u-bg--lightblue-to-right::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    bottom: -1rem;
    height: 1rem;
    background-color: rgba(0, 99, 139, 0.3);
  }
}
@media (min-width: 1440px) {
  .u-bg--lightblue-to-right::after {
    height: auto;
    top: 0;
    left: 13.5rem;
    border-radius: 2rem 0 0 2rem;
  }
}

.u-bg--primary {
  background: #c5f1fd;
}

.u-bg--secondary {
  background: #08668d;
}
.u-bg--secondary h2,
.u-bg--secondary p,
.u-bg--secondary li {
  color: #fff;
}

.u-bg--secondary-gradient {
  background: radial-gradient(circle at center top, #139fc1, #08668d);
}
.u-bg--secondary-gradient h2,
.u-bg--secondary-gradient p,
.u-bg--secondary-gradient li {
  color: #fff;
}

.u-bg--skyblue {
  background: linear-gradient(to bottom, #bce8f2, #def8ff);
}

.u-bg--oceanblue {
  background: radial-gradient(circle at center top, #e6efe2, #c0f6fb);
}

.u-bg--v-part {
  position: relative;
  overflow: hidden;
}
.u-bg--v-part::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: -138px;
  top: -5px;
  width: 189px;
  height: 335px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/bg-a.png");
  transition: all 250ms;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .u-bg--v-part::before {
    left: -58px;
  }
}
.u-bg--v-part::after {
  content: "";
  display: block;
  position: absolute;
  right: -135px;
  bottom: 195px;
  width: 189px;
  height: 335px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/bg-b.png");
  transition: all 250ms;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .u-bg--v-part::after {
    right: -58px;
    bottom: 50px;
  }
}

.u-bg--v-full, .c-get-started__bg--v-full {
  position: relative;
}
.u-bg--v-full::after, .c-get-started__bg--v-full::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  right: -65px;
  top: 0;
  width: 219px;
  height: 234px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/bg-v.png");
  background-size: cover;
  transition: all 250ms;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .u-bg--v-full::after, .c-get-started__bg--v-full::after {
    right: auto;
    left: 57%;
    top: 50%;
    width: 398px;
    height: 425px;
    transform: translateY(-35%);
  }
}
@media (min-width: 1440px) {
  .u-bg--v-full::after, .c-get-started__bg--v-full::after {
    left: 59.5%;
    width: 498px;
    height: 531px;
  }
}

.u-bg--circle, .c-get-started__bg--circle {
  position: relative;
  overflow: hidden;
}
.u-bg--circle::before, .c-get-started__bg--circle::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  max-width: 40rem;
  max-height: 40rem;
  background: radial-gradient(circle at center center, #7de5ef 0%, white 66%);
  transform: translateX(-50%) translateY(-50%);
}