.c-capabilities__list {
  display: block;

  @include bp-large {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
}

.c-capabilities__item {
  @include bp-large {
    width: calc(50% - 2.5rem);
  }

  @include bp-xlarge {
    width: calc(50% - 3.5rem);
  }
}

.c-capabilities__heading {
  margin-top: 4rem;
  color: $color-secondary;
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 900;
  letter-spacing: 0.25px;

  @include bp-large {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  @include bp-xlarge {
    margin-top: 7rem;
    font-size: 2.2rem;
  }
}

.c-capabilities__paragraph {
  margin: 1.5rem 0;

  &:first-of-type {
    @include bp-large {
      margin: 2rem 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
