.o-content {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $bp-large - ($padding * 2 + $indentation_large * 2);
  padding-left: $padding;
  padding-right: $padding;

  @include bp-large {
    max-width: none;
    padding-left: $shift_large;
    padding-right: $shift_large;
  }

  @include bp($bp-xlarge - $shift_diff * 2) {
    padding: 0;
    max-width: $maxwidth_xlarge;
  }
}

.o-content--narrow {
  @include bp-large {
    max-width: 76.8rem;
    padding-left: 0;
    padding-right: 0;
  }

  @include bp-xlarge {
    max-width: $maxwidth_xlarge - $padding_xlarge * 2;
  }
}

.o-content--jobs {
  @include bp-large {
    max-width: 90rem;
    padding-left: 0;
    padding-right: 0;
  }

  @include bp-xlarge {
    max-width: $maxwidth_xlarge - $padding_xlarge * 2 + 175;
  }
}
