.c-post {
  padding-bottom: 5rem;

  @include bp-large {
    padding-bottom: 10rem;
  }
}

.c-post__toolbar {
  position: sticky;
  top: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  padding: 25px $padding;
  gap: 40px;
  background: #fff;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.04);

  .c-social-list {
    margin-left: auto;
    font-size: 1.6rem;
  }

  @include bp-large {
    padding-inline: 36px;
  }
}

.c-post__toolbar-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  background: rgba(9, 30, 62, 0.04);
}

.c-post__toolbar-progress-bar {
  --scroll-amount: 0%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--scroll-amount);
  background: $color-primary;
}

.c-post__toolbar-back {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.705849px;
  color: $color-blue-dark;
  text-transform: uppercase;
  text-decoration: none;
}

.c-post__toolbar-back-arrow {
  margin-right: 12px;
}

.c-post__toolbar-title {
  font-size: 2rem;
  font-weight: 700;
  color: $color-secondary-3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  display: none;

  @include bp-large {
    display: block;
  }
}

.c-post__picture {
  display: block;
  text-align: center;
}

.c-post__image {
  aspect-ratio: 1070 / 435;
  width: 100%;
  object-fit: contain;
}

.c-post__header {
  margin-block: 4.8rem 3.6rem;

  @include bp-large {
    margin-block: 10.4rem 5.1rem;
  }
}

.c-post__title-content {
  @include bp-medium {
    display: flex;
    justify-content: space-between;
  }
}

.c-post__title {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  letter-spacing: 0.501466px;
  line-height: 1.37;
  color: $color-secondary-3;

  @include bp-medium {
    flex: 1;
    margin: 0;
    padding-right: 5.5rem;
    letter-spacing: 0.5px;
    font-size: 2.8rem;
  }

  @include bp-xlarge {
    font-size: 3.8rem;
  }
}

.c-post__details {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: $color-secondary-bg;

  @include bp-large {
    margin-top: 4rem;
    font-size: 1.8rem;
  }
}

.c-post__details-title {
  float: left;
  font-weight: 700;
  opacity: 0.4;
  letter-spacing: 0.37px;
  text-transform: uppercase;
}

.c-post__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: $color-secondary-2;
}

.c-post__details-data {
  font-weight: 500;
  opacity: 0.4;
  letter-spacing: 0.7px;
}

.c-post__container {
  @include bp-xlarge {
    display: grid;
    gap: 5rem;
    grid-template-columns: auto 290px;
  }
}

/* stylelint-disable */
.c-post__content {
  max-width: 746px;
  margin-bottom: 2rem;
  word-break: break-word;

  > *:first-child {
    margin-top: 0 !important;
  }

  *:last-child {
    margin-bottom: 0 !important;
  }

  .image,
  .bg-box {
    margin-block: 4.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block: 4.2rem 2rem;
    color: $color-secondary;
    font-weight: 700;

    b,
    strong {
      font-weight: 700;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h1,
  h2 {
    font-family: SharpSans, sans-serif;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
  }

  h6 {
    font-size: 1.4rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
    transition: color $transition;

    &:hover {
      color: $color-tertiary;
    }
  }

  p + h3 {
    margin-top: 4rem;
  }

  &,
  p {
    font-size: 2rem;
    line-height: 1.4;
    color: $color-secondary-2;
  }

  p,
  ul,
  ol,
  blockquote {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    quotes: none;

    p {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.45;
      font-style: normal;
      color: $color-secondary-bg;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 48px;
      height: 3px;
      margin-right: 1.5rem;
      vertical-align: middle;
      background: $color-primary;
    }
  }

  ul,
  ol {
    margin: 2rem 0;

    ul,
    ol {
      margin-left: 1rem;
      margin-block: 1rem 2rem !important;
    }

    li {
      position: relative;
    }
  }

  ul {
    margin-left: 0;
    list-style-position: inside;
    list-style-type: none;

    > li {
      padding-left: 1.5rem;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background: currentColor;
        border-radius: 50%;
      }
    }
  }

  ol {
    counter-reset: list;
    list-style-type: none;
    list-style-position: inside;

    > li {
      padding-left: 2rem;

      &::before {
        content: counter(list) ".";
        counter-increment: list;
        position: absolute;
        left: 0;
      }
    }

    ol {
      > li {
        &::before {
          content: counter(list, lower-alpha) ".";
        }
      }
    }
  }

  .image {
    &[data-align="left"] {
      text-align: left;
    }

    &[data-align="center"] {
      text-align: center;
    }

    &[data-align="right"] {
      text-align: right;
    }
  }

  .bg-box {
    padding: 24px 16px;
  }

  @include bp-medium {
    blockquote {
      position: relative;
      font-size: 1.8rem;
      padding-left: 6.3rem;

      &::before {
        position: absolute;
        top: 15px;
        left: 0;
      }
    }

    ul,
    ol {
      margin-left: 6.3rem;

      ul,
      ol {
        margin-left: 1rem;
      }
    }
  }

  @include bp-large {
    margin-bottom: 4rem;

    .bg-box {
      padding: 48px 40px;
    }
  }
}
/* stylelint-enable */

.c-post__sidebar {
  display: none;

  @include bp-xlarge {
    display: block;
  }
}

.c-post__sidebar-title {
  margin-bottom: 2.4rem;
  font-size: 2.2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 700;
}

/* stylelint-disable */
// Custom Text Netlify CMS
// -----------------------------------------------------------------------------
.c-post__content .custom-text * {
  font-size: inherit;
}

// Custom Netlify CMS List
// -----------------------------------------------------------------------------
.c-post__content ul.checkmark-list {
  display: block;
  margin-left: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: outside;

  @include bp-medium {
    margin-left: 7.3rem;
  }

  li {
    color: inherit;
    margin: 1rem 0;
    padding-left: 2rem;
    line-height: 1.4;
    display: list-item;
    list-style-type: none;

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 12px;
      left: 0;
      width: 6px;
      height: 6px;
      background: currentColor;
      border-radius: 50%;
    }
  }

  &[data-bullet-type="decimal"] {
    margin-left: 1.8rem;

    @include bp-medium {
      margin-left: 9.3rem;
    }

    > li {
      list-style-type: decimal;
      padding-left: 0.8rem;

      &::before {
        display: none;
      }
    }
  }

  &[data-bullet-type="alpha"] {
    margin-left: 1.8rem;

    @include bp-medium {
      margin-left: 9.3rem;
    }

    > li {
      list-style-type: lower-alpha;
      padding-left: 0.8rem;

      &::before {
        display: none;
      }
    }
  }

  &[data-bullet-type="traverse-bullet"] {
    // margin-left: 7rem;

    @include bp-medium {
      margin-left: 7rem;
    }

    > li {
      margin: 1.6rem 0;
      list-style-type: none;
      line-height: 1.4;
      padding-left: 3.5rem;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 2rem;
        height: 2rem;
        background:
          transparent
          url("../assets/images/traverse-bullet.png") center center / contain no-repeat;
        border-radius: 0;
      }
    }
  }

  &[data-bullet-type="rivet-bullet"] {
    // margin-left: 7rem;

    @include bp-medium {
      margin-left: 7rem;
    }

    > li {
      margin: 1.6rem 0;
      list-style-type: none;
      line-height: 1.4;
      padding-left: 3.5rem;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 2rem;
        height: 2rem;
        background:
          transparent
          url("../assets/images/rivet-bullet.png") center center / contain no-repeat;
        border-radius: 0;
      }
    }
  }

  &[data-bullet-type="vision-bullet"] {
    // margin-left: 7rem;

    @include bp-medium {
      margin-left: 7rem;
    }

    > li {
      margin: 1.6rem 0;
      list-style-type: none;
      line-height: 1.4;
      padding-left: 3.5rem;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 2rem;
        height: 2rem;
        background:
          transparent
          url("../assets/images/vision-bullet.png") center center / contain no-repeat;
        border-radius: 0;
      }
    }
  }
}
/* stylelint-enable */
