.c-notification {
  text-align: center;
}

.c-notification__heading {
  font-family: SharpSans, sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin: 0;
  letter-spacing: 0.5px;
  color: $color-blue-dark;

  @include bp-medium {
    font-size: 4rem;
  }
}

.c-notification__subheading {
  margin-block: 8px 0;
  font-family: SharpSans, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.3px;
  color: $color-blue-dark;

  @include bp-medium {
    font-size: 2.8rem;
  }
}

.c-btn--notification {
  margin-top: 24px;
  margin-bottom: 0;

  @include bp-medium {
    margin-top: 48px;
  }
}
