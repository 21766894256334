.c-upgrade__wrapper {
  @include bp-medium {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
  }

  @include bp-xlarge {
    margin-top: 6rem;
  }
}

.c-upgrade__paragraph {
  @include bp-large {
    max-width: 65rem;
  }

  @include bp-xlarge {
    max-width: 80rem;
  }
}

.c-upgrade__block {
  position: relative;
  margin-top: 3.5rem;
  background-color: $color-secondary;
  padding-top: $padding;
  padding-bottom: $padding;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $padding;
    background: inherit;
    transition: all $transition;

    @include bp-medium {
      width: 0;
    }
  }

  &::before {
    left: -$padding;
  }

  &::after {
    right: -$padding;
  }

  @include bp-medium {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 2.5rem);
    padding: 2.3rem;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  @include bp-large {
    padding: 5rem 5rem 4.3rem;
  }
}

.c-upgrade__logo {
  max-height: 4rem;
}

.c-upgrade__image {
  display: block;
  height: 2.5rem;
  width: auto;

  @include bp-medium {
    height: 4rem;
  }

  @include bp-xlarge {
    height: 5rem;
  }
}

.c-upgrade__block-paragraph {
  @include bp-medium {
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 2.3rem 0 0;
  }

  @include bp-xlarge {
    margin: 5rem 0 0;
    font-size: 2rem;
  }
}

.c-upgrade__block-link-wrapper {
  margin-top: 1.5em;

  @include bp-large {
    margin-top: 2.5em;
  }
}

.c-upgrade__block-link {
  text-decoration: none;
  color: $color-primary-light;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1.2px;
}
