.c-horizontal-tile {
  display: block;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #e6e6e6;
}

.c-horizontal-tile__picture {
  margin-bottom: 2rem;
  display: block;
}

.c-horizontal-tile__image {
  aspect-ratio: 290 / 100;
  width: 100%;
  object-fit: cover;
}

.c-horizontal-tile__title {
  margin-bottom: 3rem;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.27;
  color: $color-secondary-3;
}

.c-horizontal-tile__details {
  margin-top: 2rem;
  font-size: 1.2rem;
  color: $color-secondary-bg;
}

.c-horizontal-tile__details-title {
  font-weight: 700;
  text-transform: uppercase;
  color: #b3bfca;
  letter-spacing: 0.37px;
}

.c-horizontal-tile__details-line {
  display: inline-block;
  width: 21px;
  height: 1px;
  margin-inline: 1.1rem;
  vertical-align: middle;
  background: $color-secondary-bg;
  opacity: 0.4;
}

.c-horizontal-tile__details-data {
  font-weight: 500;
  opacity: 0.6;
  letter-spacing: 0.5px;
}
