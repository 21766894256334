.o-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable no-descending-specificity */
.o-pagination__item {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.6rem;
  color: $color-secondary-bg;
  text-align: center;

  button + button {
    margin-left: 2px;
  }

  button {
    min-height: 3.2rem;
    min-width: 3.2rem;
    border: 0;
    color: inherit;
    background: transparent;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .is-active {
    color: $color-primary;
    background: rgba(44, 185, 220, 0.1);
  }
}

.o-pagination__item--nr {
  display: flex;

  button {
    padding: 0 0.3rem;
    border-radius: 50%;
  }
}

.o-pagination__item--prev,
.o-pagination__item--next {
  button {
    padding: 0;
  }
}

.o-pagination__item--prev {
  margin-right: 1.4rem;

  svg {
    margin-right: 1rem;
  }
}

.o-pagination__item--next {
  margin-left: 1.4rem;

  svg {
    margin-left: 1rem;
  }
}
/* stylelint-enable no-descending-specificity */
