.c-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 37rem;
  padding-top: $padding;
  padding-bottom: $padding;
  transition: all $transition;
  overflow: hidden;

  &--retail,
  &--grocery,
  &--wholesale-distribution,
  &--digitally-native,
  &--suppliers {
    min-height: 46rem;
  }

  @include bp-medium {
    height: 50rem;
  }

  @include bp-large {
    height: 56rem;
    padding-top: $padding * 2;
    padding-bottom: $padding * 2;
  }

  @include bp-xlarge {
    height: 70rem;
  }

  &--portal {
    height: 37rem !important;
  }

  &--blog {
    min-height: 37rem;
    height: auto !important;
    background: radial-gradient(
      88.83% 88.83% at 46.53% -2.59%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(216, 219, 226, 0.6) 100%
    );
  }
}

.c-hero--contact {
  background: radial-gradient(circle at center top, #139fc1, #08668d);
  background-position: right center;
}

/* Background *****************************************************************/

.c-hero__bg,
.c-hero__icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  pointer-events: none;
}

.c-hero__icon::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(#139fc1, 0.57), transparent);
}

.c-hero__bg--home {
  background-color: #07729c;
  background-position: right 85%;

  @include bp-medium {
    background-position: 70% 85%;
  }

  @include bp-xlarge {
    background-position: right 85%;
  }
}

.c-hero__bg--home::after,
.c-hero__bg--404::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-secondary;
  opacity: 0.85;
  transition: opacity $transition;

  @include bp-medium {
    opacity: 0;
  }
}

.c-hero__bg--platform {
  background-color: #0f668b;
  background-position: left 85%;
  background-size: cover;

  @include bp-large {
    background-position: right 85%;
    background-size: auto 100%;
  }
}

.c-hero__bg--solutions {
  background-color: #1d9ebf;
  background-position: 75% center;
  background-size: cover;

  @include bp-xlarge {
    background-position: right center;
    background-size: auto 100%;
  }
}

.c-hero__bg--about {
  background-color: #126d94;
  background-position: 83% center;
  background-size: auto 100%;

  @include bp-xlarge {
    background-position: right center;
  }
}

.c-hero__bg--contact {
  background-position: right top;
  background-size: auto 57vw;
  right: -14rem;

  @include bp-small {
    background-size: auto 75%;
  }

  @include bp-medium {
    background-size: auto 60%;
    right: calc(100vw - 76.8rem - 14rem);
  }

  @include bp(908px) {
    // 768 + 140
    right: 0;
  }

  @include bp-large {
    background-size: auto;
  }
}

.c-hero__bg--blog {
  background-size: auto 100%;
  background-position: calc(100% + 250px) 0;
  background-repeat: no-repeat;

  @include bp-medium {
    background-position: calc(100% + 125px);
  }

  @include bp-large {
    background-position: right top 0;
  }

  @include bp-xlarge {
    background-position: right 17% top 0;
  }
}

.c-hero__bg--404 {
  background-position: 50% center;

  @include bp-medium {
    background-position: 30% 85%;
  }

  @include bp-xlarge {
    background-position: 100px 85%;
  }

  @include bp(1600px) {
    background-position: right 85%;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #096c93, #08678e 40%, #08668d);
  }
}

.c-hero__bg--misc {
  background-position: right center;

  @include bp-large {
    bottom: -13rem;
    background-position: 60rem center;
  }

  @include bp(1300px) {
    background-position: right center;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: radial-gradient(
      circle at 40% 0%,
      $color-primary,
      $color-secondary 40%
    );
    transition: opacity $transition;

    @include bp-large {
      bottom: 13rem;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $color-secondary;
    opacity: 0.85;
    transition: opacity $transition;

    @include bp-large {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.c-hero__bg--circuit,
.c-hero__icon {
  background-position: 50% 100%;
  background-size: cover;

  @include bp-xlarge {
    background-position: center center;
    background-size: auto 100%;
  }
}

.c-hero__bg--resources {
  background-position: 30% top;
  background-size: cover;

  @include bp-medium {
    background-position: center top;
  }
}

/* Content ********************************************************************/

.c-hero__content {
  transition: margin-top $transition;
}

.c-hero__content--platform {
  margin-top: -5.5rem;

  @include bp-medium {
    margin-top: 0;
  }
}

.c-hero__content--solutions {
  @include bp-large {
    margin-top: -14.5rem;
  }
}

.c-hero__content--about {
  margin-top: -14rem;

  @include bp-small {
    margin-top: -3.5rem;
  }

  @include bp-medium {
    margin-top: -6rem;
  }

  @include bp-xlarge {
    margin-top: -9rem;
  }
}

.c-hero__content--contact {
  margin-top: -16rem;

  @include bp-medium {
    margin-top: 0;
  }
}

.c-hero__content--circuit {
  margin-top: -5rem;

  @include bp-medium {
    margin-top: 0;
  }
}

/* Heading ********************************************************************/

.c-hero__heading {
  position: relative;
  z-index: 1;
  color: $color-bright;
  font: bold 3rem SharpSans, sans-serif;
  letter-spacing: 0.3px;
  transition: all $transition;

  @include bp-medium {
    font-size: 4rem;
    letter-spacing: 0.38px;
  }

  @include bp-xlarge {
    font-size: 5.2rem;
    letter-spacing: 0.5px;
  }
}

.c-hero__heading--home,
.c-hero__heading--404 {
  max-width: 35rem;

  @include bp-medium {
    max-width: 45rem;
  }

  @include bp-large {
    max-width: 50.5rem;
  }

  @include bp-xlarge {
    max-width: 63.5rem;
  }
}

.c-hero__heading--platform {
  max-width: 27rem;

  @include bp-medium {
    max-width: none;
  }
}

.c-hero__heading--solutions {
  max-width: 30rem;

  @include bp-medium {
    max-width: 50rem;
  }

  @include bp-xlarge {
    max-width: 70rem;
  }
}

.c-hero__heading--about {
  max-width: 15rem;

  @include bp-small {
    max-width: none;
  }
}

.c-hero__heading--contact {
  max-width: 15rem;

  @include bp-medium {
    max-width: none;
  }
}

/* Gloss **********************************************************************/

.c-hero__gloss {
  position: relative;
  z-index: 1;
  margin-top: 2.7rem;
  color: $color-bright;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: 0.15px;
  transition: all $transition;

  @include bp-medium {
    font-size: 1.8rem;
    line-height: 1.6;
    letter-spacing: 0.17px;
  }

  @include bp-xlarge {
    font-size: 2.8rem;
    line-height: 1.36;
    letter-spacing: 0.27px;
  }
}

.c-hero__gloss--home,
.c-hero__gloss--404 {
  max-width: 28rem;

  @include bp-large {
    max-width: 40rem;
  }

  @include bp-xlarge {
    max-width: 59rem;
  }
}

.c-hero__gloss--platform {
  max-width: 25rem;

  @include bp-medium {
    max-width: 43.5rem;
  }

  @include bp-xlarge {
    max-width: 67rem;
  }
}

.c-hero__gloss--contact {
  display: none;

  @include bp-medium {
    display: block;
    max-width: 43.5rem;
  }

  @include bp-xlarge {
    max-width: 41.5rem;
    font-size: 2.2rem;
  }
}

.c-hero__gloss--blog {
  color: #091e3e;
  width: 60%;

  @include bp-medium {
    max-width: 43.5rem;
    width: auto;
  }

  @include bp-xlarge {
    max-width: 77rem;
    font-size: 2.2rem;
    letter-spacing: 0.25px;
  }
}

.c-hero__gloss--solutions,
.c-hero__gloss--retail,
.c-hero__gloss--grocery,
.c-hero__gloss--wholesale-distribution,
.c-hero__gloss--digitally-native,
.c-hero__gloss--suppliers {
  @include bp-medium {
    max-width: 45rem;
  }

  @include bp-xlarge {
    font-size: 2.6rem;
    max-width: 60rem;
  }
}

.c-hero__logo {
  position: relative;
  z-index: 1;
  width: 162px;
  margin-bottom: 1.5rem;

  .c-hero--blog & {
    width: 75%;
    max-width: 327px;
  }

  @include bp-large {
    margin-bottom: 3rem;
  }
}
