.c-team__member {
  & + & {
    margin-top: 8rem;

    @include bp-medium {
      margin-top: 5rem;
    }
  }

  @include bp-medium {
    display: flex;
    align-items: flex-start;
    margin-top: 5rem;
  }
}

.c-team__wrapper {
  max-width: 22rem;
  box-shadow: 1px 2px 4px rgba(black, 0.2);

  @include bp-medium {
    flex-shrink: 0;
    width: 23rem;
    max-width: none;
    margin-right: 6rem;
    margin-top: 0.7rem;
  }

  @include bp-xlarge {
    width: 28rem;
  }
}

.c-team__linkedin-link {
  display: block;
  padding: 1.2rem;
  background-color: $color-primary;
  text-align: right;
  transition: all $transition;

  &:hover {
    background-color: #42d7fd;
  }

  &:focus {
    background-color: $color-secondary-bg;
  }
}

.c-team__linkedin-icon {
  max-width: 3rem;
}

.c-team__name {
  margin-top: 3rem;
  color: $color-secondary;
  font-size: 1.7rem;
  font-weight: 700;

  @include bp-medium {
    margin-top: 0;
  }

  @include bp-large {
    font-size: 1.8rem;
  }

  @include bp-xlarge {
    font-size: 2.2rem;
  }
}

.c-team__role {
  color: $color-secondary;
  font-size: 1.7rem;
  font-weight: 400;
  font-style: italic;

  @include bp-large {
    font-size: 1.8rem;
  }

  @include bp-xlarge {
    font-size: 2.2rem;
  }
}

.c-team__description {
  p {
    line-height: 1.46;
  }
}
