.c-news {
  color: $color-bright;
  font-size: 1.4rem;
  background-color: $color-tertiary;

  @include bp-large {
    color: #a6bac7;
  }
}

.c-news__inner {
  padding-top: $padding * 0.5;
  padding-bottom: $padding * 0.5;
}

.c-news__content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include bp-large {
    padding-right: 1.5rem;
  }

  @include bp($bp-xlarge - $shift_diff * 2) {
    max-width: none;
    padding-left: calc((100% - #{$maxwidth_xlarge - $padding_large}) / 2);
  }

  @include bp-xlarge {
    padding-right: 0;
    padding-left: 12rem;
    max-width: $bp-xlarge - $padding_large * 2;
  }
}

.c-news__content {
  @include bp-large {
    padding-right: 1rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.c-news__close-wrapper {
  display: none;

  @include bp-large {
    display: block;
  }
}

.c-news__close-button {
  @include btn-reset;

  display: block;
  color: inherit;
  cursor: pointer;
}

.c-news__close-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  fill: currentColor;
}
