.c-solution-blocks__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 5rem 0;
  margin: 0 0 2.2rem 0;
  background: $color-grey;
  transition: all $transition;

  @include bp-large {
    align-items: flex-start;
    padding: 7rem 8.5rem 4rem;
    margin-bottom: 3.6rem;
  }

  @include bp-xlarge {
    padding: 7rem 0 5rem;
    margin-bottom: 4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $padding;
    background: inherit;
    transition: all $transition;

    @include bp-large {
      width: 0;
    }

    @include bp-xlarge {
      width: $padding_xlarge;
    }
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }
}

.c-solution-blocks__icon-wrapper {
  width: 10.7rem;
  height: 9.1rem;

  @include bp-large {
    position: absolute;
    top: 50%;
    right: 5rem;
    width: 15.4rem;
    height: 13.1rem;
    transform: translateY(-50%);
  }

  @include bp-xlarge {
    right: 3rem;
    width: 21.4rem;
    height: 18.2rem;
    transform: translateY(-60%);
  }
}

.c-solution-blocks__name {
  margin-top: 3.4rem;
  color: $color-secondary;
  font-size: 1.9rem;
  font-weight: 900;

  @include bp-large {
    font-size: 2.8rem;
    margin-top: 0;
    font-weight: 700;
  }

  @include bp-xlarge {
    font-size: 3.8rem;
  }
}

.c-solution-blocks__link {
  color: inherit;
  text-decoration: none;
}

.c-solution-blocks__description {
  text-align: center;
  max-width: 80%;
  line-height: 1.46;

  @include bp-large {
    text-align: left;
    max-width: 60%;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
    max-width: 60%;
  }

  &:last-of-type {
    @include bp-large {
      margin-bottom: 5rem;
    }
  }
}

.c-solution-blocks__button {
  margin-top: 0;
  margin-bottom: 0;

  @include bp-large {
    align-self: center;
  }

  @include bp-xlarge {
    margin-right: $padding_xlarge;
  }
}

.c-solution-blocks br {
  display: none;
}
