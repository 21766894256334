.o-two-cols {
  @include bp-large {
    display: flex;
    justify-content: space-between;
  }
}

.o-two-cols__col {
  @include bp-large {
    width: 45%;
  }

  @include bp-xlarge {
    width: 42.5%;
  }
}
