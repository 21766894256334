.c-icons-rich {
  display: flex;
  flex-wrap: wrap;

  @include bp-large {
    margin: 5rem 0;
  }

  @include bp-xlarge {
    margin: 7.5rem 0;
  }
}

.c-icons-rich__item {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 0;

  @include bp-large {
    width: 45%;

    &:nth-child(1) {
      order: 1;
      margin-right: 10%;
    }

    &:nth-child(2) {
      order: 3;
      margin-right: 10%;
    }

    &:nth-child(3) {
      order: 5;
      margin-right: 10%;
    }

    &:nth-child(4) {
      order: 2;
    }

    &:nth-child(5) {
      order: 4;
    }

    &:nth-child(6) {
      order: 6;
    }
  }
}

.c-icons-rich__image-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 5.8rem;
  height: 6.4rem;
  margin-right: 3rem;

  @include bp-medium {
    width: 7rem;
    height: 7rem;
  }

  @include bp-xlarge {
    width: 8.8rem;
    height: 8.8rem;
  }
}

.c-icons-rich__image {
  max-width: 100%;
  max-height: 100%;
  margin-top: 0.5rem;
}

.c-icons-rich__image--hand {
  width: 4.7rem;
  height: 4.1rem;

  @include bp-medium {
    width: 6.4rem;
    height: 5.7rem;
  }

  @include bp-xlarge {
    width: 8rem;
    height: 7.1rem;
  }
}

.c-icons-rich__image--scale {
  width: 3.2rem;
  height: 5.2rem;

  @include bp-medium {
    width: 3.9rem;
    height: 6.4rem;
  }

  @include bp-xlarge {
    width: 4.9rem;
    height: 8rem;
  }
}

.c-icons-rich__image--boxes {
  width: 5.1rem;
  height: 3.6rem;

  @include bp-medium {
    width: 6.9rem;
    height: 4.8rem;
  }

  @include bp-xlarge {
    width: 8.6rem;
    height: 6rem;
  }
}

.c-icons-rich__image--transparency {
  width: 4.2rem;
  height: 6.4rem;

  @include bp-medium {
    width: 4.6rem;
    height: 7rem;
  }

  @include bp-xlarge {
    width: 5.8rem;
    height: 8.8rem;
  }
}

.c-icons-rich__image--handshake {
  width: 5.8rem;
  height: 3.9rem;

  @include bp-medium {
    width: 7rem;
    height: 4.7rem;
  }

  @include bp-xlarge {
    width: 8.8rem;
    height: 5.9rem;
  }
}

.c-icons-rich__image--pyramid {
  width: 5.6rem;
  height: 5.6rem;

  @include bp-medium {
    width: 6.8rem;
    height: 6.8rem;
  }

  @include bp-xlarge {
    width: 8.5rem;
    height: 8.5rem;
  }
}

.c-icons-rich__label {
  display: block;
  color: $color-secondary;
  font-size: 1.5rem;
  font-weight: 900;

  @include bp-large {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }

  @include bp-xlarge {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.c-icons-rich__paragraph {
  font-size: 1.5rem;
  margin: 0;

  @include bp-large {
    font-size: 1.8rem;
  }

  @include bp-xlarge {
    font-size: 2rem;
  }
}
