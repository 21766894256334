.c-legal {
  padding-top: $padding;
  padding-bottom: $padding;
  color: #386180;

  @include bp-large {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: black;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include bp-large {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.6rem;

    @include bp-large {
      font-size: 2rem;
    }
  }

  dl {
    margin-top: 2rem;
    font-size: 1.6rem;

    @include bp-large {
      font-size: 2rem;
    }
  }

  dt {
    display: inline;
    color: inherit;
    float: left;
    margin-right: 0.5rem;

    &:not(.no-quotes)::before {
      content: "“";
    }

    &:not(.no-quotes)::after {
      content: "”";
    }
  }

  .no-quotes span {
    &::before {
      content: "“";
    }

    &::after {
      content: "”";
    }
  }

  dd {
    display: block;
    margin: 0 0 3rem 0;
  }

  ol {
    counter-reset: list;
    list-style-type: none;
    list-style-position: inside;
  }

  li {
    display: flex;
    list-style-type: inherit;
    font-size: 1.6rem;
    margin-left: 3rem;

    @include bp-large {
      font-size: 2rem;
    }

    &::before {
      content: "(" counter(list, lower-roman) ")";
      counter-increment: list;
      display: block;
      width: 5rem;
      flex-shrink: 0;
    }
  }

  a {
    border-bottom: 2px solid #386180;
  }
}
