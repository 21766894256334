.c-menu {
  display: none;

  @include bp-large {
    display: flex;
    width: auto;
    left: auto;
    top: 0;
    right: $padding * 2;
    bottom: 0;
    padding: 0;
    background: none;
    transform: none;
    transition: none;
    align-items: center;
  }
}

.c-menu--mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  width: 100%;
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
  margin: 0;
  background-color: $color-quaternary;
  box-shadow: 0 1px 10px rgba(black, 0);
  transform: translateY(-100%);
  transition: transform $transition;

  .has-menu-opened & {
    transform: translateY(0);
    box-shadow: 0 1px 10px rgba(black, 0.2);

    @include bp-large {
      box-shadow: none;
    }
  }

  @include bp-large {
    display: none;
  }
}

.c-menu__item {
  display: block;
  margin: 0;

  @include bp-large {
    margin-left: 0.5rem;
  }
}

.c-menu__item--button {
  @include bp-large {
    margin-left: 2.9rem;
  }

  @media (min-width: $bp-large) and (max-width: 1150px - 0.02) {
    margin-left: 1.4rem;
  }
}

.c-menu__link {
  display: block;
  padding: 1.4rem;
  color: $color-secondary;
  font-family: SharpSans, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;

  @include bp-large {
    letter-spacing: 0.75px;
  }

  @media (min-width: $bp-large) and (max-width: 1150px - 0.02) {
    font-size: 1.5rem;
    padding-inline: 0.8rem;
  }
}

.c-menu__link--icon {
  display: flex;
  align-items: center;
}

.c-menu__icon {
  margin-left: 12px;
}

.c-menu__link--button {
  font-weight: 700;
  padding: 1.1rem 2.1rem;
  border-radius: $radius;
  margin-top: 1rem;
  color: $color-bright;
  line-height: 1.45;
  background: $color-primary;
  min-width: 14.4rem;
  text-align: center;

  @include bp-large {
    margin-top: 0;
    padding: 1.1rem 1.8rem;
  }

  @media (min-width: $bp-large) and (max-width: 1150px - 0.02) {
    min-width: 0;
  }
}

.c-menu__link--button-darker {
  background: $color-secondary-3;
}
