/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-bright;
  text-decoration: underline;
}

p a {
  color: inherit;
  text-decoration: none;
}
