.c-clients-a {
  padding: $padding 0;
  background-color: #e6ebf5;

  @include bp-medium {
    padding-block: 17px;
  }
}

.c-clients-a__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
  gap: 15px;

  @include bp-medium {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.c-clients-a__item {
  width: 26%;
  margin: 0;
  text-align: center;

  @include bp-medium {
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.c-clients-a__image,
.c-clients-a__picture {
  display: block;
  margin: auto;

  &--cvs {
    max-width: 6rem;

    @include bp-medium {
      max-width: none;
      max-height: 4.8rem;
    }
  }

  &--kohls {
    max-height: 1.5rem;

    @include bp-medium {
      max-height: 2rem;
    }
  }

  &--burlington {
    max-height: 4rem;
  }

  &--giant-eagle {
    max-width: 5.5rem;

    @include bp-medium {
      max-width: 8rem;
    }
  }

  &--michaels {
    max-height: 4.5rem;

    @include bp-medium {
      max-height: 5rem;
    }
  }

  &--tractor {
    max-height: 3rem;

    @include bp-medium {
      max-height: 3.9rem;
    }
  }
}

.c-clients-a__image {
  @include bp-medium {
    transform: scale(0.8);
  }

  @include bp-large {
    transform: scale(0.8);
  }

  &--bw {
    filter: grayscale(1);
  }
}
