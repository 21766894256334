.c-vlist {
  margin-left: 3.2rem;

  @include bp-large {
    display: flex;
    flex-wrap: wrap;
    margin: 11rem 0 5rem;
  }

  @include bp-xlarge {
    margin: 11rem 0 9rem;
  }
}

.c-vlist__item {
  display: flex;
  margin: 2rem 0;
  align-items: center;

  @include bp-large {
    width: 50%;
    margin: 1.5rem 0;
  }
}

.c-vlist__punctuation-wrapper {
  width: 1.8rem;
  height: 2.1rem;
  margin-right: 1.4rem;

  @include bp-large {
    width: 2.7rem;
    height: 3.2rem;
    margin-right: 3rem;
  }
}

.c-vlist__punctuation-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.c-vlist__text {
  color: $color-secondary;

  @include bp-large {
    font-size: 2rem;
  }

  @include bp-xlarge {
    font-weight: 900;
  }
}
