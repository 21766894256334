/* stylelint-disable value-list-comma-space-after */
@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Book.woff2") format("woff2"),
    url("../assets/fonts/SharpSans-Book.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Medium.woff2") format("woff2"),
    url("../assets/fonts/SharpSans-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Semibold.woff2") format("woff2"),
    url("../assets/fonts/SharpSans-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/SharpSans-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: SharpSans;
  font-display: swap;
  src: url("../assets/fonts/SharpSans-Extrabold.woff2") format("woff2"),
    url("../assets/fonts/SharpSans-Extrabold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}
