.c-solution-icons {
  margin-top: 3.8rem;

  @include bp-large {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8rem;
  }

  @include bp-xlarge {
    margin-top: 12rem;
  }
}

.c-solution-icons__item {
  margin: 2.2rem 0 0 2.5rem;

  @include bp-large {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    margin: 0;
  }
}

.c-solution-icons__icon-wrapper {
  display: none;

  @include bp-large {
    display: block;
    width: 8rem;
    height: 6.6rem;
  }

  @include bp-xlarge {
    width: 12rem;
    height: 9.9rem;
  }
}

.c-solution-icons__icon {
  max-width: 100%;
  max-height: 100%;
}

.c-solution-icons__name {
  color: $color-secondary;
  font-weight: 900;

  @include bp-large {
    margin-top: 2.4rem;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
  }

  @include bp-xlarge {
    font-size: 2.4rem;
  }
}

.c-solution-icons__link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.c-solution-icons__description,
.c-solution-icons__button {
  display: none;
}
