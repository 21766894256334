.c-newsletter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-blue-dark, 0.4);
  z-index: 100;
  align-items: center;
  justify-content: center;
  color: $color-secondary-bg;
  display: none;

  &.is-open {
    display: flex;
  }
}

.c-newsletter-modal__main {
  flex: 0 0 auto;
  position: relative;
  width: calc(100% - 6rem);
  max-width: 97rem;
  max-height: 90%;
  display: flex;

  &::before {
    content: "";
    position: absolute;
    top: 0.7rem;
    left: 1.7rem;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background-color: rgba($color-secondary, 0.6);
  }
}

.c-newsletter-modal__close {
  @include btn-reset;

  display: block;
  color: inherit;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  z-index: 1;
  overflow: hidden;

  @include bp-medium {
    top: 2rem;
    right: 2rem;
  }

  @include bp-large {
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
  }
}

.c-newsletter-modal__close-icon {
  width: 1em;
  height: 1em;
  display: block;
  fill: currentColor;
}

.c-newsletter-modal__main-inner {
  width: 100%;
  position: relative;
  background: radial-gradient(circle at center -10%, #e6efe2, #c0f6fb)
    $color-bright;
  border-radius: 1.5rem;
  overflow: hidden;
  display: flex;
}

.c-newsletter-modal__main-inner-inner {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  padding: 2rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;

  @include bp-medium {
    padding: 4rem 5rem 2rem 5rem;
  }

  @include bp-large {
    padding: 7rem 5rem 3.5rem 5rem;
  }
}

.c-newsletter-modal__form {
  .o-newsletter-form__heading {
    color: inherit;
    margin-bottom: 1rem;

    @include bp-large {
      letter-spacing: 0.29px;
      margin-bottom: 2.5rem;
    }

    @include bp-xlarge {
      font-size: 3.4rem;
    }
  }

  .o-newsletter-form__intro {
    color: inherit;
    margin: 0;
    margin-bottom: 3rem;
    letter-spacing: 0.2px;

    @include bp-large {
      max-width: 67rem;
    }

    @include bp-xlarge {
      font-size: 2rem;
      margin-bottom: 5rem;
    }
  }

  .o-newsletter-form__heading,
  .o-newsletter-form__intro {
    @include bp-xlarge {
      margin-left: 5rem;
    }
  }

  .o-newsletter-form__inner {
    margin-bottom: 2rem;
  }

  .o-newsletter-form__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    max-width: 73rem;

    @include bp-large {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    @include bp-xlarge {
      margin-left: 3rem;
    }
  }

  .o-newsletter-form__field-group {
    flex: 0 0 auto;
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;

    @include bp-medium {
      width: 1 * 0.5 * 100%;
    }

    @include bp-large {
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 2.2rem;
      margin-bottom: 4.5rem;
    }

    label {
      display: block;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .asterisk {
      color: $color-red;
    }

    input {
      display: block;
      border-radius: 0;
      border: 0;
      font: inherit;
      color: inherit;
      background: none;
      width: 100%;
      border-bottom: 2px solid $color-primary;
    }

    /* stylelint-disable selector-class-pattern, selector-no-qualifying-type */
    div.mce_inline_error {
      margin-top: 0.5rem;
      font-size: 1.4rem;
    }
    /* stylelint-enable selector-class-pattern, selector-no-qualifying-type */
  }

  .o-newsletter-form__spam-note {
    max-width: 59rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;

    @include bp-medium {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }

  .o-newsletter-form__button-wrapper {
    text-align: center;
  }

  .o-newsletter-form__responses {
    text-align: center;

    a {
      color: inherit;
    }
  }

  .o-newsletter-form__footer {
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0.24px;
    text-align: center;

    a {
      color: inherit;
    }

    margin-top: 2rem;

    @include bp-medium {
      margin-top: 3rem;
    }

    @include bp-xlarge {
      margin-top: 5rem;
    }

    @include bp-medium {
      font-size: 1.5rem;
      letter-spacing: 0.28px;
    }
  }
}
