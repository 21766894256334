.c-links {
  margin: 3rem 0;

  @include bp-large {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
  }

  @include bp-xlarge {
    max-width: 86rem;
  }
}

.c-links__item {
  display: block;
  margin: 1.4rem 0;
  color: $color-secondary;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

.c-links__item-span:hover {
  border-bottom: 2px solid $color-secondary;
}

.c-links br {
  display: none;

  @include bp-large {
    display: block;
  }
}
