.c-social-list {
  position: relative;
  font-size: 1.4rem;
  display: inline-block;
}

.c-social-list__button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-weight: 500;
  color: $color-secondary-bg;
  letter-spacing: 0.705849px;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
  transition: $transition all;

  &:hover,
  &.is-opened {
    color: $color-primary;
  }
}

.c-social-list__list {
  position: absolute;
  top: 120%;
  right: -2rem;
  align-items: center;
  gap: 12px;
  display: none;
  background: #fff;
  padding: 2.5rem 2rem 2rem;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.04);
  grid-auto-flow: row;

  .is-opened + & {
    display: grid;
  }

  &--horizontal {
    box-shadow: none;
    background: none;
    right: auto;
    left: 100%;
    top: 2px;
    padding: 0 1.5rem;
    grid-auto-flow: column;
  }
}

.c-social-list__item {
  margin: 0;
}

.c-social-list__link {
  display: block;
}

.c-social-list__img {
  max-width: none;
}
