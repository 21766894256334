.c-icons-block {
  margin-top: 6rem;

  @include bp-large {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 0;
  }
}

.c-icons-block__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 0;
  margin: 3.5rem 0 0;
  text-align: center;
  background: rgba(#e6ebf5, 0.5);

  &:first-child {
    margin-top: 0;

    @include bp-large {
      margin-top: 4.8rem;
    }
  }

  @include bp-large {
    margin-top: 4.8rem;
    width: calc(100% / 3 - 3.2rem);
  }
}

.c-icons-block__image-wrapper {
  width: 9.3rem;
  height: 6.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable */

.c-icons-block__image {
  &--document {
    max-width: 4.6rem;
  }
  &--hand {
    max-width: 6.2rem;
  }
  &--scale {
    max-width: 3.5rem;
  }
  &--spiral {
    max-width: 7rem;
  }
  &--box {
    max-width: 6.6rem;
  }
  &--boxes {
    max-width: 6.2rem;
  }
  &--short {
    max-width: 5.6rem;
  }
  &--auto {
    max-width: 6.8rem;
  }
  &--abstract {
    max-width: 9.3rem;
  }
}

/* stylelint-enable */

.c-icons-block__textual {
  margin-top: 2rem;
  width: 100%;
}

.c-icons-block__label {
  display: block;
  max-width: 80%;
  margin: auto;
  color: #031d44;
  font-weight: 700;
}
