/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  font-family: $font-sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
