.c-partners {
  padding: $padding 0;
  background-color: #e6ebf5;

  @include bp-medium {
    margin-top: $padding * 4;
    padding: 75px 0;
  }
}

.c-partners__title {
  margin-bottom: 0;
  text-align: center;

  @include bp-medium {
    margin-bottom: 35px;
  }
}

.c-partners__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;

  @include bp-medium {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.c-partners__item {
  margin: $padding * 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: calc(50% - #{$padding});
  flex: 2 1 auto;

  @include bp-medium {
    margin: 0 $padding * 0.5;
    height: 52px;
  }

  @include bp-large {
    margin: 0 $padding * 1.3333;
  }

  &--portrait {
    max-width: calc(33.33% - #{$padding});
    flex: 1 1 auto;

    @include bp-medium {
      height: 104px;
    }
  }

  &:first-child {
    @include bp-medium {
      margin-left: 0;
    }
  }

  &:last-child {
    @include bp-medium {
      margin-right: 0;
    }
  }
}

.c-partners__link {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.c-partners__image {
  margin: auto;

  @include bp-medium {
    max-height: 100%;
    width: auto;
  }
}
