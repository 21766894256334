.c-jobs {
  margin-bottom: 20rem;
  margin-left: -30px;
  margin-right: -30px;

  @include bp-medium {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-job-top {
  box-shadow: 0 2px 4px 0 #b8bfca;
  font-family: Helvetica, sans-serif;
  font-size: 2.6rem;
  color: $color-secondary-2;
  letter-spacing: 0.39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 2.8rem;
  transition: background $transition;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:hover {
    background: $color-quaternary;
  }

  &.is-opened {
    background: $color-primary-bg;
  }

  @include bp-small {
    padding: 4.2rem 4rem;
  }

  @include bp-large {
    padding: 5.3rem 10.8rem 5.3rem 7.6rem;
  }
}

.c-job-top__title {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  line-height: 2.1rem;
  letter-spacing: 0.4px;
  font-size: 1.56rem;
  padding-right: 10px;
  position: relative;
  top: 2px;

  @include bp-small {
    font-size: 20px;
    line-height: 34px;
    position: static;
  }

  @include bp-large {
    font-size: 26px;
  }
}

.c-job-top__arrow {
  transition: transform $transition;
  flex: 0 0 2.4rem;

  .is-opened & {
    transform: rotate(180deg);
  }

  @include bp-large {
    max-width: none;
    flex: 0 0 auto;
  }
}

.c-job-bottom {
  transition: height 0.7s, opacity 0.7s, margin-bottom 0.7s;
  overflow: hidden;
  opacity: 0;
  margin-bottom: 1rem;
  background: transparent;

  &.is-opened {
    opacity: 1;
    margin-bottom: 0;
  }

  @include bp-small {
    margin-bottom: 2.5rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  @include bp-large {
    margin-left: 33px;
    margin-right: 33px;
  }
}

.c-job:last-child {
  .c-job-bottom {
    margin-bottom: 0;
  }
}

.c-job-bottom__inner {
  background: #f8fdfe;
  border: 1px solid #d7eaee;
  padding: 3.2rem 2.8rem 5rem;

  @include bp-medium {
    padding: 10.9rem 5.7rem 10rem;
  }
}

.c-job-bottom__block-label {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.6rem;
  letter-spacing: 0.3px;
  color: $color-secondary-2;
  display: block;
  margin-bottom: 2.8rem;

  @include bp-large {
    font-size: 2rem;
  }
}

.c-job-bottom__block-content {
  margin-bottom: 5rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  color: $color-secondary-2;
  letter-spacing: 0.3px;
  line-height: 2.2rem;

  @include bp-large {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 8.6rem;
    padding-left: 4.3rem;
  }
}

.c-job-bottom__button-wrapper {
  text-align: center;
}

.c-job-bottom__button {
  margin: 0;
}
