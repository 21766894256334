@use "sass:math";

$colOuter: math.div(1, 24) * 100%;
$colInner: math.div(1, 21) * 100%;
$colInnerTwoColDesktop: math.div(1, 18) * 100%;
$colInnerDataSheetSpacer: math.div(1, 9) * 100%;

.o-resources-grid-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1440px;
  padding-left: $padding;
  padding-right: $padding;

  @include bp-large {
    padding-left: $padding_large;
    padding-right: $padding_large;
  }

  @include bp-xlarge {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.o-resources-grid-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  &--two-columns {
    @include bp-large {
      margin-right: 3 * $colInner;
    }
  }
}

.o-resources-grid-col {
  flex: 0 0 auto;
  padding: 0 0.5rem;

  &--outer-spacer {
    display: none;

    @include bp-large {
      display: block;
      width: 1 * $colOuter;
    }

    @include bp-xlarge {
      display: block;
      width: 3 * $colOuter;
    }
  }

  &--outer-main {
    width: 100%;

    @include bp-large {
      width: $colOuter * 23;
    }

    @include bp-xlarge {
      width: $colOuter * 21;
    }
  }

  &--case-study {
    width: 100%;

    @include bp-medium {
      width: 50%;
    }

    @include bp-large {
      width: 7 * $colInner;
    }

    @include bp-xlarge {
      width: 6 * $colInner;
    }
  }

  &--case-study-spacer {
    display: none;

    @include bp-xlarge {
      display: block;
      width: 1 * $colInner;
    }
  }

  &--case-study-button {
    width: 100%;

    @include bp-large {
      padding-top: 2rem;
    }

    @include bp-xlarge {
      padding-top: 5rem;
      width: 20 * $colInner;
    }
  }

  &--two-columns-main {
    width: 100%;
    margin-bottom: 2rem;

    @include bp-medium {
      width: 15 * $colInner;
      margin-bottom: 0;
      padding-right: 1rem;
    }

    @include bp-large {
      width: 13 * $colInnerTwoColDesktop;
    }

    @include bp-xlarge {
      width: 14 * $colInnerTwoColDesktop;
    }
  }

  &--data-sheet {
    width: 100%;

    @include bp-medium {
      width: 50%;
    }

    @include bp-large {
      width: 9 * $colInner;
    }
  }

  &--data-sheet-spacer {
    display: none;

    @include bp-large {
      display: block;
      width: 1 * $colInner;
    }
  }
}
