.c-blog {
  padding-block: 3.5rem 7.5rem;

  @include bp-large {
    padding-block: 7rem 15rem;
  }
}

.c-blog__filters {
  display: flex;
  gap: 2.4rem;
  margin-bottom: 3.5rem;
  padding-bottom: 2rem;
  color: $color-secondary-bg;
  border-bottom: 1px solid #e6e6e6;
  overflow-y: auto;
  align-items: flex-start;

  @include bp-large {
    margin-bottom: 7rem;
  }
}

.c-blog__filters-title {
  font-size: 2rem;
}

.c-blog__filters-list {
  display: flex;
  gap: 2.4rem;
  margin-top: 5px;

  @include bp-medium {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}

.c-blog__filters-button {
  font-family: SharpSans, sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  border: 0;
  background: none;
  transition: color $transition;
  cursor: pointer;

  &.is-active {
    color: $color-primary;
  }

  &:hover {
    color: $color-primary-light;
  }
}

.c-blog__filters-button--FIRST {
  color: $color-primary;
}

.c-blog__title-top {
  @include bp-xlarge {
    margin-bottom: 7rem;
    font-size: 3.2rem;
  }
}

.c-blog__title-bottom {
  margin-block: 4.2rem 3.5rem;
  padding-top: 3.5rem;
  border-top: 10px solid $color-secondary-bg;
  letter-spacing: 0.5px;

  @include bp-xlarge {
    margin-block: 8.4rem 7rem;
    padding-top: 7rem;
  }
}

.c-blog__posts {
  display: grid;
  gap: 4.4rem;
}

.c-blog__posts-empty {
  display: none;
  font-size: 2.4rem;
  color: $color-secondary-bg;
  font-weight: 700;
  text-align: center;

  &.is-visible {
    display: block;
  }
}

.c-blog__pagination {
  margin-top: 3.2rem;

  &.is-hidden {
    display: none;
  }

  @include bp-large {
    margin-top: 4rem;
  }
}
