.c-resources-recent-articles__row {
  position: relative;
  padding-bottom: 4rem;

  @include bp-xlarge {
    padding-bottom: 7rem;
  }

  .c-resources-recent-articles__single:not(:last-child) & {
    margin-bottom: 4rem;

    @include bp-xlarge {
      margin-bottom: 7rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    width: calc(100% - 1rem);
    border-bottom: 2px solid $color-primary-bg;
  }
}

.c-resources-recent-articles__single-main {
  display: flex;
}

.c-resources-recent-articles__single-image-wrapper {
  flex: 0 0 auto;
  width: 6rem;
  padding-right: 1rem;
  display: flex;

  @include bp-medium {
    width: 8rem;
  }

  @include bp-large {
    min-height: 12rem;
    padding-right: 2rem;
    width: 12rem;
  }
}

.c-resources-recent-articles__single-image {
  width: 100%;
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
}

.c-resources-recent-articles__single-info {
  width: 100%;
  color: $color-secondary-2;
  line-height: 1.1;
}

.c-resources-recent-articles__single-heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @include bp-medium {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  @include bp-large {
    font-size: 2.4rem;
    margin-bottom: 1.8rem;
  }
}

.c-resources-recent-articles__single-publication,
.c-resources-recent-articles__single-author {
  font-size: 1.6rem;

  @include bp-large {
    font-size: 1.8rem;
  }
}

.c-resources-recent-articles__single-publication {
  margin-bottom: 1rem;
}

.c-resources-recent-articles__single-author {
  font-style: italic;
}
