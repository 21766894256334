.c-checklist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.c-checklist__label {
  display: flex;
  width: 50%;
  margin: 1rem 0;
  align-items: flex-start;
  cursor: pointer;

  @include bp-medium {
    width: calc(100% / 3);
  }
}

.c-checklist__input {
  position: absolute;
  visibility: hidden;
}

.c-checklist__pseudoinput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1.7rem;
  height: 1.7rem;
  border: 0.2rem solid $color-primary;
  border-radius: 0.3rem;
  margin: 0.1rem 1rem 0 0;
  background: white;
  transition: background-color $transition;

  :checked + & {
    background: $color-primary;
  }
}

/* stylelint-disable */

_:-ms-fullscreen,
:root .c-checklist__pseudoinput {
  margin-top: -0.2rem;
}

/* stylelint-enable */

.c-checklist__checked-icon {
  width: 1.3rem;
}

.c-checklist__pseudolabel {
  color: $color-secondary;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.c-checklist__pseudolabel-short {
  @include bp-small {
    display: none;
  }

  /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
  & + .c-checklist__pseudolabel-default {
    display: none;

    @include bp-small {
      display: block;
    }
  }
}
