@use "sass:math";

$ecosystemImageWidth: math.div(10, 16) * 100%;
$ecosystemTabletWidth: math.div(7, 15) * 100%;
$ecosystemRightAdjustment: math.div(1, 18) * 100%;
$ecosystemDesktopWidth: math.div(8, 19) * 100%;

.c-ecosystem__image-row {
  margin: 0 -2.5rem 4.5rem;

  @include bp-medium {
    margin: 0 0 8.5rem;
  }

  @include bp-xlarge {
    margin: 10rem 0 12rem;
  }
}

.c-ecosystem__image-wrapper {
  max-width: 42rem;
  margin: 0 auto;
  position: relative;

  @include bp-medium {
    max-width: none;
    width: $ecosystemImageWidth;
  }
}

.c-ecosystem__image {
  padding-top: 105%;
  background-image: url("../assets/images/ecosystem/ecosystem-mobile.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  // @include bp-medium {
  //  background-image: url('../assets/images/ecosystem/ecosystem-desktop.svg');
  // }
}

.c-ecosystem__blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5rem;

  &::after {
    content: "";
    flex: auto;
    order: 99;
  }

  @include bp-xlarge {
    margin-right: -$ecosystemRightAdjustment;
  }
}

.c-ecosystem__section {
  flex: 0 0 auto;
  width: 100%;
  padding: 0.5rem;

  @include bp-medium {
    width: $ecosystemTabletWidth;
  }

  @include bp-xlarge {
    width: $ecosystemDesktopWidth;
  }

  /* stylelint-disable max-nesting-depth */
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      @include bpmax($bp-medium - 1) {
        @if $i % 2 == 0 {
          order: 50 + $i;
        } @else {
          order: $i;
        }
      }
    }
  }
  /* stylelint-enable max-nesting-depth */

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.c-ecosystem__section-title {
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin-left: -3rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 1.5rem;
  display: inline-block;
  min-width: 19rem;
  margin-bottom: 2rem;

  @include bp-medium {
    margin-left: 0;
    display: block;
  }

  @include bp-large {
    font-size: 2.2rem;
    letter-spacing: 0.36px;
    padding-left: 1.5rem;
  }
}

.c-ecosystem__section-list {
  color: #386180;
  font-size: 1.5rem;

  @include bp-large {
    font-size: 2rem;
  }
}

.c-ecosystem__section-list-element {
  list-style: disc;
  margin-left: 2rem;
  padding-left: 0.5rem;

  @include bp-medium {
    margin-left: 5rem;
  }

  @include bp-large {
    margin-left: 3.5rem;
  }
}
