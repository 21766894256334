/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer__main {
  padding-top: $padding;
  padding-bottom: $padding;
  color: $color-bright;
  background: linear-gradient(to bottom, #154072, #091e3e);

  @include bp-large {
    font-size: 1.8rem;
    padding-top: 4.4rem;
    padding-bottom: 3.5rem;
  }
}

.c-footer__contact-wrapper {
  text-align: center;
}

.c-footer__contact-button {
  font-size: 1.28rem;

  @include bp-large {
    margin-top: 19px;
    font-size: 1.6rem;
    min-width: 147px;
    height: 48px;
    border-radius: 7px;
    letter-spacing: 0.25px;
    padding-block: 1.3rem;
  }
}

.c-footer__menu {
  margin-top: 2rem;
}

.c-footer__heading {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3.5rem;
  margin-bottom: 0.7rem;
  letter-spacing: 0.17px;

  @include bp-large {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.c-footer__heading--first {
  @include bp-large {
    margin-bottom: 0.5rem;
  }
}

.c-footer__link,
.c-footer__linkish {
  display: block;
  text-decoration: none;
  font-weight: 400;

  @include bp-large {
    font-size: 2rem;
    letter-spacing: 0.17px;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }

  br {
    display: none;

    @include bp-large {
      display: block;
    }
  }
}

.c-footer__link--primary {
  text-decoration: none;
  color: $color-primary;

  @include bp-large {
    font-weight: 400;
    margin: 0;
  }
}

.c-footer__link--icon {
  display: flex;
  align-items: center;
}

.c-footer__icon {
  margin-left: 8px;
}

.c-footer__form {
  margin-top: 1rem;
  display: flex;
  max-width: 35rem;

  @include bp-large {
    max-width: 39rem;
  }
}

.c-footer__form-input {
  display: block;
  flex-grow: 1;
  padding: 0.3rem 0.6rem;
  border: 0;
  border-radius: 0.33rem;
  color: $color-dark;
  font-size: 1.2rem;
  font-family: SharpSans, sans-serif;
  font-weight: 600;
  line-height: 1.5;

  &::placeholder {
    color: $color-medium;
  }

  @include bp-large {
    font-size: inherit;
    font-weight: 300;
    padding: 0.45rem 1rem;
  }
}

.c-footer__form-button {
  margin-left: 0.6rem;

  @include bp-large {
    margin-left: 1.6rem;
  }
}

.c-footer__logo {
  display: block;
  width: 100%;
  max-width: 14.6rem;
  margin: 5rem auto;

  @include bp-large {
    margin-right: 0;
    max-width: 18.3rem;
    margin-top: 18px;
  }
}

.c-footer__group {
  margin: 5rem auto;
  text-align: center;
  display: grid;
  gap: 10px;

  .c-footer__link {
    font-family: SharpSans, sans-serif;
    font-weight: 700;
    letter-spacing: 0.170455px;
  }

  @include bp-large {
    margin: 6.3rem 0 0;
    text-align: right;
  }
}

.c-footer__social {
  display: flex;
  margin: 1rem 0 2rem -1rem;
}

.c-footer__social-link {
  display: block;
  width: 5rem;
  height: 4.5rem;
  padding: 0.75rem 1rem;
}

.c-footer__toolbar {
  grid-area: toolbar;
  display: grid;
  gap: 24px;
  place-items: center;
  margin-left: 0;
  margin-bottom: 0;

  li {
    margin: 0;
  }

  @include bp-large {
    display: flex;
    justify-content: end;
    grid-column: 1/-1;
    grid-row: 3/4;
    gap: 66px;
    justify-self: stretch;
    margin-top: 30px;
    padding-top: 27px;
    border-top: 1px solid #08668d;
  }
}

.c-footer__toolbar-link {
  display: grid;
  transition: all $transition;

  &:hover {
    opacity: 0.7;
  }
}

.c-footer__toolbar-logo--vision {
  position: relative;
  width: 104px;
}

.c-footer__toolbar-logo--platform {
  position: relative;
  width: 146px;
}

@include bp-large {
  .c-footer__grid-container {
    display: grid;
    grid-template-columns: 20fr 20fr 20fr auto;
  }

  .c-footer__grid-item--1 {
    grid-column: 4;
    margin-bottom: 4.8rem;
    justify-self: end;
  }

  .c-footer__grid-item--2 {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 4.8rem;
  }

  .c-footer__grid-item--4 {
    grid-column: 1;
    grid-row: 2;
  }

  .c-footer__grid-item--5 {
    grid-column: 2;
    grid-row: 2 / span 2;
  }

  .c-footer__grid-item--6 {
    grid-column: 3;
    grid-row: 2;
  }

  .c-footer__grid-item--7 {
    justify-self: end;
    grid-column: 4;
    grid-row: 2;
  }

  .c-footer__grid-item--8 {
    grid-column: 1/-1;
  }
}

@include bp-xlarge {
  .c-footer__grid-container {
    grid-template-columns: 14fr 12fr 15fr auto 25fr;
  }

  .c-footer__grid-item--1 {
    grid-column: 5;
  }

  .c-footer__grid-item--3 {
    grid-row: 2 / span 2;
  }

  .c-footer__grid-item--5 {
    // grid-column: 3;
    // grid-row: 2 / span 2;
  }

  .c-footer__grid-item--6 {
    grid-column: 3;
    grid-row: 2 / span 2;
  }

  .c-footer__grid-item--7 {
    grid-column: 5;
    grid-row: 2;
  }

  .c-footer__grid-item--8 {
    grid-row: 4;
  }
}
